import axios from "axios";
import logger from "./logService";
import { toast } from "react-toastify";
import Auth from '../Auth';


axios.interceptors.request.use(config => {
  const auth = new Auth();
  const token = auth.getToken();
  if(token) {
    config.headers.common.Authorization = `Bearer ${token}`;
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  Promise.reject(error);
});

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error);
    toast.error("An unexpected error occurrred.");
  }

  return Promise.reject(error);
});

export function RequestHeader() {
  const auth = new Auth();
  const token = auth.getToken();
  if(token) {
    const header = { headers: { Authorization: `Bearer ${token}`}}
    return header;
  }
  return null;
}

export function RequestHeaderBasic() {
  const username = process.env.REACT_APP_REGISTER_UNAME;
  const password = process.env.REACT_APP_REGISTER_PAWO;
  const header = {headers: {Authorization: `Basic ${new Buffer(username+':'+password).toString('base64')}`}};
  return header;
}

const httpServiceObj = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete
};

export default httpServiceObj;
