import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar, CardActions, Divider } from '@material-ui/core';
import Score from '@material-ui/icons/Score';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  studentName: {
    fontWeight: 700,
    fontSize: '1.2rem'
  },
  courseName: {
    fontWeight: 500,
    fontSize: '1rem'
  },
  mainInfo: {
    justifyContent: 'space-between'
  }
}));

const MainInfo = props => {
  const { className, testResult, courseName, studentName } = props;

  const classes = useStyles();

  let score = null;
  
  if(testResult.length > 0) {
    const totalScore = testResult.filter(e => e.hasOwnProperty('Total Score'));
    score = totalScore.length > 0 ? totalScore[0]['Total Score'] : null;
  }
  
  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              MID TERM / FINAL TEST RESULT
            </Typography>
            <Typography
              color="inherit"
              variant="h3"
            >
              {score > 0 ? score : 'N/A'}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <Score className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.mainInfo}>
        <Typography
            className={classes.studentName}
            color="inherit"
            variant="h5"
          >
            {studentName}
        </Typography>
        <Typography
            className={classes.courseName}
            color="inherit"
            variant="h5"
          >
            {courseName}
        </Typography>
      </CardActions>
    </Card>
  );
};

MainInfo.propTypes = {
  className: PropTypes.string,
  testResult: PropTypes.array.isRequired,
  courseName: PropTypes.string.isRequired,
  courseStartDate: PropTypes.string,
  courseEndDate: PropTypes.string,
  studentName: PropTypes.string
};

export default MainInfo;
