import http, { RequestHeader } from './httpService';


const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = '/api';

export function getQuiz(id, history) {
    const header = RequestHeader();
    const result = http.post(`${apiUrl}${apiEndpoint}/quiz/code/azuread-b2c-oauth2/${id}/`, {}, header);
    result.catch(function(error) {
        console.log(error);
        history.push('/');
    });
    return result;
}

export function getQuizWithoutLogin(id, data, history) {
    const result = http.post(`${apiUrl}${apiEndpoint}/quiz/code/azuread-b2c-oauth2/${id}/`, data);
    result.catch(function(error) {
        console.log(error);
        history.push('/');
    });
    return result;
}

export function postTrackingStudentAnswers(data) {
    const header = RequestHeader();
    return http.post(`${apiUrl}${apiEndpoint}/tracking/test_process/`, data, header);
}

export function postStudentAnswerResults(data) {
    const header = RequestHeader();
    const testCode = data.testCode;
    delete data.testCode;
    return http.post(`${apiUrl}${apiEndpoint}/quiz/answer/azuread-b2c-oauth2/${testCode}/`, data, header);
}

export function getStudentResultDetail(testCode, userName, history) {
    const header = RequestHeader();
    const result = http.get(`${apiUrl}${apiEndpoint}/answers/code/azuread-b2c-oauth2/${userName}/${testCode}/`, header);
    result.catch(function(error) {
        console.log(error);
        history.push('/error');
    });
    return result;
}

export function getStudentResultDetailGrading(testCode, userName, history) {
    const header = RequestHeader();
    const data = {studentName: userName};
    const result = http.post(`${apiUrl}${apiEndpoint}/question/code/azuread-b2c-oauth2/${testCode}/`, data, header);
    result.catch(function(error) {
        console.log(error);
        history.push('/error');
    });
    return result;
}

export function postDetailGrading(testCode, userName, gradingResult) {
    const header = RequestHeader();
    const data = {
        studentName: userName,
        gradeResults: gradingResult
    };
    const result = http.post(`${apiUrl}${apiEndpoint}/grade/code/azuread-b2c-oauth2/${testCode}/`, data, header);
    return result;
}


export function checkAudioFileExists(fileUrl) {
    return fetch(fileUrl).then((res) => {
        if(res.status === 200) {
            return true;
        }
        return false;
    }).catch(() => false);
}

export function uploadAudioFile(file, userName, fileName=null, onUploadProgress=null) {
    let header = RequestHeader() ? RequestHeader() : {};
    header["Content-Type"] = "multipart/form-data";
    if(onUploadProgress) {
        header["onUploadProgress"] = onUploadProgress;
    }
    let fd = new FormData();
    let fn = (fileName === null) ? file.size : fileName;
    fd.append('file', file, (onUploadProgress ? `audio_${fn}` : `blob_${fn}`));
    return http.post(`${apiUrl}${apiEndpoint}/upload-media/azuread-b2c-oauth2/${userName}/2/`, 
        fd, header); // 1: create new file, 2: replace old file
}

export function postCheckCodeInfo(data) {
    const header = RequestHeader();
    return http.post(`${apiUrl}${apiEndpoint}/check-code-valid/${data.testCode}/`, data, header);
}