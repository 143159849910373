import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.warning.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceUpIcon: {
    color: theme.palette.success.dark
  },
  differenceUpValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  differenceDownIcon: {
    color: theme.palette.error.dark
  },
  differenceDownValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  differenceValue: {
    color: theme.palette.info.dark,
    marginRight: theme.spacing(1)
  }
}));

const Info1 = props => {
  const { className, currentHWResult, previousHWResult } = props;

  const classes = useStyles();

  let compareToPreviousCourse = 0;
  if(Object.keys(previousHWResult).length > 0) {
    compareToPreviousCourse = parseInt((currentHWResult.percentage_completion - previousHWResult.percentage_completion) / currentHWResult.percentage_completion * 100);
    if(compareToPreviousCourse === 0) {
      compareToPreviousCourse = 'Remain unchanged';
    }
  }

  const showCompare = (compareToPreviousCourse) => {
    if(compareToPreviousCourse !== 0) {
      if(compareToPreviousCourse === 'Remain unchanged') {
        return (
          <div className={classes.difference}>
            <Typography
              className={classes.differenceValue}
              variant="body2"
            >
              {compareToPreviousCourse}%
            </Typography>
            <Typography
              className={classes.caption}
              variant="caption"
            >
              Compare to previous course
            </Typography>
          </div>
        );
      } else {
        return (
          <div className={classes.difference}>
            {compareToPreviousCourse < 0 && <ArrowDownwardIcon className={classes.differenceDownIcon} />}
            {compareToPreviousCourse > 0 && <ArrowUpwardIcon className={classes.differenceUpIcon} />}
            <Typography
              className={compareToPreviousCourse < 0 ? classes.differenceDownValue : classes.differenceUpValue}
              variant="body2"
            >
              {compareToPreviousCourse}%
            </Typography>
            <Typography
              className={classes.caption}
              variant="caption"
            >
              Compare to previous course
            </Typography>
          </div>
        );
      }
    }
    return null; 
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              HOMEWORK COMPLETION
            </Typography>
            <Typography variant="h3">{currentHWResult.percentage_completion > 0 ? currentHWResult.percentage_completion : 'N/A'}%</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AssignmentTurnedIn className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        {showCompare(compareToPreviousCourse)}
      </CardContent>
    </Card>
  );
};

Info1.propTypes = {
  className: PropTypes.string,
  currentHWResult: PropTypes.object.isRequired,
  previousHWResult: PropTypes.object.isRequired
};

export default Info1;
