import React from "react";
import Scheduler from "react-big-scheduler";
import "react-big-scheduler/lib/css/style.css";
import withDragDropContext from "./withDnDContext";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Grid, Button } from "@material-ui/core";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import "./TutorScheduler.scss";
import palette from "../theme/palette";

const useStyles = makeStyles((theme) => ({
  container: {},
  title: {
    textAlign: "center",
  },
  arrowBack: {
    color: theme.palette.info.main,
    margin: "1rem",
  },
  greenColor: {
    color: theme.palette.success.main,
  },
  explanationWraper: {
    padding: theme.spacing(2),
  },
  explanationItem: {
    display: "flex",
    alignItems: "center"
  },
  saveBooking: {
    margin: "0 8px",
    color: palette.success.main
  }
}));

const TutorScheduler = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ArrowBackIcon
        className={classes.arrowBack}
        onClick={() => props.handleArrowBack(1)}
      />
      <h2 className={classes.title}>BOOK TUTOR SCHEDULER</h2>
      <Scheduler
        schedulerData={props.tutorSchedulerData}
        prevClick={props.prevClick}
        nextClick={props.nextClick}
        onSelectDate={props.onSelectDate}
        onViewChange={props.onViewChange}
        eventItemClick={props.eventClicked}
        viewEventClick={props.ops1}
        viewEventText="Book"
        viewEvent2Click={props.ops2}
        viewEvent2Text="Cancel"
        toggleExpandFunc={props.toggleExpandFunc}
      />
      <Grid container justify="flex-end" className={classes.explanationWraper}>
        <Grid item className={classes.explanationItem}>
          <FiberManualRecord className={classes.greenColor} />
          <span>In booking</span>
          {props.temporaryScheduledTutor.length > 0 && (
            <Button
              variant="contained"
              onClick={props.saveBooking}
              className={classes.saveBooking}
              disabled={props.disableBooking}
            >
              Save booking
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

TutorScheduler.propTypes = {
  handleArrowBack: PropTypes.func.isRequired,
  removeSchedule: PropTypes.func.isRequired,
  tutorSchedulerData: PropTypes.object,
  temporaryScheduledTutor: PropTypes.array,
  onViewChange: PropTypes.func.isRequired,
  onSelectDate: PropTypes.func.isRequired,
  eventClicked: PropTypes.func.isRequired,
  prevClick: PropTypes.func.isRequired,
  nextClick: PropTypes.func.isRequired,
  ops1: PropTypes.func.isRequired,
  ops2: PropTypes.func.isRequired,
  toggleExpandFunc: PropTypes.func.isRequired,
  saveBooking: PropTypes.func.isRequired,
  disableBooking: PropTypes.bool.isRequired
};

export default withDragDropContext(TutorScheduler);
