import React from 'react';
import PropTypes from 'prop-types';
import RingLoader from 'react-spinners/RingLoader';
import { CSSTransitionGroup } from 'react-transition-group';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { blue } from '@material-ui/core/colors';
import { QuizConfirmAlertDialogSlide } from './Dialog';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 800,
        borderRadius: 3,
        margin: "5rem auto 2rem auto",
        flexGrow: 1,
        width: "100%"
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& svg': {
            cursor: "pointer"
        },
        padding: 40
    },
    arrowBack: {
        color: blue[500]
    }
}));


function Finish(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    return (
        <CSSTransitionGroup
            className={classes.container}
            component="div"
            transitionName="fade"
            transitionEnterTimeout={800}
            transitionLeaveTimeout={500}
            transitionAppear
            transitionAppearTimeout={500}
        >
            <RingLoader
                css={props.overrideSpinner}
                // size={150}
                color={blue[400]}
                loading={props.loadingSpinner}
            />
            <Grid container alignItems="center" className={classes.root}>  
                <Grid item xs={5}><ArrowBackIcon className={classes.arrowBack} onClick={props.onSetCurrentQuestion} /></Grid>
                <Grid item xs={7}>
                    <Button variant="outlined" 
                        color="primary" 
                        className={classes.button} 
                        onClick={() => setOpen(true)} 
                        disabled={props.loadingSpinner}
                    >
                        Finish
                    </Button>
                    <QuizConfirmAlertDialogSlide open={open}
                        handleFinish={props.handleFinish}
                        handleClose={() => setOpen(false)} />
                </Grid>
            </Grid>
        </CSSTransitionGroup>
    );
}

Finish.propTypes = {
    onSetCurrentQuestion: PropTypes.func.isRequired,
    handleFinish: PropTypes.func.isRequired,
    overrideSpinner: PropTypes.object.isRequired,
    loadingSpinner: PropTypes.bool.isRequired
}

export default Finish;