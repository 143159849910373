import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group';
import { toast } from "react-toastify";
// import is from "is_js";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { Button, Input, InputLabel, FormHelperText } from '@material-ui/core';
import Auth from '../../Auth';
import { InitStudentDialogSlide, 
  InitStudentRegisteredNameDialogSlide,
  PlayWithoutLoginDialogSlide } from './Dialog';
import CheckAudio from './CheckAudio';
import {postCheckCodeInfo} from '../../services/quizService';


const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 800,
    borderRadius: 3,
    margin: "5rem auto 2rem auto",
    flexGrow: 1
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    padding: 40,
  },
  codeWraper: {
    paddingTop: theme.spacing(4),
    textAlign: "center"
  },
  submit: {
    marginTop: 20,
    marginLeft: 40
  },
  checkAudioWraper: {
    maxWidth: "390px"
  }
}));


export const PlayWithoutLogin = (props) => {
  const history = useHistory();
  const loadTheTest = () => {
    const { testCode, studentName } = props.match.params;
    history.push(`/test/${testCode}/play-without-login/${studentName}/start/`);
  }
  return <PlayWithoutLoginDialogSlide loadTheTest={loadTheTest} />;
}


export const InitStudent = () => {

  const [open1, setOpen1] = React.useState(true);
  const [open2, setOpen2] = React.useState(false);

  const history = useHistory();

  // if(is.ios() || is.safari()) {
  //   toast.error("Sorry, We haven't supported Iphone, Ipad and Safari at this time.");
  //   history.push('/');
  //   return '';
  // }

  // if(navigator.oscpu && navigator.oscpu.includes('Windows NT 6.1')) {
  //   toast.error("Sorry, Windows 7 is no longer supported.");
  //   history.push('/');
  //   return '';
  // }

  // if(navigator.appVersion && navigator.appVersion.includes('Windows NT 6.1')) {
  //   toast.error("Sorry, Windows 7 is no longer supported.");
  //   history.push('/');
  //   return '';
  // }

  const _initStudentLogin = () => {
    const tc = _getTestCodeParam();
    history.push(`/test/${tc}/play/`);
  }
  
  const _getTestCodeParam = () => {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const tc = pathArray[2];
    return tc;
  }
  
  const _initStudentRegisteredName = () => {
    setOpen1(false);
    setOpen2(true);
  }

  const _handleSubmit = (e) => {
    e.preventDefault();
    const studentName = e.target.registered_name.value;
    const tc = _getTestCodeParam();
    history.push(`/test/${tc}/play-without-login/${studentName}/`);
  }

  const auth = new Auth();
  const tc = _getTestCodeParam();

  const _checkTestCode = async (tc) => {
    const checkCodeData = {
      testCode: tc
    }
    const { data } = await postCheckCodeInfo(checkCodeData);
    if(data.status_code !== 200) {
      if(data.message === 'Coming Soon') {
        toast.info('The test will be shown in some minutes!');
        history.push('/test');
      } else if(data.message === 'Time expired') {
        toast.error('Time expired');
        history.push('/test');
      } else if(data.message === 'Sorry, Test Code were not exists') {
        toast.error('Sorry, the test code is invalid!');
        history.push('/test');
      } else {
        toast.error(data.message);
        history.push('/test');
      }
      return false;
    } else {
      return data.data;
    }
  }

  if(auth.isLoggedIn()) {
    history.push(`/test/${tc}/play/`);
    return '';
  } else {
    if(open2) {
      return <InitStudentRegisteredNameDialogSlide open={open2} 
        handleClose={() => {
          setOpen2(false);
          history.push(`/test/`);
        }} 
        handleSubmit={_handleSubmit} />;
    } else {
      _checkTestCode(tc).then(res => {
        if(res && res.forceLoginSocial === true) {
          // window.location.href = `/test/${tc}/play/`;
          history.push(`/test/${tc}/play/`);
        }
      });

      return <InitStudentDialogSlide open={open1} 
        initStudentLogin={_initStudentLogin} 
        initStudentRegisteredName={_initStudentRegisteredName} 
        handleClose={() => setOpen1(false)} 
      />;
    }
  }
}


const Main = () => {
  const classes = useStyles();
  const history = useHistory();

  const onSubmit = (event) => {
    event.preventDefault();
    history.push(`/test/${event.target.testcode.value}`);
  }

  const [enableStart, setEnableStart] = useState(true);

  return (
    <CSSTransitionGroup
        className={classes.container}
        component="div"
        transitionName="fade"
        transitionEnterTimeout={800}
        transitionLeaveTimeout={500}
        transitionAppear
        transitionAppearTimeout={500}
    >
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={8} className={classes.checkAudioWraper}>
          <CheckAudio setEnableStart={setEnableStart} />
        </Grid>
        
        <Grid item xs={8} className={classes.codeWraper}>
          <form onSubmit={onSubmit}>
            <FormControl>
              <InputLabel htmlFor="testcode">Test Code</InputLabel>
              <Input id="testcode" name="testcode" aria-describedby="my-helper-text"
                autoFocus={true} placeholder="Your test code here" required/>
              <FormHelperText id="my-helper-text">Enter your Test Code then click the Start button.</FormHelperText>
            </FormControl>
            <FormControl>
              <Button disabled={!enableStart} type="submit" variant="contained" color="primary" 
                className={classes.submit}>Start</Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </CSSTransitionGroup>
  );
};

export default Main;
