import React from 'react';
import clsx from 'clsx';
import uuid from 'uuid/v1';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';

import { linkify } from '../../utils/helper';
import Info from '@material-ui/icons/Info';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0,
  },
  text: {
    "& span": {
      fontSize: '0.9rem',
      fontWeight: 'bold'
    },
    "& p": {
      fontSize: '0.8rem',
      padding: '8px 0',
      color: 'inherit',
    },
    alignSelf: 'flex-start',
    minWidth: "34px"
  },
  body: {
    whiteSpace: "pre-line",
    wordWrap: "break-word",
    fontWeight: "normal !important"
  },
  media: {
    width: "50%",
    height: "auto",
    "& img": {
      width: "100%",
      height: "auto"
    },
    marginTop: "12px"
  },
  icon: {
    color: theme.palette.info.main
  },
  date: {
    paddingBottom: 0,
    "& p": {
      lineHeight: "8px"
    }
  }
}));

const List4 = props => {
  const { className, announcements } = props;

  const classes = useStyles();

  let data = [];
  for(const item of announcements) {
    let announceData = {
      id: uuid(),
      title: item['title'],
      body: item['body'],
      image: item['image'],
      date: item['date']
    };
    data.push(announceData);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Announcements"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {data.map((ann, i) => (
            <React.Fragment key={ann.id}>
              <ListItem className={classes.date}>
                <ListItemText secondary={ann['date']} />
              </ListItem>
              <ListItem
                divider={i < data.length - 1}
              >
                <ListItemAvatar className={classes.text}>
                  <Info className={classes.icon} />
                </ListItemAvatar>
                <ListItemText className={classes.text}
                  secondary={<span className={classes.body} dangerouslySetInnerHTML={{ __html: linkify(ann['body']) }} />}
                  primary={ann['title']}
                />
                {ann['image'] &&
                  <CardMedia className={classes.media} title={ann['title']}>
                    <img src={ann['image']} alt="" />
                  </CardMedia>
                }
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

List4.propTypes = {
  className: PropTypes.string,
  announcements: PropTypes.array.isRequired
};

export default List4;
