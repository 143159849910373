import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { grey, blue } from '@material-ui/core/colors';
import { SwapVert } from '@material-ui/icons';

const grid = 8;

// a little function to help us with reordering the result
const reorder =  (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => {
    let result = {
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? 'lightgreen' : '#fff',

        fontSize: '1.1rem',

        // styles we need to apply on draggables
        ...draggableStyle
    };

    return result;
};

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : grey[200],
    padding: grid,
    width: "100%",
});

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex", 
        justifyContent: "space-between"
    },
    swapIcon: {
        paddingTop: "10%",
        '& svg': {
            fontSize: "2rem",
            color: blue[500],
        }
    }
}));

function DragDropOrder(props) {

    const classes = useStyles();

    const onDragEnd = (result) => {
        // dropped outside the list
        if(!result.destination) {
           return; 
        }
        
        const items = reorder(
          props.studentAnswers.items, 
          result.source.index, 
          result.destination.index
        );
        
        props.onSetUserAnswer({items: items});
    }

    return (
        <div className={classes.root}>
            <div className={classes.swapIcon}><SwapVert /></div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                        >
                            {props.studentAnswers && props.studentAnswers.items && props.studentAnswers.items.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id.toString()}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                    <div>
                                        <div
                                        ref={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                        >
                                            {item.text}
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                    )}
                                </Draggable>
                            ))}

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable> 
            </DragDropContext>
        </div>
    );
}

DragDropOrder.propTypes = {
    onSetUserAnswer: PropTypes.func.isRequired,
    studentAnswers: PropTypes.object.isRequired
}

export default DragDropOrder;