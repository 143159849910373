import http, { RequestHeader } from './httpService';


const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = '/api';

export function postSubscription(data) {
    const header = RequestHeader();
    return http.post(`${apiUrl}${apiEndpoint}/notify/subscribe/azuread-b2c-oauth2/`, 
        data, header);
}