import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Countdown from "react-countdown-now";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { postRegisterWithOTPCode } from "../../services/registerService";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    margin: "2rem auto",
    width: "100%",
  },
  titleBox: {
    margin: "2rem auto !important",
    display: "flex",
    justifyContent: "space-between",
  },
  inputNumber: {
    width: "2.3rem",
    margin: "0 0.3rem !important",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  divBtn: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2rem auto",
  },
  countdownBtn: {
    borderRadius: "50% !important",
    minWidth: "40px !important",
    padding: "6px 0 !important",
  },
}));

function InputOTPCode(props) {
  const classes = useStyles();
  const { state, setState, setOpenBackdrop } = props;

  const LENGTH = 6;
  const clamp = (min, max, val) => Math.max(min, Math.min(val, max));
  const [dataInput] = useState([...Array(LENGTH).keys()]);
  const inputRefs = useRef([]);
  const [inputComplete, setInputComplete] = useState(false);
  const [countdownComplete, setCountdownComplete] = useState(false);

  const handleInput = (e, index) => {
    if (e.target.value) {
      const nextIndex = clamp(0, dataInput.length - 1, index + 1); // <-- get next index
      inputRefs.current[nextIndex].focus(); // <-- get ref and focus
    }

    e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 1);

    const notComplete = inputRefs.current.filter((e) => e.value === "");
    if (notComplete.length === 0) {
      setInputComplete(true);
    } else {
      setInputComplete(false);
    }
  };

  const handleBack = () => {
    setState({
      ...state,
      registerState: "register",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setOpenBackdrop(true);
    const inputData = inputRefs.current.map((e) => e.value);
    const otpCode = inputData.join("");
    const dataRegister = {
      email: state.email,
      phone: state.mobile,
      program: state.program,
      name: state.yourname,
      otp_code: otpCode,
    };
    const { data } = await postRegisterWithOTPCode(dataRegister);
    if (data.status_code === 200) {
      if (data.message === "Codes were created success") {
        setState({
          ...state,
          registerState: "done",
          linkTest: data.link_test
        });
      } else {
        toast.error("Your OTP code is invalid! Please try again.");
      }
    } else {
      if (data.message === "OTP Code was wrong!") {
        toast.error("Your OTP code is invalid! Please try again.");
      } else if (data.message === "OTP Code was expired!") {
          toast.error("Your OTP code is expired!");
      } else {
        toast.error("Something went wrong!");
      }
    }
    setOpenBackdrop(false);
  };

  const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
    let leftSeconds = hours * 3600 + minutes * 60 + seconds;
    if (leftSeconds >= 0) {
      return seconds;
    }
    return "";
  };

  const onCompleteCountdown = () => {
    setCountdownComplete(true);
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className={classes.root}
      onSubmit={handleSubmit}
    >
      <Box component="div" className={classes.titleBox}>
        <Typography component="h4" variant="span">
          Please enter your OTP Code (Sent via Zalo/SMS)
        </Typography>
        <Button
          variant="contained"
          size="small"
          color="success"
          className={classes.countdownBtn}
        >
          <Countdown
            date={state.countdown}
            renderer={rendererCountdown}
            onComplete={onCompleteCountdown}
          />
        </Button>
      </Box>

      <Box component="div">
        {dataInput.map((data, index) => (
          <TextField
            key={index}
            id={`number${index}`}
            inputRef={(ref) => (inputRefs.current[index] = ref)}
            type="number"
            required
            autoFocus={index === 0}
            size="small"
            onInput={(e) => handleInput(e, index)}
            className={classes.inputNumber}
          />
        ))}
      </Box>
      <Box component="div" className={classes.divBtn}>
        <Button
          type="button"
          variant="text"
          onClick={handleBack}
          disabled={!countdownComplete}
        >
          Back
        </Button>

        <Button
          type="submit"
          variant="contained"
          disabled={!inputComplete || countdownComplete}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

InputOTPCode.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  setOpenBackdrop: PropTypes.func.isRequired,
};

export default InputOTPCode;
