import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import is from "is_js";
import { css } from "@emotion/core";
import RingLoader from "react-spinners/RingLoader";
import { CSSTransitionGroup } from "react-transition-group";
import { Swipe } from "react-swipe-component";
import { addBackToTop } from "vanilla-back-to-top";
// import { Detector } from "react-detect-offline";
import { UseStateValue } from "../../state";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { blue } from "@material-ui/core/colors";
import Auth from "../../Auth";
import {
  getQuiz,
  getQuizWithoutLogin,
  postTrackingStudentAnswers,
  postStudentAnswerResults,
  checkAudioFileExists,
  uploadAudioFile,
} from "../../services/quizService";
import QuizInfo from "./QuizInfo";
import Question from "./Question";
import QuestionCount from "./QuestionCount";
import AnswerOption from "./AnswerOption";
import Essay from "./Essay";
import Finish from "./Finish";
import DragDrop from "./DragDrop";
import FillPlace from "./FillPlace";
import FillPlaceParagraph from "./FillPlaceParagraph";
import RecordingVoice from "./RecordingVoice";
import MultichoiceImage from "./MultichoiceImage";
import MultiQuestion from "./MultiQuestion";
import GroupQuestionButtons from "./GroupQuestionButtons";
import DragDropOrder from "./DragDropOrder";
import SpeechRecognitionQuestion from "./SpeechRecognitionQuestion";

export const initialState = {
  testCode: null,
  userName: null,
  quizTitle: "",
  quizDescription: "",
  quizDuringTime: 0,
  countdown: 0,
  quizQuestions: [],
  questionId: 0,
  question: "",
  questionType: "",
  questionExplanation: "",
  questionFigure: "",
  questionAudio: null,
  questionVideo: null,
  answerOptions: [],
  audiosPlayed: {},
  recordingObj: {},
  recordingType: (is.ios() || is.safari()) ? "upload" : "",
  counter: 0,
  questionOrder: 1,
  studentAnswers: {},
  answerResults: {},
  studentFinish: "no",
  questionsShow: [],
  leftMin: 0
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "loadData":
      return {
        ...state,
        testCode: action.testCode,
        userName: action.userName,
        quizQuestions: action.quizQuestions,
        quizTitle: action.quizTitle,
        quizDescription: action.quizDescription,
        quizDuringTime: action.quizDuringTime,
      };
    case "initData":
      return {
        ...state,
        questionId: action.questionId,
        question: action.question,
        questionType: action.questionType,
        questionExplanation: action.questionExplanation,
        questionFigure: action.questionFigure,
        questionAudio: action.questionAudio,
        questionVideo: action.questionVideo,
        answerOptions: action.answerOptions,
      };
    case "updateCountdown":
      return {
        ...state,
        countdown: action.countdown,
      };
    case "updateLeftMin":
      return {
        ...state,
        leftMin: action.leftMin,
      };
    case "updateQuestionsShow":
      return {
        ...state,
        questionsShow: action.questionsShow,
      };
    case "updateAudiosPlayed":
      return {
        ...state,
        audiosPlayed: action.audiosPlayed,
      };
    case "updateRecordingObj":
      return {
        ...state,
        recordingObj: action.recordingObj,
      };
    case "updateStudentAnswers":
      return {
        ...state,
        studentAnswers: action.studentAnswers,
      };
    case "updateAnswerResults":
      return {
        ...state,
        answerResults: action.answerResults,
      };
    case "setCurrentQuestion":
      return {
        ...state,
        questionId: action.questionId,
        question: action.question,
        questionType: action.questionType,
        questionExplanation: action.questionExplanation,
        questionFigure: action.questionFigure,
        questionAudio: action.questionAudio,
        questionVideo: action.questionVideo,
        answerOptions: action.answerOptions,
        counter: action.counter,
        questionOrder: action.questionOrder,
        studentAnswers: action.studentAnswers,
      };
    case "updateStudentFinish":
      return {
        ...state,
        studentFinish: action.studentFinish,
      };
    case "setRecordingType":
      return {
        ...state,
        recordingType: action.recordingType
      };
    default:
      return state;
  }
};

// two functions of drag-drop items
const getLeftItems = (items) => {
  const shuffle = _.shuffle(items);
  return shuffle.map((item) => ({
    id: `l_${item.id}`,
    answer: item.answer,
  }));
};
const getLeftItemSingle = (item) => {
  return [
    {
      id: `l_${item.question_id}`,
      answer: item.answer_text,
      figure: item.answer_figure,
    },
  ];
};
const getRightItems = (items) => {
  return items.map((item) => ({
    id: `r_${item.id}`,
    figure: item.figure,
    text: item.text,
  }));
};

function Quiz(props) {
  const classes = useStyles();

  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [progressUpload, setProgressUpload] = useState(0);

  const [
    {
      testCode,
      userName,
      quizTitle,
      quizDescription,
      quizQuestions,
      questionId,
      questionType,
      answerOptions,
      counter,
      questionOrder,
      studentAnswers,
      answerResults,
      studentFinish,
      countdown,
      questionsShow,
      recordingType
    },
    dispatch,
  ] = UseStateValue();

  const history = useHistory();

  // const shuffleArray = (array) => {
  //   var currentIndex = array.length,
  //     temporaryValue,
  //     randomIndex;

  //   // While there remain elements to shuffle...
  //   while (0 !== currentIndex) {
  //     // Pick a remaining element...
  //     randomIndex = Math.floor(Math.random() * currentIndex);
  //     currentIndex -= 1;

  //     // And swap it with the current element.
  //     temporaryValue = array[currentIndex];
  //     array[currentIndex] = array[randomIndex];
  //     array[randomIndex] = temporaryValue;
  //   }

  //   return array;
  // }

  useEffect(() => {
    const { testCode, studentName } = props.match.params;

    async function fetchData() {
      // turn on spinner
      setLoadingSpinner(true);

      let resResult = null;
      let uName = null;
      if (studentName) {
        const { data } = await getQuizWithoutLogin(
          testCode,
          { studentName },
          history
        );
        if (data) {
          resResult = data;
          uName = resResult.data.quizAnonymous;
        }
      } else {
        const { data } = await getQuiz(testCode, history);
        if (data) {
          resResult = data;
        }
        const auth = new Auth();
        const currentUser = auth.currentUser();
        uName = currentUser ? currentUser.azureId : null;
      }

      // turn off spinner
      setLoadingSpinner(false);

      if (resResult.status_code !== 200) {
        dispatch({
          type: "loadData",
          testCode: null,
          userName: null,
          quizQuestions: [],
          quizTitle: "",
          quizDescription: "",
        });
        if (resResult.message === "Your test has already been taken") {
          if (uName) {
            return history.push(`/test/${testCode}/${uName}/`);
          }
        } else if (resResult.message === "Coming Soon") {
          toast.info("The test will be shown in some minutes!");
          return history.push("/test");
        } else if (resResult.message === "Time expired") {
          toast.error("Time expired");
          return history.push("/test");
        } else if (resResult.message === "Overtime") {
          toast.error("Time over");
          return history.push("/test");
        } else if (resResult.message === "This code was not for you") {
          toast.error("Wrong registered name");
          return history.push("/test");
        } else if (resResult.message === "Sorry, Test Code were not exists") {
          toast.error("Sorry, the test code is invalid!");
          return history.push("/test");
        }
        toast.error(resResult.message);
        return history.push("/test");
      }

      let qQuestions = resResult.data.quizQuestions;
      qQuestions = qQuestions.map((q) => ({
        ...q,
        figure: q.figure ? `${q.figure}` : null,
        answerOptions:
          q.question_type !== "essay" &&
          q.question_type !== "recording" &&
          q.questionType !== "multiquestion"
            ? q.answers
            : [],
      }));

      // Temporary data for SpeechRecognitionQuestion
      // qQuestions.push({
      //   question_type: 'speech_recognition',
      //   question_id: '9999',
      //   question: 'Which color often is the sky?',
      //   explanation: '',
      //   figure: null,
      //   audio: null,
      //   video: null,
      //   answers: [
      //     {
      //       content: 'green',
      //       figure: '/images/green.png',
      //       correct: false
      //     },
      //     {
      //       content: 'white',
      //       figure: '/images/white.png',
      //       correct: false
      //     },
      //     {
      //       content: 'blue',
      //       figure: '/images/blue.png',
      //       correct: true
      //     },
      //     {
      //       content: 'gray',
      //       figure: '/images/gray.jpeg',
      //       correct: false
      //     }
      //   ]
      // });
      // End Temporary data for SpeechRecognitionQuestion

      dispatch({
        type: "loadData",
        testCode: testCode,
        userName: uName,
        quizQuestions: qQuestions,
        quizTitle: resResult.data.quizTitle,
        quizDescription: resResult.data.quizDescription,
        quizDuringTime: resResult.data.quizDuringTime,
      });

      let codeStartTime =
        resResult.data.quizDuringStartTime !== null
          ? resResult.data.quizDuringStartTime
          : Date.now();
      let takingTime = resResult.data.quizDuringTime;
      let leftMinutes = calLeftMins(takingTime, codeStartTime);
      dispatch({
        type: "updateCountdown",
        countdown: Date.now() + leftMinutes * 60 * 1000,
      });
      dispatch({
        type: "updateLeftMin",
        leftMin: leftMinutes,
      });
      if (leftMinutes > 0) {
        updateQuestionsShow(qQuestions, takingTime, leftMinutes);
      }

      dispatch({
        type: "initData",
        questionId: qQuestions[counter].question_id,
        question: qQuestions[counter].question,
        questionType: qQuestions[counter].question_type,
        questionExplanation: qQuestions[counter].explanation,
        questionFigure: qQuestions[counter].figure,
        questionAudio: qQuestions[counter].audio,
        questionVideo: qQuestions[counter].video,
        answerOptions: qQuestions[counter].answers,
      });

      let answersObj = {};

      if (qQuestions[counter].question_type === "dragdrop") {
        // if drag-drop question
        answersObj = {
          leftItems: getLeftItems(qQuestions[counter].answers),
          rightItems: getRightItems(qQuestions[counter].answers),
        };
      } else if (qQuestions[counter].question_type === "dragdropsingle") {
        // if drag-drop-single question
        answersObj = {
          leftItems: getLeftItemSingle(qQuestions[counter]),
          rightItems: getRightItems(qQuestions[counter].answers),
        };
      } else if (qQuestions[counter].question_type === "dragdroporder") {
        // if dragdroporder question
        answersObj = { items: qQuestions[counter].answers };
      } else if (qQuestions[counter].question_type === "multiquestion") {
        // if multiquestion
        answersObj = {};
        qQuestions[counter].sub_questions.forEach((item) => {
          if (item.question_type === "dragdrop") {
            answersObj[item.question_id] = {
              leftItems: getLeftItems(item.answers),
              rightItems: getRightItems(item.answers),
            };
          } else if (item.question_type === "dragdropsingle") {
            answersObj[item.question_id] = {
              leftItems: getLeftItemSingle(item),
              rightItems: getRightItems(item.answers),
            };
          } else if (item.question_type === "dragdroporder") {
            answersObj[item.question_id] = { items: item.answers };
          }
        });
      }

      // get student answers from the local storage if exists
      let lsAnswerResults = localStorage.getItem(
        `yolatest_answerResults_${testCode}_${uName}`
      );

      if (lsAnswerResults) {
        lsAnswerResults = JSON.parse(lsAnswerResults);
        dispatch({
          type: "updateAnswerResults",
          answerResults: lsAnswerResults,
        });
        answersObj = lsAnswerResults[qQuestions[counter].question_id]
          ? lsAnswerResults[qQuestions[counter].question_id]
          : answersObj;
      }

      if (Object.keys(answersObj).length > 0) {
        dispatch({
          type: "updateStudentAnswers",
          studentAnswers: answersObj,
        });
      }

      // add event listener for asking when refreshing or leave
      // window.addEventListener('beforeunload', (e) => {
      //   e.preventDefault();
      //   e.returnValue = true;
      // });

      // Update questionsShow every 60 seconds
      const interval = setInterval(() => {
        let leftMinutes = calLeftMins(takingTime, codeStartTime);
        // Frequently update questionsShow
        if (leftMinutes > 0) {
          dispatch({
            type: "updateLeftMin",
            leftMin: leftMinutes,
          });
          updateQuestionsShow(qQuestions, takingTime, leftMinutes);
        }
      }, 60000);
      return () => clearInterval(interval);
    }
    fetchData();

    // prevent right click
    document.oncontextmenu = () => {
      return false;
    };

    // remove event listener for asking when refreshing or leave
    // return () => {
    //   window.removeEventListener('beforeunload', (e) => {
    //     e.preventDefault();
    //     e.returnValue = true;
    //   });
    // }
  }, [dispatch, props, history]);

  const calLeftMins = (takingTime, codeStartTime) => {
    let leftMinutes = takingTime;
    if (codeStartTime !== null) {
      codeStartTime = new Date(codeStartTime);
      let dif = Date.now() - codeStartTime;
      let diffMinutes = Math.round(dif / 1000 / 60);
      leftMinutes = takingTime - diffMinutes > 0 ? takingTime - diffMinutes : 0;
    }
    return leftMinutes;
  };

  const updateQuestionsShow = (qQuestions, takingTime, leftMinutes) => {
    let questionsShow = [];
    qQuestions.forEach((q, index) => {
      let showFrom = q.show_time.minute_from;
      let showTo = q.show_time.minute_to;
      if ((showFrom === 0 && showTo === 0) || (showFrom === null && showTo === null)) {
        questionsShow.push({
          questionId: q.question_id,
          showFrom: showFrom,
          showTo: showTo,
        });
      } else if (showFrom - 1 === takingTime - leftMinutes + 1) {
        toast.info("Next question will be opened in 1 minute!", {autoClose: 30000});
      } else if (
        takingTime - leftMinutes + 1 >= showFrom &&
        takingTime - leftMinutes <= showTo
      ) {
        questionsShow.push({
          questionId: q.question_id,
          showFrom: showFrom,
          showTo: showTo,
        });

        // Auto moving on to the next question
        if (showFrom === takingTime - leftMinutes + 1) {
          setQuestion(index, index + 1, qQuestions);
        }
      }
    });

    dispatch({
      type: "updateQuestionsShow",
      questionsShow: questionsShow,
    });
  };

  const setPending = () => {
    dispatch({
      type: "updateStudentFinish",
      studentFinish: "pending",
    });
  };

  const setNextQuestion = () => {
    let counterCurr = counter + 1;
    let questionOrderCurr = questionOrder + 1;
    if (questionOrderCurr > quizQuestions.length) {
      setPending();
      return;
    }
    setQuestion(counterCurr, questionOrderCurr);
  };

  const setPreviousQuestion = () => {
    let counterCurr = counter - 1;
    if (!(counterCurr in quizQuestions)) return;
    let questionOrderCurr = questionOrder - 1;
    setQuestion(counterCurr, questionOrderCurr);
  };

  const setCurrentQuestion = () => {
    setQuestion(counter, questionOrder);
    dispatch({
      type: "updateStudentFinish",
      studentFinish: "no",
    });
  };

  const setQuestion = (counterCurr, questionOrderCurr, qQuest = []) => {
    let qQuestions = [];
    if (quizQuestions && quizQuestions.length > 0) {
      qQuestions = quizQuestions;
    } else {
      qQuestions = qQuest;
    }

    let questionIdCurr = qQuestions[counterCurr].question_id;

    if (
      qQuest.length === 0 &&
      questionsShow.filter((qs) => qs.questionId === questionIdCurr).length ===
        0
    ) {
      toast.info("Next question is not available at the moment!");
      return;
    }

    let answersObj = {};

    if (qQuestions[counterCurr].question_type === "dragdrop") {
      // if drag-drop question
      answersObj = {
        leftItems: getLeftItems(qQuestions[counterCurr].answers),
        rightItems: getRightItems(qQuestions[counterCurr].answers),
      };
    } else if (qQuestions[counterCurr].question_type === "dragdropsingle") {
      // if drag-drop-single
      answersObj = {
        leftItems: getLeftItemSingle(qQuestions[counterCurr]),
        rightItems: getRightItems(qQuestions[counterCurr].answers),
      };
    } else if (qQuestions[counterCurr].question_type === "dragdroporder") {
      // if drag-drop order question
      answersObj = { items: qQuestions[counterCurr].answers };
    } else if (qQuestions[counterCurr].question_type === "multiquestion") {
      // if multiquestion
      answersObj = {};
      qQuestions[counterCurr].sub_questions.forEach((item) => {
        if (item.question_type === "dragdrop") {
          answersObj[item.question_id] = {
            leftItems: getLeftItems(item.answers),
            rightItems: getRightItems(item.answers),
          };
        } else if (item.question_type === "dragdropsingle") {
          answersObj[item.question_id] = {
            leftItems: getLeftItemSingle(item),
            rightItems: getRightItems(item.answers),
          };
        } else if (item.question_type === "dragdroporder") {
          answersObj[item.question_id] = { items: item.answers };
        }
      });
    }

    dispatch({
      type: "setCurrentQuestion",
      counter: counterCurr,
      questionOrder: questionOrderCurr,
      questionId: questionIdCurr,
      question: qQuestions[counterCurr].question,
      questionType: qQuestions[counterCurr].question_type,
      questionExplanation: qQuestions[counterCurr].explanation,
      questionFigure: qQuestions[counterCurr].figure,
      questionAudio: qQuestions[counterCurr].audio,
      questionVideo: qQuestions[counterCurr].video,
      answerOptions:
        qQuestions[counterCurr].question_type !== "essay" &&
        qQuestions[counterCurr].question_type !== "recording" &&
        qQuestions[counterCurr].question_type !== "multiquestion"
          ? qQuestions[counterCurr].answers
          : [],
      studentAnswers:
        questionIdCurr in answerResults
          ? answerResults[questionIdCurr]
          : answersObj,
    });
  };

  const setUserAnswer = async (answerObj) => {
    if (typeof answerObj.persist === "function") {
      answerObj.persist();
    }

    let quesId = questionId;
    let answers = { ...studentAnswers };
    let answerResultsCurr = { ...answerResults };
    if (questionType === "recording" && recordingType !== "upload") {
      const lsQuesId = localStorage.getItem(
        `yolatest_recording_voice_question_curr_id_${testCode}_${userName}`
      );
      if (lsQuesId) {
        quesId = parseInt(lsQuesId);
      }
      let lsAnswerResults = localStorage.getItem(
        `yolatest_answerResults_${testCode}_${userName}`
      );
      if (lsAnswerResults) {
        lsAnswerResults = JSON.parse(lsAnswerResults);
        answerResultsCurr = lsAnswerResults;
      }
    }

    // prevent in case of quesId null or NaN
    if (!quesId) return;

    if (questionType === "multichoice") {
      if (answerObj.currentTarget.checked) {
        answers[answerObj.currentTarget.value] =
          answerObj.currentTarget.dataset.answercorrect; // or answerObj.currentTarget.getAttribute("data-answercorrect")
      } else {
        delete answers[answerObj.currentTarget.value];
      }
    } else if (questionType === "multichoice_image") {
      answers = {};
      answers[answerObj.currentTarget.value] = "checked";
    } else if (
      questionType === "truefalse" ||
      questionType === "singlechoice"
    ) {
      answers = {};
      answers[answerObj.currentTarget.value] =
        answerObj.currentTarget.dataset.answercorrect; // or answerObj.currentTarget.getAttribute("data-answercorrect")
    } else if (questionType === "essay") {
      answers = {};
      answers["value"] = answerObj.currentTarget.value;
    } else if (
      questionType === "dragdrop" ||
      questionType === "dragdropsingle" ||
      questionType === "dragdroporder"
    ) {
      answers = answerObj;
    } else if (questionType === "fillplace") {
      // trim multiple spaces
      const v = answerObj.currentTarget.value.replace(/\s+/g, " ").trim();
      if (v !== "") {
        answers[answerObj.currentTarget.id] = v;
      }
    } else if (questionType === "fillplaceparagraph") {
      // trim multiple spaces
      const v = answerObj.currentTarget.value.replace(/\s+/g, " ").trim();
      if (v !== "") {
        answers[answerObj.currentTarget.id] = v;
      }
    } else if (questionType === "recording") {
      answers = {};
      if (Object.keys(answerObj).length > 0) {
        if(answerObj.blobURL) {
          // upload the blob file to the server
          const blobExists = await checkAudioFileExists(answerObj.blobURL);
          if (blobExists) {
            const blobRes = await fetch(answerObj.blobURL);
            const blob = await blobRes.blob();
            const fileName = `${testCode}_${userName}_${quesId}`;
            const { data } = await uploadAudioFile(blob, userName, fileName);
            if (data.status_code === 200) {
              answers = { value: data.audio_link };
            }
          }
        } else if(answerObj.value) {
          // receive socket upload
          answers = {value: answerObj.value};
          setProgressUpload(100);
        }
      } else if (answerObj && answerObj.name) {
        // upload audio file to the server
        const fileName = `${testCode}_${userName}_${quesId}`;
        const { data } = await uploadAudioFile(answerObj, userName, fileName, (event) => {
          setProgressUpload(Math.round((100 * event.loaded) / event.total));
        });
        if (data.status_code === 200) {
          answers = { value: data.audio_link };
        }
      }
    }

    await dispatch({
      type: "updateStudentAnswers",
      studentAnswers: answers,
    });

    answerResultsCurr[quesId] = answers;

    await dispatch({
      type: "updateAnswerResults",
      answerResults: answerResultsCurr,
    });

    // update local storage
    if(Object.keys(answerResultsCurr).length) {
      localStorage.setItem(
        `yolatest_answerResults_${testCode}_${userName}`,
        JSON.stringify(answerResultsCurr)
      );
    }

    // trigger the tracking data
    trackingStudentAnswers();
  };

  const convertStudentData = async () => {
    let resultsObj = { ...answerResults };

    // if having drag-drop or drag-drop-single questions
    const dragdropQuestions = quizQuestions.filter(
      (ques) =>
        ques.question_type === "dragdrop" ||
        ques.question_type === "dragdropsingle"
    );
    dragdropQuestions.forEach((element) => {
      if (element.question_id in resultsObj) {
        let answersObj = {};
        const rightItems = resultsObj[element.question_id].rightItems;
        rightItems.forEach((element2) => {
          if ("rightId" in element2) {
            answersObj[element2.rightId.split("_")[1]] = element2.answer;
          }
        });
        resultsObj[element.question_id] = answersObj;
      }
    });

    // if having drag-drop-order questions
    const dragdropOrderQuestions = quizQuestions.filter(
      (ques) => ques.question_type === "dragdroporder"
    );
    dragdropOrderQuestions.forEach((element) => {
      if (element.question_id in resultsObj) {
        let answersObj = {};
        resultsObj[element.question_id].items.forEach((element2, index) => {
          answersObj[element2.id] = index + 1;
        });
        resultsObj[element.question_id] = answersObj;
      }
    });

    const s3UploadBucketUrl = "https://learning-backend.s3-ap-southeast-1.amazonaws.com/audios/";
    if(recordingType !== "upload") {
      // if having recording questions
      if (testCode && testCode !== "null" && userName && userName !== "null") {
        const recordingQuestions = quizQuestions.filter(
          (ques) => ques.question_type === "recording"
        );
        for (var element of recordingQuestions) {
          // check audio existing on S3
          const audioFileName = `blob_${testCode}_${userName}_${element.question_id}`;
          const audioFileFullUrl = s3UploadBucketUrl + audioFileName;
          // check audio file exists
          const blobExists = await checkAudioFileExists(audioFileFullUrl);
          if (blobExists) {
            resultsObj[element.question_id] = { value: audioFileFullUrl };
          } else {
            delete resultsObj[element.question_id];
          }
        }
      }
    }
    

    // if haveing multiquestion questions
    const multiQuestions = quizQuestions.filter(
      (ques) => ques.question_type === "multiquestion"
    );
    for (var el of multiQuestions) {
      if (el.question_id in resultsObj) {
        let childAnswersObject = { ...resultsObj[el.question_id] };
        // if having dragdrop or dragdrop-single sub questions
        const childDragdropQuestions = el.sub_questions.filter(
          (q2) =>
            q2.question_type === "dragdrop" ||
            q2.question_type === "dragdropsingle"
        );
        childDragdropQuestions.forEach((q3) => {
          if (q3.question_id in childAnswersObject) {
            let ansObj = {};
            const rItems = childAnswersObject[q3.question_id].rightItems;
            rItems.forEach((e) => {
              if ("rightId" in e) {
                ansObj[e.rightId.split("_")[1]] = e.answer;
              }
            });
            childAnswersObject[q3.question_id] = ansObj;
          }
        });

        // if having dragdroporder
        const childDragdropOrderQuestions = el.sub_questions.filter(
          (q2) => q2.question_type === "dragdroporder"
        );
        childDragdropOrderQuestions.forEach((q3) => {
          if (q3.question_id in childAnswersObject) {
            let ansObj = {};
            childAnswersObject[q3.question_id].items.forEach((o, index) => {
              ansObj[o.id] = index + 1;
            });
            childAnswersObject[q3.question_id] = ansObj;
          }
        });

        if(recordingType !== "upload") {
          // if having recording
          const childRecordingQuestions = el.sub_questions.filter(
            (q2) => q2.question_type === "recording"
          );
          for (var q3 of childRecordingQuestions) {
            // check audio existing on S3
            const audioFileName = `blob_${testCode}_${userName}_${q3.question_id}`;
            const audioFileFullUrl = s3UploadBucketUrl + audioFileName;
            const streamExists = await checkAudioFileExists(audioFileFullUrl);
            if (streamExists) {
              childAnswersObject[q3.question_id] = { value: audioFileFullUrl };
            } else {
              delete childAnswersObject[q3.question_id];
            }
          }
        }
        
        resultsObj[el.question_id] = childAnswersObject;
      }
    }
    return resultsObj;
  };

  const trackingStudentAnswers = async () => {
    const resultsObj = await convertStudentData();
    if (
      resultsObj !== null &&
      Object.keys(resultsObj).length > 0 &&
      testCode &&
      testCode !== "null" &&
      userName &&
      userName !== "null"
    ) {
      // tracking data
      let postData = {
        code: testCode,
        answerResults: resultsObj,
        studentName: userName,
        status: 1,
      };
      postTrackingStudentAnswers(postData);
    }
  };

  const handleFinish = async () => {
    // turn on spinner
    setLoadingSpinner(true);

    // trigger the tracking data
    await trackingStudentAnswers();

    const resultsObj = await convertStudentData();

    if(!Object.keys(resultsObj).length) {
      // turn off spinner
      setLoadingSpinner(false);
      toast.error("Your answers are empty! Please refresh the page and try again.");
      return;
    }

    // save to db
    let postData = {
      testCode: testCode,
      answerResults: resultsObj,
    };
    if (userName) {
      postData["studentName"] = userName;
    }
    const { data } = await postStudentAnswerResults(postData);

    // turn off spinner
    setLoadingSpinner(false);

    if (data.status_code !== 200 && data.status_code !== 201) {
      toast.error(data.message);
    } else {
      toast.success(data.message);
      dispatch({
        type: "updateStudentFinish",
        studentFinish: "yes",
      });
    }
  };

  if (studentFinish === "yes") {
    // return history.push(`/test/${testCode}/${userName}/`);
    history.push(`/`);
    return "";
  } else {
    if (studentFinish === "pending") {
      return (
        <Finish
          handleFinish={handleFinish}
          onSetCurrentQuestion={setCurrentQuestion}
          overrideSpinner={overrideSpinner}
          loadingSpinner={loadingSpinner}
        />
      );
    }
  }

  // Auto submit if time is over
  if (countdown === -1) {
    // handleFinish();
  }

  const _setVoiceQuestionCurr = (parentQuesId, quesId) => {
    localStorage.setItem(
      `yolatest_recording_voice_parent_question_curr_id_${testCode}_${userName}`,
      parentQuesId
    );
    localStorage.setItem(
      `yolatest_recording_voice_question_curr_id_${testCode}_${userName}`,
      quesId
    );
  };

  const RenderAnswer = () => {
    let render = "";
    if (
      questionType === "multichoice" ||
      questionType === "truefalse" ||
      questionType === "singlechoice"
    ) {
      render = (
        <ul className="answerOptions">
          {answerOptions.map((item, index) => (
            <AnswerOption
              key={item.content}
              answerContent={item.content}
              answerCorrect={item.correct}
              studentAnswers={studentAnswers}
              questionType={questionType}
              questionId={questionId}
              onAnswerSelected={(event) => setUserAnswer(event)}
            />
          ))}
        </ul>
      );
    } else if (questionType === "essay") {
      render = (
        <Essay
          onEssayChange={(event) => setUserAnswer(event)}
          studentAnswers={studentAnswers}
        />
      );
    } else if (
      questionType === "dragdrop" ||
      questionType === "dragdropsingle"
    ) {
      render = (
        <DragDrop
          onSetUserAnswer={setUserAnswer}
          studentAnswers={studentAnswers}
        />
      );
    } else if (questionType === "dragdroporder") {
      render = (
        <DragDropOrder
          onSetUserAnswer={setUserAnswer}
          studentAnswers={studentAnswers}
        />
      );
    } else if (questionType === "fillplace") {
      render = (
        <FillPlace
          onTextChange={(event) => setUserAnswer(event)}
          studentAnswers={studentAnswers}
          answerOptions={answerOptions}
        />
      );
    } else if (questionType === "fillplaceparagraph") {
      const paragraphQues = quizQuestions.filter(
        (ques) => ques.question_id === questionId
      )[0];
      render = (
        <FillPlaceParagraph
          onTextChange={(event) => setUserAnswer(event)}
          studentAnswers={studentAnswers}
          answerOptions={answerOptions}
          paragraph={paragraphQues.paragraph}
        />
      );
    } else if (questionType === "recording") {
      render = (
        <RecordingVoice
          onSave={setUserAnswer}
          audioObj={studentAnswers}
          quesId={questionId}
          setVoiceQuestionCurr={_setVoiceQuestionCurr}
          progressUpload={progressUpload}
        />
      );
    } else if (questionType === "multichoice_image") {
      render = (
        <MultichoiceImage
          onChange={(event) => setUserAnswer(event)}
          studentAnswers={studentAnswers}
          answerOptions={answerOptions}
        />
      );
    } else if (questionType === "speech_recognition") {
      render = <SpeechRecognitionQuestion answerOptions={answerOptions} />;
    }
    return render;
  };

  const getGridListCols = () => {
    if (isWidthUp("lg", props.width)) {
      return 3;
    }

    if (isWidthUp("md", props.width)) {
      return 2;
    }

    if (isWidthUp("sm", props.width)) {
      return 1;
    }

    return 1;
  };

  const getGridListCols2 = () => {
    if (isWidthUp("lg", props.width)) {
      return 1;
    }
    return 1;
  };

  const setSwipe = (way) => {
    switch (way) {
      case "left":
        setNextQuestion();
        break;
      case "right":
        setPreviousQuestion();
        break;
      default:
        return;
    }
  };

  addBackToTop({ backgroundColor: blue[400] });

  let questionRender = (
    <Question
      getGridListCols={getGridListCols}
      getGridListCols2={getGridListCols2}
      renderAnswer={RenderAnswer}
    />
  );
  if (questionType === "multiquestion") {
    questionRender = (
      <MultiQuestion
        getGridListCols={getGridListCols}
        getGridListCols2={getGridListCols2}
        setVoiceQuestionCurr={_setVoiceQuestionCurr}
        trackingStudentAnswers={trackingStudentAnswers}
      />
    );
  }

  // const connectionChange = (online) => {
  //   if (!online) {
  //     toast.error("No internet connection!");
  //   }
  //   return null;
  // };

  return (
    <React.Fragment>
      {/* <Detector render={({ online }) => connectionChange(online)} /> */}
      <CSSTransitionGroup
        className={classes.container}
        component="div"
        transitionName="fade"
        transitionEnterTimeout={800}
        transitionLeaveTimeout={500}
        transitionAppear
        transitionAppearTimeout={500}
        onCopy={(e) => e.preventDefault()}
        onCut={(e) => e.preventDefault()}
      >
        <Grid container alignContent="center" className={classes.root}>
          <RingLoader
            css={overrideSpinner}
            // size={150}
            color={blue[400]}
            loading={loadingSpinner}
          />
          <QuizInfo quizTitle={quizTitle} quizDescription={quizDescription} />
          <Swipe
            nodeName="div"
            className={classes.swipe}
            onSwipedRight={() => setSwipe("right")}
            onSwipedLeft={() => setSwipe("left")}
            detectTouch={true}
          >
            <Grid item xs={12} className={classes.questionCount}>
              <QuestionCount
                onSetPreviousQuestion={setPreviousQuestion}
                onSetNextQuestion={setNextQuestion}
              />
            </Grid>
          </Swipe>
          <Grid item xs={12}>
            {questionRender}
          </Grid>
          <Grid item xs={12} className={classes.groupQuestionButton}>
            <GroupQuestionButtons
              onSetQuestion={setQuestion}
              onSetNextQuestion={setNextQuestion}
            />
          </Grid>
        </Grid>
      </CSSTransitionGroup>
    </React.Fragment>
  );
}

const overrideSpinner = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1170,
    borderRadius: 3,
    margin: "5rem auto 2rem auto",
    flexGrow: 1,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: "20px 10px 20px 10px",
  },
  questionCount: {
    paddingBottom: "20px",
  },
  swipe: {
    width: "100%",
  },
  groupQuestionButton: {
    padding: theme.spacing(2),
  },
}));

export default withWidth()(Quiz);
