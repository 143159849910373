import { useState, useEffect } from "react";

import is from 'is_js';

import {postSubscription} from "../services/notificationService";
import Auth from '../Auth';


import {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription
} from "./push-notifications";
//import all the function created to manage the push notifications

const pushNotificationSupported = isPushNotificationSupported();
//first thing to do: check if the push notifications are supported by the browser

export default function usePushNotifications() {
  const [userConsent, setSuserConsent] = useState(typeof(Notification) !== "undefined" ? Notification.permission : null);
  //to manage the user consent: Notification.permission is a JavaScript native function that return the current state of the permission
  //We initialize the userConsent with that value
  const [userSubscription, setUserSubscription] = useState(null);

  const [currentUser, setCurrentUser] = useState();

  const [postedToPushServer, setPostedToPushServer] = useState(null);

  /**
   * define a click handler that asks the user permission,
   * it uses the setSuserConsent state, to set the consent of the user
   * If the user denies the consent, an error is created with the setError hook
   */
  if (userConsent !== "granted") {
    askUserPermission().then(consent => {
      setSuserConsent(consent);
      if (consent !== "granted") {
        console.log('You denied the consent to receive notifications');
      } else {
        onClickSusbribeToPushNotification();
      }
    }).catch(error => {
      console.log(error);
    });
  }

  useEffect(() => {
    const auth = new Auth();
    if(auth.isLoggedIn()) {
      setCurrentUser(auth.currentUser());
    }
  }, []);

  useEffect(() => {
    if (pushNotificationSupported) {

      registerServiceWorker().then(() => {
        
      });
    }
  }, []);
  //if the push notifications are supported, registers the service worker
  //this effect runs only the first render
  
  useEffect(() => {

    const getExixtingSubscription = async () => {
      const existingSubscription = await getUserSubscription();
      setUserSubscription(existingSubscription);

    };
    getExixtingSubscription();
  }, []);
  //Retrieve if there is any push notification subscription for the registered service worker
  // this use effect runs only in the first render


  /**
   * define a click handler that creates a push notification subscription.
   * Once the subscription is created, it uses the setUserSubscription hook
   */
  const onClickSusbribeToPushNotification = () => {

    createNotificationSubscription()
      .then(function(subscrition) {
        setUserSubscription(subscrition);
      })
      .catch(err => {
        console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);

      });
  };

  /**
   * define a click handler that sends the push susbcribtion to the push server.
   * Once the subscription ics created on the server, it saves the id using the hook setPushServerSubscriptionId
   */
  const onClickSendSubscriptionToPushServer = () => {
    let postData = JSON.parse(JSON.stringify(userSubscription));
    let browser = null;
    if(is.chrome()) {
      browser = 'CHROME';
    } else if(is.firefox()) {
      browser = 'FIREFOX';
    } else if(is.opera()) {
      browser = 'OPERA';
    }
    if(browser) {
      postData['browser'] = browser;
      if(currentUser) {
        postSubscription(postData)
          .then(function(response) {
            if(response.status === 200) {
              setPostedToPushServer(true);
            }
          })
          .catch(err => {
  
          }
        );
      }
    }
    
  };

  if(userSubscription && postedToPushServer === null) {
    onClickSendSubscriptionToPushServer();
  }

  /**
   * returns all the stuff needed by a Component
   */
  return {
    onClickSusbribeToPushNotification,
    onClickSendSubscriptionToPushServer,
    userConsent,
    pushNotificationSupported,
    userSubscription
  };
}
