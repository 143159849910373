import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { grey, blue } from '@material-ui/core/colors';
import CardMedia from '@material-ui/core/CardMedia';
import Forward from '@material-ui/icons/Forward';
import { Typography } from '@material-ui/core';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, droppableId, isDragDisabled=false) => {
    let result = {
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? 'lightgreen' : '#fff',

        fontSize: '1rem',

        // styles we need to apply on draggables
        ...draggableStyle
    };

    if(!isDragging && droppableId === 'rightItems') {
        result = {...result, ...{
            marginTop: "-75px",
            marginLeft: "13%",
            marginBottom: "24px",
            opacity: "0.8",
            backgroundColor: 'lightblue',
            textAlign: "center",
            width: "75%",
            padding: "10px",
        }};
    }
    if(isDragDisabled === true) {
        result['minHeight'] = '120px';
    }

    return result;
};

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : grey[200],
    padding: grid,
    width: 200,
});

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex", 
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "600px"
    },
    media: {
        height: "auto",
        "& img": {
            width: "100%",
            height: "auto"
        },
    },
    forward: {
        paddingTop: "30%",
        '& svg': {
            fontSize: "36px",
            color: blue[500],
        }
    },
    answerText: {
        fontSize: "1rem",
    }
}));

function DragDrop(props) {

    const classes = useStyles();

    /**
     * Moves an item from one list to another list.
     */
    const _move = (source, destination, droppableSource, droppableDestination) => {
    
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
    
        destClone.splice(droppableDestination.index, 0, removed);
    
        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;
    
        return result;
    };

    const onBeforeDragStart = result => {
        // disable drag origin right items
        if(result.draggableId.split('_')[0] === 'r') return;
    }

    const onDragEnd = result => {
        const { source, destination } = result;
        if(result.combine) {

            if(source.droppableId === 'rightItems' || result.combine.draggableId.split('_')[0] === 'l') return;

            const existedCombine = props.studentAnswers['rightItems'].filter(item => item.rightId && item.rightId === result.combine.draggableId);
            if(existedCombine.length > 0) return;
            
            const des = {
                droppableId: 'rightItems',
                index: 0
            };
            // move to another side
            const res = _move(
                props.studentAnswers['leftItems'],
                props.studentAnswers['rightItems'],
                source,
                des
            );

            let rightItems = res.rightItems.map(item => {
                if(item.id === result.draggableId) {
                    return {...item, rightId: result.combine.draggableId }
                }
                return item;
            });

            rightItems = _reorderRightItems(rightItems);

            props.onSetUserAnswer({
                leftItems: res.leftItems,
                rightItems: rightItems
            });

        } else {
            // dropped outside the list
            if (!destination || source.droppableId === 'leftItems' 
                || source.droppableId === destination.droppableId) {
                return;
            }

            // move to another side
            const res = _move(
                props.studentAnswers[source.droppableId],
                props.studentAnswers[destination.droppableId],
                source,
                destination
            );

            const leftItems = res.leftItems.filter(item => item).map(item => {
                if(item.id === result.draggableId) {
                    delete item['rightId'];
                }
                return item;
            });

            props.onSetUserAnswer({
                leftItems: leftItems,
                rightItems: res.rightItems
            });
        }
    };

    const _reorderRightItems = (rightItems) => {
        let rightItemsFigure = rightItems.filter(item => !('rightId' in item));
        let rightItemsAnswer = rightItems.filter(item => ('rightId' in item));

        let result = [];
        rightItemsFigure.forEach(element => {
            result.push(element);
            rightItemsAnswer.forEach(element2 => {
                if(element2.rightId === element.id) {
                    result.push(element2);
                }
            });
        });

        return result;
    }

    const _showItem = (item, index, droppableId) => {
        let s = '';
        let isDragDisabled = false;
        if(droppableId === 'rightItems' && !('rightId' in item)) {
            isDragDisabled = true;
        }
        let cls = null;

        if(item.answer) {
            s = item.answer;
            cls = droppableId;
        } else {
            if(item.figure || item.text) {
                s = (
                    <React.Fragment>
                        {item.text && <Typography className={classes.answerText} component="p">{item.text}</Typography>}
                        {item.figure && (
                            <CardMedia className={classes.media}>
                                <img src={item.figure} alt="" />
                            </CardMedia>
                        )}
                    </React.Fragment>
                );
            }
            if(droppableId === 'rightItems' && ('rightId' in item)) {
                cls = droppableId;
            }
        }
        
        return (
            <Draggable isDragDisabled={isDragDisabled}
                draggableId={item.id}
                index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            cls,
                            isDragDisabled
                        )}>
                        {s}
                    </div>
                )}
            </Draggable>
        );
    }

    const _showRightItems = (rightItems) => {

        const result = rightItems.map((item, index) => {
            return (
                <React.Fragment key={item.id}>
                    {_showItem(item, index, 'rightItems')}
                </React.Fragment>
            );
        });

        return result;
    }

    return (
        <div className={classes.root}>
            <DragDropContext onBeforeDragStart={onBeforeDragStart} onDragEnd={onDragEnd}>
                <Droppable droppableId="leftItems">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>

                            {props.studentAnswers['leftItems'] && props.studentAnswers.leftItems.map((item, index) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        {_showItem(item, index)}
                                    </React.Fragment>
                                );
                            })}

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                <div className={classes.forward}><Forward /></div>
                <Droppable droppableId="rightItems" isCombineEnabled={true}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            
                            {props.studentAnswers['rightItems'] && _showRightItems(props.studentAnswers.rightItems)}
                            
                            {provided.placeholder}

                        </div>
                    )}
                </Droppable>                
            </DragDropContext>
        </div>
    );
}

DragDrop.propTypes = {
    onSetUserAnswer: PropTypes.func.isRequired,
    studentAnswers: PropTypes.object.isRequired
}

export default DragDrop;