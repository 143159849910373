import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { UseStateValue } from '../../state';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import { Grid, GridList, GridListTile, CardMedia } from '@material-ui/core';
import { checkAudioFileExists, uploadAudioFile } from '../../services/quizService';
import AnswerOption from './AnswerOption';
import Essay from './Essay';
import DragDrop from './DragDrop';
import FillPlace from './FillPlace';
import RecordingVoice from './RecordingVoice';
import MultichoiceImage from './MultichoiceImage';
import DragDropOrder from './DragDropOrder';
import FillPlaceParagraph from './FillPlaceParagraph';


const useStyles = makeStyles(theme => ({
    left: {
        height: "85vh",
        overflowY: "auto",
        overflowX: "hidden",
        padding: theme.spacing(1),
        alignSelf: "flex-start"
    },
    right: {
        height: "85vh",
        overflowY: "auto",
        overflowX: "hidden",
        padding: theme.spacing(1),
        alignSelf: "flex-start"
    },
    explanation: {
        whiteSpace: "pre-line",
        paddingTop: theme.spacing(2),
        fontSize: "1.1rem"
    },
    questionName: {
        marginTop: 0,
        marginBottom: 4
    },
    media: {
        height: "auto",
        "& img": {
            width: "100%",
            height: "auto"
        }
    },
    questionAudio: {
        height: "100px",
        "& div": {
            alignItems: "center",
            display: "flex",
        }
    },
    childQuestion: {
        padding: "8px 0"
    },
    childQuestionContent: {
        paddingBottom: theme.spacing(2)
    },
    multiChoiceImage: {
        "img": {
            width: "100% !important"
        }
    }
}));

function MultiQuestion(props) {
    const classes = useStyles();

    const [progressUpload, setProgressUpload] = useState(0);

    const [{testCode,
            userName,
            quizQuestions,
            counter,
            questionId,
            question, 
            questionExplanation, 
            questionFigure, 
            questionAudio, 
            questionVideo,
            audiosPlayed,
            studentAnswers,
            answerResults,
            recordingType
        }, dispatch] = UseStateValue();

      const handleAudioStart = (quesId=null) => {
        if(quesId === null) return;
        let ap = {...audiosPlayed};
        ap[quesId] = "played";
        dispatch({
          type: "updateAudiosPlayed",
          audiosPlayed: ap
        });
      }

    const mediaRender = (figure=null, audio=null, video=null, quesId=null) => {
        if(figure) {
            figure = (<GridListTile cols={1}>
            <CardMedia className={classes.media} title={question}>
                <img src={figure} alt={question} />
            </CardMedia>
            </GridListTile>);
        }
        if(audio) {
            audio = (<GridListTile cols={1} className={classes.questionAudio}>
                <ReactPlayer url={audio} loop={false} width="100%" height="50px" 
                  config={{ 
                    file: { 
                      attributes: {
                        onContextMenu: e => e.preventDefault(),
                        controlsList: 'nodownload'
                      } 
                    } 
                  }} 
                  controls={(quesId != null && quesId in audiosPlayed) ? true : true}
                  onStart={() => handleAudioStart(quesId)}
                />
            </GridListTile>);
        }
        if(video) {
            video = (<GridListTile cols={1} className={classes.questionVideo}>
            <ReactPlayer url={video} controls={true} width="100%" height="100%" 
              config={{ 
                file: { 
                  attributes: {
                    onContextMenu: e => e.preventDefault(),
                    controlsList: 'nodownload'
                  } 
                } 
              }} />
            </GridListTile>);
        }
        return (
            <GridList spacing={15} cellHeight={"auto"} cols={1}>
              {audio && audio}
              {video && video}
              {figure && figure}
            </GridList>
        );
    }

    const setAnswer = async (item, answerObj) => {
        if(typeof answerObj.persist === 'function') {
            answerObj.persist();
        }
        let parentQuesId = questionId;
        let recQuesId = item.question_id;
        let sa = {...studentAnswers};
        let lsAnswerResults = {...answerResults};
        if(item.question_type === 'recording' && recordingType !== "upload") {
            const lsParentQuesId = localStorage.getItem(`yolatest_recording_voice_parent_question_curr_id_${testCode}_${userName}`);
            if(lsParentQuesId) {
              parentQuesId = parseInt(lsParentQuesId);
            }
            const lsRecQuesId = localStorage.getItem(`yolatest_recording_voice_question_curr_id_${testCode}_${userName}`);
            if(lsRecQuesId) {
              recQuesId = parseInt(lsRecQuesId);
            }
            let lsAnswerResultsTmp = localStorage.getItem(`yolatest_answerResults_${testCode}_${userName}`);
            if(lsAnswerResultsTmp) {
                lsAnswerResults = JSON.parse(lsAnswerResultsTmp);
                sa = lsAnswerResults[parentQuesId] ? lsAnswerResults[parentQuesId] : {}; 
            }
        }

        // prevent question id null
        if(!parentQuesId || !recQuesId) return;
        
        let answers = (sa[recQuesId]) ? sa[recQuesId] : {};
    
        if(item.question_type === 'multichoice') {
          if(answerObj.currentTarget.checked) {
            answers[answerObj.currentTarget.value] = answerObj.currentTarget.dataset.answercorrect; // or answerObj.currentTarget.getAttribute("data-answercorrect")
          } else {
            delete answers[answerObj.currentTarget.value];
          }
        } else if(item.question_type === 'multichoice_image') {
          answers = {};
          answers[answerObj.currentTarget.value] = 'checked';
        } else if(item.question_type === 'truefalse' || item.question_type === 'singlechoice') {
          answers = {};
          answers[answerObj.currentTarget.value] = answerObj.currentTarget.dataset.answercorrect; // or answerObj.currentTarget.getAttribute("data-answercorrect")
        } else if(item.question_type === 'essay') {
          answers = {};
          answers['value'] = answerObj.currentTarget.value;
        } else if(item.question_type === 'dragdrop' || item.question_type === 'dragdropsingle' || item.question_type === 'dragdroporder') {
          answers = answerObj;
        } else if(item.question_type === 'fillplace') {
          // trim multiple spaces
          const v = answerObj.currentTarget.value.replace(/\s+/g, ' ').trim();
          if(v !== '') {
            answers[answerObj.currentTarget.id] = v;
          }
        } else if(item.question_type === 'fillplaceparagraph') {
          // trim multiple spaces
          const v = answerObj.currentTarget.value.replace(/\s+/g, ' ').trim();
          if(v !== '') {
            answers[answerObj.currentTarget.id] = v;
          }
        } else if(item.question_type === 'recording') {
          answers = {};
          if(Object.keys(answerObj).length > 0) {
            if(answerObj.blobURL) {
              // upload blog file to the server
              const blobExists = await checkAudioFileExists(answerObj.blobURL);
              if(blobExists) {
                const blobRes = await fetch(answerObj.blobURL);
                const blob = await blobRes.blob();
                const fileName = `${testCode}_${userName}_${recQuesId}`;
                const { data } = await uploadAudioFile(blob, userName, fileName);
                if(data.status_code === 200) {
                  answers = {value: data.audio_link};
                }
              }
            } else if(answerObj.value) {
              // receive socket upload
              answers = {value: answerObj.value};
              setProgressUpload(100);
            }
          } else if(answerObj && answerObj.name) {
            // upload audio file to the server
            const fileName = `${testCode}_${userName}_${recQuesId}`;
            const { data } = await uploadAudioFile(answerObj, userName, fileName, (event) => {
              setProgressUpload(Math.round((100 * event.loaded) / event.total));
            });
            if (data.status_code === 200) {
              answers = { value: data.audio_link };
            }
          }
        }

        sa[recQuesId] = answers;
        
        await dispatch({
          type: 'updateStudentAnswers',
          studentAnswers: sa
        });

        lsAnswerResults[parentQuesId] = sa;
    
        await dispatch({
          type: 'updateAnswerResults',
          answerResults: lsAnswerResults
        });
    
        // update local storage
        if(Object.keys(lsAnswerResults).length) {
          localStorage.setItem(`yolatest_answerResults_${testCode}_${userName}`, JSON.stringify(lsAnswerResults));
        }

        // trigger the tracking data
        props.trackingStudentAnswers();
    }

    const renderQuestionAnswer = (item) => {
        let render = '';
        let sa = studentAnswers[item.question_id] ? studentAnswers[item.question_id] : {};
        if(item.question_type === 'multichoice' || item.question_type === 'truefalse' || item.question_type === 'singlechoice') {
          render = (
            <ul className="answerOptions">
              {item.answers.map((item2, index2) => (
                <AnswerOption
                  key={index2}
                  answerContent={item2.content}
                  answerCorrect={item2.correct}
                  studentAnswers={sa} 
                  questionType={item.question_type}
                  questionId={item.question_id}
                  onAnswerSelected={(event) => setAnswer(item, event)}
                />
              ))}
            </ul>
          );
        } else if(item.question_type === 'essay') {
          render = <Essay onEssayChange={(event) => setAnswer(item, event)} 
                    studentAnswers={sa}
                />;
        } else if(item.question_type === 'dragdrop' || item.question_type === 'dragdropsingle') {
          render = <DragDrop onSetUserAnswer={(obj) => setAnswer(item, obj)} 
                    studentAnswers={sa}
                />;
        } else if(item.question_type === 'dragdroporder') {
          render = <DragDropOrder onSetUserAnswer={(obj) => setAnswer(item, obj)} 
                    studentAnswers={sa}
                />
        } else if(item.question_type === 'fillplace') {
          render = <FillPlace onTextChange={(event) => setAnswer(item, event)} 
                    studentAnswers={sa} 
                    answerOptions={item.answers}
                />;
        } else if(item.question_type === 'fillplaceparagraph') {
          render = <FillPlaceParagraph onTextChange={event => setAnswer(item, event)} 
                    studentAnswers={sa} answerOptions={item.answers} 
                    paragraph={item.paragraph} 
                />;
        } else if(item.question_type === 'recording') {
          render = <RecordingVoice onSave={(obj) => setAnswer(item, obj)} audioObj={sa} 
                    setVoiceQuestionCurr={props.setVoiceQuestionCurr} 
                    quesId={item.question_id} parentQuesId={questionId}
                    progressUpload={progressUpload} />;
        } else if(item.question_type === 'multichoice_image') {
          render = <MultichoiceImage onChange={(event) => setAnswer(item, event)} 
                      studentAnswers={sa} 
                      answerOptions={item.answers}
                      className={classes.multiChoiceImage}
                />;
        }
        return render;
    }

    const showMultiQuestions = (item, index) => {
        return (
            <div key={index} className={classes.childQuestion}>
                <div className={classes.childQuestionContent}>
                    <h3 className={classes.questionName}>{item.question}</h3>
                    {mediaRender(item.figure, item.audio, item.video, item.question_id)}
                    {item.explanation && <p className={classes.explanation}>{item.explanation}</p>}
                </div>
                <div>{renderQuestionAnswer(item)}</div>
            </div>
        );
    }
    
    return (
        <Grid container alignItems="center" className={classes.root}>
            <Grid item xs={6} className={classes.left}>
                <h3 className={classes.questionName}>{question}</h3>
                {mediaRender(questionFigure, questionAudio, questionVideo, questionId)}
                <p className={classes.explanation}>{questionExplanation}</p>
            </Grid>
            <Grid item xs={6} className={classes.right}>
                {quizQuestions[counter] && quizQuestions[counter]["sub_questions"].map((item, index) => showMultiQuestions(item, index))}
            </Grid>
        </Grid>
    );
}

MultiQuestion.propTypes = {
    getGridListCols: PropTypes.func.isRequired,
    getGridListCols2: PropTypes.func.isRequired,
    setVoiceQuestionCurr: PropTypes.func.isRequired,
    trackingStudentAnswers: PropTypes.func.isRequired
};

export default MultiQuestion;