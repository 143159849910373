import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ReactMic } from 'react-mic';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ReactPlayer from 'react-player';
import { red } from '@material-ui/core/colors';
import Info from './Info';


const useStyles = makeStyles(theme => ({
    status: {
        margin: theme.spacing(0),
        "& canvas": {
            width: "100%",
            height: "40px"
        }
    },
    button: {
        margin: theme.spacing(1)
    }
}));

const info = `MICRO CHECK
    Please check your micro before entering the test   
    1. Click Check micro and record your voice
    2. Click Done then click Play button to hear the recording
    3. Adjust the volume if necessary
    4. Turn on your micro if your voice is not recorded
    5. Click check micro to record again
    6. Contact the proctor if your voice is still not recorded.`;

function CheckAudio(props) {
    const classes = useStyles();
    const [recordStatus, setRecordStatus] = useState(false);
    const [blobExists, setBlobExists] = useState(false);
    const [audioObj, setAudioObj] = useState({});

    const startRecording = (e) => {        
        setRecordStatus(true);

        props.setEnableStart(false);
    }

    const stopRecording = () => {
        setRecordStatus(false);
    }

    const onStop = (recordedBlob) => {
        setAudioObj(recordedBlob);

        props.setEnableStart(true);
    }

    if(audioObj['blobURL']) {
        fetch(audioObj['blobURL'])
        .then(() => setBlobExists(true))
        .catch(() => setBlobExists(false));
    }

    return (
        <Grid container alignItems="center" className={classes.root}>
            <Grid item xs={12} className={classes.status}>
                <ReactMic
                    record={recordStatus}
                    className="sound-wave"
                    onStop={onStop}
                    strokeColor="#fff"
                    backgroundColor={red[100]} 
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Grid item xs={10}>
                        <Button disabled={recordStatus} type="button" size="small"
                            color="secondary" variant="outlined"
                            className={classes.button} 
                            onClick={startRecording}>Check micro</Button>
                        <Button disabled={!recordStatus} type="button" size="small"
                            color="primary" variant="outlined"
                            className={classes.button} 
                            onClick={stopRecording} >Done</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Info info={info} />
                    </Grid>
                </Grid>
            </Grid>
            {blobExists && (
                <Grid item xs={12}>
                    <ReactPlayer url={audioObj['blobURL']} controls={true} width="100%" height="30px" />
                </Grid>
            )}
        </Grid>
    );
}

CheckAudio.propTypes = {
    setEnableStart: PropTypes.func.isRequired
}


export default CheckAudio;