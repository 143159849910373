import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import { blue } from '@material-ui/core/colors';


const useStyles = makeStyles(theme => ({
    iconInfo: {
        color: blue[500],
    },
    typography: {
        padding: theme.spacing(2),
        whiteSpace: "pre-line"
    },
}));


function Info(props) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <InfoIcon className={classes.iconInfo} fontSize="large" onClick={handleClick} />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>{props.info}</Typography>
            </Popover>
        </React.Fragment>
    );
}

Info.propTypes = {
    info: PropTypes.string.isRequired,
}

export default Info;