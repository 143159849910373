import React from 'react';
import PropTypes from 'prop-types';
import { UseStateValue } from '../../state';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, ButtonGroup } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  ButtonGroup: {
    width: "100%",
    display: "block"
  },
  numberBtn: {
    margin: "0 4px 4px 4px",
    borderColor: grey[400] + ' !important'
  },
  numberBtnEdited: {
    margin: "0 4px 4px 4px",
    borderColor: red[400] + ' !important'
  },
  btnFinish: {
    margin: "0 4px",
    borderBottomColor: "inherit"
  }
}));

function GroupQuestionButtons(props) {
  const classes = useStyles();
  const [{quizQuestions, questionOrder, answerResults, questionsShow}] = UseStateValue();
  const showNumberButton = (q, index) => {
    let btnColor = "default";
    let className = classes.numberBtn;
    if(q.question_id in answerResults) {
      btnColor = "secondary";
      className = classes.numberBtnEdited;
    }
    let numberBtn = <Button className={className} color={btnColor} key={index} onClick={() => props.onSetQuestion(index, index + 1)}>{index + 1}</Button>;
    if(questionsShow.filter(qs => (qs.questionId === q.question_id)).length === 0) {
      numberBtn = <Button className={className} color={btnColor} key={index} disabled={true}>{index + 1}</Button>;
    }
    if(questionOrder === index + 1) {
      numberBtn = <Button className={className} key={index} variant="contained" onClick={() => props.onSetQuestion(index, index + 1)}>{index + 1}</Button>;
    }
    return numberBtn;
  }
  
  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs={12}>
        <ButtonGroup color="primary" aria-label="outlined primary button group" 
          className={classes.ButtonGroup}>
          {quizQuestions.map((q, index) => showNumberButton(q, index))}
          {questionOrder === quizQuestions.length && (
            <Button onClick={props.onSetNextQuestion} className={classes.btnFinish}>Finish</Button>
          )}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}

GroupQuestionButtons.propTypes = {
  onSetQuestion: PropTypes.func.isRequired,
  onSetNextQuestion: PropTypes.func.isRequired
}

export default GroupQuestionButtons;

