import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Class from "@material-ui/icons/Class";
import palette from "../theme/palette";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "1rem",
    margin: "24px auto",
  },
  link: {
    display: "flex",
    cursor: 'pointer',
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: palette.info.main
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export default function DashboardBreadcrumbs(props) {
  const classes = useStyles();
  const { courseName, loadProfile, studentName } = props;

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
      <Link color="inherit" onClick={loadProfile} className={classes.link}>
        <AccountCircle className={classes.icon} />
        {studentName}
      </Link>
      <Typography color="textPrimary">
        <Class className={classes.icon} />
        {courseName}
      </Typography>
    </Breadcrumbs>
  );
}

DashboardBreadcrumbs.propTypes = {
  courseName: PropTypes.string.isRequired,
  loadProfile: PropTypes.func.isRequired,
  studentName: PropTypes.string.isRequired
};
