import React from "react";
import clsx from "clsx";
import uuid from "uuid/v1";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  Typography,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
  rating: {
    display: "flex",
    flexDirection: "column",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
    "& > .MuiRating-root": {
      fontSize: "1.5rem",
    },
  },
  absent: {
    fontSize: "0.9rem"
  }
}));

const List3 = (props) => {
  const { className, comments } = props;

  const classes = useStyles();

  let data = [];
  for (const item of comments) {
    const commentObj = Object.entries(item)[0][1];
    let commentData = {
      id: uuid(),
      day: Object.entries(item)[0][0],
      comment: commentObj["comment"],
      star: commentObj["star"],
    };
    data.push(commentData);
  }

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title="Teacher's comments" />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Day</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell>Star</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d) => (
                <TableRow hover key={d.id}>
                  <TableCell>{d.day}</TableCell>
                  <TableCell>{d.comment}</TableCell>
                  <TableCell>
                    {d.star < 4 && (
                      <div className={classes.rating}>
                        <Rating
                          max={3}
                          name="daily-star-rating"
                          defaultValue={0}
                          precision={0.1}
                          value={(d.star * 3) / 3}
                        />
                      </div>
                    )}
                    {d.star === 4 && 
                      <Typography variant="caption" className={classes.absent}>Absent</Typography>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

List3.propTypes = {
  className: PropTypes.string,
  comments: PropTypes.array.isRequired,
};

export default List3;
