import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import CardMedia from '@material-ui/core/CardMedia';

import { linkify } from '../../utils/helper';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    overflowX: 'hidden'
  },
  message: {
    fontSize: '1rem',
    "& .MuiAlert-message": {
      width: '100%'
    }
  },
  media: {
    height: "auto",
    "& img": {
      width: "100%",
      height: "auto"
    },
    marginTop: "12px",
    width: "90%"
  },
  body: {
    whiteSpace: "pre-line",
    wordWrap: "break-word",
    position: "relative",
    zIndex: 2
  },
  date: {
    paddingTop: theme.spacing(1)
  }
}));

const NotificationDetail = props => {
  const { className } = props;

  const classes = useStyles();

  const {notificationType} = props;

  const objComment = ('comment' in notificationType && notificationType['comment'].length > 0) 
    ? notificationType['comment'][notificationType['comment'].length - 1] : {};

  const objAnnouncement = ('announcement' in notificationType && notificationType['announcement'].length > 0) 
    ? notificationType['announcement'][0] : {};

  const objNote = ('note' in notificationType && notificationType['note'].length > 0) 
    ? notificationType['note'][0] : {};
  
  return (
    <div className={clsx(classes.root, className)}>
      {'comment' in notificationType && Object.keys(objComment).length > 0 && 
        Object.entries(objComment)[0][1]['comment'] !== "" && 
        <Alert severity="info" className={classes.message}>
          <AlertTitle>{Object.entries(objComment)[0][0]}</AlertTitle>
          <span className={classes.body} dangerouslySetInnerHTML={{ __html: linkify(Object.entries(objComment)[0][1]['comment']) }} />
        </Alert>
      }
      {'announcement' in notificationType && Object.keys(objAnnouncement).length > 0 &&
        <Alert severity="info" className={classes.message}>
          <AlertTitle>{objAnnouncement['title']}</AlertTitle>
          <span className={classes.body} dangerouslySetInnerHTML={{ __html: linkify(objAnnouncement['body']) }} />
          {objAnnouncement['image'] && 
            <CardMedia className={classes.media} title={objAnnouncement['title']}>
              <img src={objAnnouncement['image']} alt="" />
            </CardMedia>
          }
          <h5 className={classes.date}>{objAnnouncement['date']}</h5>
        </Alert>
      }
      {'note' in notificationType && Object.keys(objNote).length > 0 && 
        <Alert severity="info" className={classes.message}>
          <AlertTitle>New notification</AlertTitle>
          <span className={classes.body} dangerouslySetInnerHTML={{ __html: linkify(objNote['body']) }} />
          <h5 className={classes.date}>{objNote['date']}</h5>
        </Alert>
      }
    </div>
  );
};

NotificationDetail.propTypes = {
  className: PropTypes.string,
  notificationType: PropTypes.object
};

export default NotificationDetail;
