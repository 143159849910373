import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Countdown from 'react-countdown-now';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Apps from '@material-ui/icons/Apps';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { Grid, Card, CardActionArea, CardMedia } from '@material-ui/core';
import Auth from '../Auth';
import { UseStateValue } from '../state';
import DrawerMenu from './DrawerMenu';


const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
  },
  header: {
    backgroundColor: "#02acf1",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    "& div": {
      minHeight: "auto"
    }
  },
  title: {
    flexGrow: 1,
    alignItems: "center",
    marginRight: 16
  },
  welcome: {
      flexGrow: 1,
      fontWeight: "bold",
      color: "inherit"
  },
  countdown: {
    flexGrow: 1,
    fontWeight: "bold",
    color: "#FFEB3B"
  },
  headerMenu: {
    zIndex: theme.zIndex.appBar,
    position: "relative",
    background: "#fff"
  },
  headerMenuRow: {
    padding: theme.spacing(1)
  },
  headerMenuItem: {
    padding: theme.spacing(1)
  },
  appIcon: {
    height: 36
  },
  appText: {
    textAlign: "center",
    margin: 0,
    lineHeight: 1,
  },
  headerMenuItemWraper: {
    width: 50,
    padding: 4
  }
}));

export default function Header(props) {
  const classes = useStyles();

  const [{testCode, countdown}, dispatch] = UseStateValue();

  const [currentUser, setCurrentUser] = useState((new Auth()).currentUser());

  useEffect(() => {
    const auth = new Auth();
    if(auth.isLoggedIn()) {
      setCurrentUser(auth.currentUser());
    }
  }, [setCurrentUser]);
  
  /** Header Menu */
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const handleToggleHeaderMenu = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleCloseHeaderMenu = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  /** End Header Menu */

  /** Countdown */
  // Renderer callback with condition
  const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
    let leftSeconds = (hours * 3600) + (minutes * 60) + seconds;
    if(testCode) {
      if(leftSeconds >= 0) {
        return <span>Left time: {hours}:{minutes}:{seconds}</span>;
      }
    }
    return '';
  };

  const onComplete = () => {
    toast.error('Time over');
    dispatch({
      type: 'updateCountdown',
      countdown: -1
    });
  }
  /** End Countdown */

  const backHome = () => {
    dispatch({
      type: 'loadData',
      testCode: null,
      quizQuestions: []
    });
  }

  const goToPage = (page) => {
    window.location.href = page;
  }

  const displayUserInformation = () => {
    let userInfo = null, profileBtn = null, cd = null;
    if (currentUser) {
      userInfo = (
        <React.Fragment>
            <Typography className={classes.welcome}>
                Welcome, {currentUser.firstName} {currentUser.lastName}
            </Typography>
        </React.Fragment>
      );
    }
    if (!currentUser) {
      profileBtn = <Button color="inherit" onClick={() => window.location.href = '/profile/dashboard'}>Profile</Button>
    }
    if(testCode) {
      cd = (
        <Typography className={classes.countdown}>
          <Countdown date={countdown} 
            renderer={rendererCountdown} onComplete={onComplete} />
        </Typography>
      );
    }
    let logoutBtn = null;
    if(currentUser) {
      logoutBtn = <Button color="inherit" onClick={() => (new Auth()).logout()}>Logout</Button>;
    }
    return (
      <React.Fragment>
        {userInfo}
        {cd}
        {profileBtn}
        {logoutBtn}
      </React.Fragment>
    );
  };

  const displayHeaderMenu = () => {
    return (
      <React.Fragment>
        <IconButton ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} 
          aria-haspopup="true" aria-label="More apps" edge="end" color="inherit" 
          className={classes.menuButton} onClick={handleToggleHeaderMenu}>
          <Apps />
        </IconButton>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} 
          transition disablePortal className={classes.headerMenu} >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              className={classes.headerMenu}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseHeaderMenu}>
                  <Grid container justify="center" className={classes.headerMenuRow}>
                    <Grid item xs={4} className={classes.headerMenuItem}>
                      <Card className={classes.headerMenuItemWraper}>
                        <CardActionArea onClick={() => goToPage('https://m.yola.vn')}>
                          <CardMedia
                            className={classes.appIcon}
                            image="/icon/favicon-96x96.png"
                            title="App"
                          />
                          <Typography gutterBottom component="p" className={classes.appText}>
                            App
                          </Typography>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={4} className={classes.headerMenuItem}>
                      <Card className={classes.headerMenuItemWraper}>
                        <CardActionArea onClick={() => goToPage('https://learn.yola.vn')}>
                          <CardMedia
                            className={classes.appIcon}
                            image="/img/learn-online-96x96.png"
                            title="Learn"
                          />
                          <Typography gutterBottom component="p" className={classes.appText}>
                            Learn
                          </Typography>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={4} className={classes.headerMenuItem}>
                      <Card className={classes.headerMenuItemWraper}>
                        <CardActionArea onClick={() => goToPage('https://yola.vn')}>
                          <CardMedia
                            className={classes.appIcon}
                            image="/img/yola-website-96x96.png"
                            title="Website"
                          />
                          <Typography gutterBottom component="p" className={classes.appText}>
                              Web
                          </Typography>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    )
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <DrawerMenu />
          <Typography variant="h6" className={classes.title}>
            <Link to='/' onClick={backHome}>
              <img src="/yola-white.png" className="App-logo" alt="logo" />
            </Link>
          </Typography>
          {displayUserInformation()}
          {displayHeaderMenu()}
        </Toolbar>
      </AppBar>
    </div>
  );
}