import React from 'react';
import { UseStateValue } from '../../state';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ReactPlayer from 'react-player';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    padding: 0
  },
  cardContent: {
    padding: "16px 8px",
  },
  media: {
    height: "auto",
    "& img": {
      maxWidth: "100%",
      height: "auto"
    }
  },
  question: {
    paddingTop: "10px",
    paddingBottom: "20px"
  },
  questionAudio: {
    "& div": {
        alignItems: "center",
        display: "flex",
        padding: theme.spacing(1)
    }
  },
  questionVideo: {
    "& div": {
      padding: theme.spacing(1)
    }
  },
  explanation: {
    margin: 0,
    whiteSpace: "pre-line",
    fontSize: "1rem"
  }
}));

function Question(props) {
  const [{question, questionId, questionType, questionExplanation, questionFigure, questionAudio, 
          questionVideo, audiosPlayed}, dispatch] = UseStateValue();
  const classes = useStyles();

  const handleAudioStart = () => {
    let ap = {...audiosPlayed};
    ap[questionId] = "played";
    dispatch({
      type: "updateAudiosPlayed",
      audiosPlayed: ap
    });
  }

  const mediaRender = () => {
    let figure = null, audio = null, video = null;
    if(questionFigure) {
      figure = (<GridListTile cols={1}>
        <CardMedia className={classes.media} title={question}>
          <img src={questionFigure} alt={question} />
        </CardMedia>
      </GridListTile>);
    }
    if(questionAudio) {
      audio = (<GridListTile cols={1} className={classes.questionAudio}>
        <ReactPlayer url={questionAudio} loop={false} width="100%" height="50px" 
          config={{ 
            file: { 
              attributes: {
                onContextMenu: e => e.preventDefault(),
                controlsList: 'nodownload',
              } 
            } 
          }} 
          controls={questionId in audiosPlayed ? true : true}
          onStart={handleAudioStart}
        />
      </GridListTile>);
    }
    if(questionVideo) {
      video = (<GridListTile cols={1} className={classes.questionVideo}>
        <ReactPlayer url={questionVideo} controls={true} width="100%" height="100%" 
          config={{ 
            file: { 
              attributes: {
                onContextMenu: e => e.preventDefault(),
                controlsList: 'nodownload'
              } 
            } 
          }} />
      </GridListTile>);
    }
    return (
      <GridList spacing={15} cellHeight={"auto"} cols={questionType === 'multiquestion' ? 1 : props.getGridListCols2()}>
        {audio && audio}
        {video && video}
        {figure && figure}
      </GridList>
    );
  }
  return (
    <Card className={classes.card}>
      {mediaRender()}
      <CardContent className={classes.cardContent}>
        <Typography variant="body2" color="textPrimary" component="p" 
          className={classes.explanation}>
          {questionExplanation}
        </Typography>
        <Typography gutterBottom variant="h6" component="h6" className={classes.question}>
          {question}
        </Typography>
        {props.renderAnswer()}
      </CardContent>
    </Card>
  );
}

Question.propsTypes = {
  renderAnswer: PropTypes.func.isRequired,
  getGridListCols: PropTypes.func.isRequired,
  getGridListCols2: PropTypes.func.isRequired
}

export default Question;
