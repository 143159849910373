import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    loginOrInputNameAction: {
        justifyContent: "space-between"
    },
    getStarted: {
        justifyContent: "center"
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function QuizConfirmAlertDialogSlide(props) {

    const handleYes = () => {
        props.handleClose();
        props.handleFinish();
    };

    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want finish this test?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        After clicking the Finish button, 
                            your test will be ended and you will be able to see the result.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleYes} color="primary">
                        Yes
                    </Button>
                    <Button onClick={props.handleClose} color="secondary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

QuizConfirmAlertDialogSlide.propTypes = {
    open: PropTypes.bool.isRequired,
    handleFinish: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
};


export function PlayWithoutLoginDialogSlide(props) {

    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={true}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Click START to play the test. </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Testing time will be recorded as clicking the START button below.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.getStarted}>
                    <Button onClick={props.loadTheTest} color="primary" variant="contained">
                        START
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

PlayWithoutLoginDialogSlide.propTypes = {
    loadTheTest: PropTypes.func.isRequired
}


export function InitStudentDialogSlide(props) {

    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Please enter your registered name or just play your test with Google / Facebook either. </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Registered name is the name that Yola sent to you beside the code.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.loginOrInputNameAction}>
                    <Button onClick={props.initStudentLogin} color="primary">
                        Google / Facebook
                    </Button>
                    <Button onClick={props.initStudentRegisteredName} color="secondary" variant="contained">
                        Enter my registered name
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

InitStudentDialogSlide.propTypes = {
    initStudentLogin: PropTypes.func.isRequired,
    initStudentRegisteredName: PropTypes.func.isRequired
}


export function InitStudentRegisteredNameDialogSlide(props) {
    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <form onSubmit={props.handleSubmit}>
                    <DialogTitle id="alert-dialog-slide-title">Please enter your registered name</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            The registered name is your name that already registered at Yola.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="registered_name"
                            label="Your registered name"
                            type="text"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="secondary">
                            Play
                        </Button>
                        <Button onClick={props.handleClose} color="default">
                            Close
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

InitStudentRegisteredNameDialogSlide.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
}


export function ConfirmListeningAfterSpeakingDialogSlide(props) {

    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Are you sure your recording is listened clearly?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Please click the Play button to hear your recording. 
                        If you hear nothing then you should re-open the app and try to recoring again.
                    </DialogContentText>
                </DialogContent>
                <DialogActions justifycontent="space-between">
                    <Button onClick={props.handleYes} color="secondary">
                        Yes
                    </Button>
                    <Button onClick={props.handleClose} color="default" variant="contained">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmListeningAfterSpeakingDialogSlide.propTypes = {
    handleYes: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}