import React from 'react';
import PropTypes from 'prop-types';

function AnswerOption(props) {

  let optionsRender = (
    <li className="answerOption">
      <input
        type="radio"
        className="radioCustomButton"
        name={`radioGroup${props.questionId}`}
        checked={props.answerContent in props.studentAnswers}
        id={`radioGroup${props.questionId}${props.answerContent}`}
        value={props.answerContent}
        data-answercorrect={props.answerCorrect.toString()}
        onChange={props.onAnswerSelected}
      />
      <label className="radioCustomLabel" htmlFor={`radioGroup${props.questionId}${props.answerContent}`}>
        {props.answerContent}
      </label>
    </li>
  );

  if(props.questionType === "multichoice") {
    optionsRender = (
      <li className="answerOption">
        <input
          type="checkbox"
          className="checkboxCustomButton"
          name={`checkboxGroup${props.questionId}`}
          checked={props.answerContent in props.studentAnswers}
          id={`checkboxGroup${props.questionId}${props.answerContent}`}
          value={props.answerContent}
          data-answercorrect={props.answerCorrect.toString()}
          onChange={props.onAnswerSelected}
        />
        <label className="checkboxCustomLabel" htmlFor={`checkboxGroup${props.questionId}${props.answerContent}`}>
          {props.answerContent}
        </label>
      </li>
    );
  }

  return optionsRender;
}

AnswerOption.propTypes = {
  answerCorrect: PropTypes.bool.isRequired,
  answerContent: PropTypes.string.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
  studentAnswers: PropTypes.object.isRequired,
  questionType: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired,
};

export default AnswerOption;
