import http, {RequestHeaderBasic} from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = "/api";

export function postRegister(data) {
    const header = RequestHeaderBasic();
    return http.post(`${apiUrl}${apiEndpoint}/registry-user-test-online/`, 
        data, header);
}

export function postRegisterWithOTPCode(data) {
    const header = RequestHeaderBasic();
    return http.post(`${apiUrl}${apiEndpoint}/create-code-test-online/`, 
        data, header);
}