import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Button,
  Popover,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Stars from "@material-ui/icons/Stars";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.info.light,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  differenceUpIcon: {
    color: theme.palette.success.dark,
  },
  differenceUpValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1),
  },
  differenceDownIcon: {
    color: theme.palette.error.dark,
  },
  differenceDownValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
  differenceValue: {
    color: theme.palette.info.dark,
    marginRight: theme.spacing(1),
  },
  rating: {
    display: "flex",
    flexDirection: "column",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
    "& > .MuiRating-root": {
      fontSize: "2rem",
    },
  },
  pointWraper: {
    marginTop: theme.spacing(1),
  },
  total_score: {
    fontSize: "1.8rem",
    fontWeight: 900,
    letterSpacing: "0.2rem",
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    padding: "4px 16px",
    "&:hover": {
      color: theme.palette.info.light,
      borderColor: theme.palette.info.light,
    },
  },
  typography: {
    padding: theme.spacing(2),
    whiteSpace: "pre-line",
  },
}));

const Gamification = (props) => {
  const { className, currentEffort, previousEffort, courseProgram } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let compareToPreviousEffort = 0;
  if (Object.keys(previousEffort).length > 0) {
    compareToPreviousEffort = parseInt(
      ((currentEffort.total_score - previousEffort.total_score) /
        currentEffort.total_score) *
        100
    );
    if (compareToPreviousEffort === 0) {
      compareToPreviousEffort = "Remain unchanged";
    }
  }

  const showCompare = (compareToPreviousEffort) => {
    if (compareToPreviousEffort !== 0) {
      if (compareToPreviousEffort === "Remain unchanged") {
        return (
          <React.Fragment>
            <Typography className={classes.differenceValue} variant="body2">
              {compareToPreviousEffort}%
            </Typography>
            <Typography className={classes.caption} variant="caption">
              Compare to previous effort
            </Typography>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {compareToPreviousEffort < 0 && (
              <ArrowDownwardIcon className={classes.differenceDownIcon} />
            )}
            {compareToPreviousEffort > 0 && (
              <ArrowUpwardIcon className={classes.differenceUpIcon} />
            )}
            <Typography
              className={
                compareToPreviousEffort < 0
                  ? classes.differenceDownValue
                  : classes.differenceUpValue
              }
              variant="body2"
            >
              {compareToPreviousEffort}%
            </Typography>
            <Typography className={classes.caption} variant="caption">
              Compare to previous effort
            </Typography>
          </React.Fragment>
        );
      }
    }
    return null;
  };

  const effortExplain = () => {
    const totalScore =
      currentEffort.total_score > 0
        ? `${currentEffort.total_score} was calculated based on the following formula:`
        : ``;
    let midtermScore =
      currentEffort.appify_score.MT > 0
        ? `\n+ Midterm: ${currentEffort.appify_score.MT} = Midterm point / 100 * 10`
        : ``;
    let finalScore =
      currentEffort.appify_score.FT > 0
        ? `\n+ Final: ${currentEffort.appify_score.FT} = Final point / 100 * 30`
        : ``;
    if (courseProgram === "IELTS") {
      midtermScore =
        currentEffort.appify_score.MT > 0
          ? `\n+ Midterm: ${currentEffort.appify_score.MT} = Midterm point / 9 * 10`
          : ``;
      finalScore =
        currentEffort.appify_score.FT > 0
          ? `\n+ Final: ${currentEffort.appify_score.FT} = Final point / 9 * 30`
          : ``;
    } else if (courseProgram === "JUNIOR") {
      finalScore =
        currentEffort.appify_score.FT > 0
          ? `\n+ Final: ${currentEffort.appify_score.FT} = Final point / 100 * 40`
          : ``;
    }
    const hwGrade =
      currentEffort.others_score.homework_percentage_grade > 0
        ? `\n+ Homework grade: ${currentEffort.others_score.homework_percentage_grade} = Homework grade / 100 * 20`
        : ``;
    const hwComplete =
      currentEffort.others_score.homework_completion > 0
        ? `\n+ Homework completion: ${currentEffort.others_score.homework_completion} = Homework completion / 100 * 20`
        : ``;
    const rating =
      currentEffort.others_score.rating_stars > 0
        ? `\n+ Ratings: ${currentEffort.others_score.rating_stars} = Rating Stars / Total Stars * 10`
        : ``;
    const timespent =
      currentEffort.others_score.time_spent > 0
        ? `\n+ Timespent: ${currentEffort.others_score.time_spent} = HW timespent / 10 hours * 10`
        : ``;
    return (
      totalScore +
      midtermScore +
      finalScore +
      hwGrade +
      hwComplete +
      rating +
      timespent
    );
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              CURRENT EFFORT
            </Typography>
            <div className={classes.rating}>
              <Rating
                name="half-rating"
                defaultValue={0}
                precision={0.1}
                value={(currentEffort.total_score * 5) / 100}
              />
            </div>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <Stars className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        {false && (
          <Grid container justify="space-between">
            <Grid item xs={6} className={classes.pointWraper}>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                className={classes.total_score}
                onClick={handleClick}
              >
                {currentEffort.total_score || ""}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Typography className={classes.typography}>
                  {effortExplain()}
                </Typography>
              </Popover>
            </Grid>
            <Grid item xs={6} className={classes.difference}>
              {showCompare(compareToPreviousEffort)}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

Gamification.propTypes = {
  className: PropTypes.string,
  currentEffort: PropTypes.object,
  previousEffort: PropTypes.object,
  courseProgram: PropTypes.string,
};

export default Gamification;
