import http, { RequestHeader } from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = "/api";

export function getListScheduledTutor() {
  const header = RequestHeader();
  return http.get(
    `${apiUrl}${apiEndpoint}/yolameandkey/get_tutor_online_booked/azuread-b2c-oauth2/`,
    header
  );
}

export function postAvailableTutor(data) {
  const header = RequestHeader();
  return http.post(
    `${apiUrl}${apiEndpoint}/yolameandkey/get_tutor_online/azuread-b2c-oauth2/`,
    data,
    header
  );
}

export function postBookTutor(data) {
  const header = RequestHeader();
  return http.post(
    `${apiUrl}${apiEndpoint}/yolameandkey/book_tutor_online/azuread-b2c-oauth2/`,
    data,
    header
  );
}

export function deleteBookTutor(data) {
    const header = RequestHeader();
    return http.post(
      `${apiUrl}${apiEndpoint}/yolameandkey/cancel_tutor_online/azuread-b2c-oauth2/${data.booking_tutor_id}/`,
      data,
      header
    );
  }
  