import React from 'react';
import clsx from 'clsx';
import uuid from 'uuid/v1';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';

import { linkify } from '../../utils/helper';
import Note from '@material-ui/icons/Note';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0,
  },
  text: {
    "& span": {
      fontSize: '0.9rem'
    },
    alignSelf: 'flex-start',
    minWidth: "34px"
  },
  media: {
    height: "auto",
    "& img": {
      width: "100%",
      height: "auto"
    },
    marginTop: "12px"
  },
  icon: {
    color: theme.palette.info.main
  },
  body: {
    whiteSpace: "pre-line",
    wordWrap: "break-word"
  },
  date: {
    paddingBottom: 0,
    "& p": {
      lineHeight: "8px"
    }
  }
}));

const List5 = props => {
  const { className, notes } = props;

  const classes = useStyles();

  let data = [];
  for(const item of notes) {
    let noteData = {
      id: uuid(),
      body: item['body'],
      date: item['date']
    };
    data.push(noteData);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Notes"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {data.map((note, i) => (
            <React.Fragment key={note.id}>
              <ListItem className={classes.date}>
                <ListItemText secondary={note['date']} />
              </ListItem>
              <ListItem
                divider={i < data.length - 1}
              >
                <ListItemAvatar className={classes.text}>
                  <Note className={classes.icon} />
                </ListItemAvatar>
                <ListItemText className={classes.text}
                  primary={<span className={classes.body} dangerouslySetInnerHTML={{ __html: linkify(note['body']) }} />}
                />
              </ListItem>
            </React.Fragment>
            
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

List5.propTypes = {
  className: PropTypes.string,
  notes: PropTypes.array.isRequired
};

export default List5;
