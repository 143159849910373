import React, { useState, useEffect } from "react";

import { css } from "@emotion/core";
import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";
import moment from "moment";
import { SchedulerData, ViewTypes, DATE_FORMAT } from "react-big-scheduler";

import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

import palette from "../theme/palette";
import Auth from "../../Auth";
import {
  getListScheduledTutor,
  postAvailableTutor,
  postBookTutor,
  deleteBookTutor,
} from "../../services/keyMeService";
import SelectAvailableTime from "./SelectAvailableTime";
// import SelectTutorSchedule from "./SelectTutorSchedule";
import ListScheduledTutor from "./ListScheduledTutor";
import TutorScheduler from "./TutorScheduler";


const overrideSpinner = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "100vw",
    margin: "5rem auto 2rem auto",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    width: "95%",
  },
}));

const BookTutor = () => {
  const classes = useStyles();

  const stateObj = {
    username: null,
    name: null,
    checkedDay: [],
    bookStatus: 3,
    scheduledTutor: [],
    tutorSchedulerData: {},
    temporaryScheduledTutor: [],
    disableBooking: false
  };
  const [state, setState] = useState(stateObj);

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  useEffect(() => {
    const auth = new Auth();
    const currentUser = auth.currentUser();
    const username = currentUser ? currentUser.azureId : null;
    const name = currentUser ? currentUser.name : "";
    setState((s) => ({ ...s, username: username, name: name }));

    async function fetchData() {
      // turn on spinner
      setLoadingSpinner(true);

      // Get list scheduled tutor
      const { data } = await getListScheduledTutor();
      if (data.status_code !== 200) {
        toast.error(data.message);
      }

      // turn off spinner
      setLoadingSpinner(false);

      const listScheduledTutor = data.data;

      if (listScheduledTutor.length !== 0) {
        // sort scheduled data by date and time
        let scheduledTutor = listScheduledTutor.sort((a, b) => {
          const dateA = `${moment(a.date, "DD-MM-YYYY").format("YYYY-MM-DD")} ${
            a.start_time
          }:00`;
          const dateB = `${moment(b.date, "DD-MM-YYYY").format("YYYY-MM-DD")} ${
            b.start_time
          }:00`;
          return moment(dateB) - moment(dateA);
        });
        // add overtime attr to the scheduled list
        scheduledTutor = scheduledTutor.map((item) => {
          const endTime = moment(
            `${item.date} ${item.end_time}`,
            "YYYY-MM-DD hh:mm:ss"
          );
          let it = { ...item };
          it["overtime"] = false;
          if (endTime <= moment()) {
            it["overtime"] = true;
          }
          return it;
        });
        setState((s) => ({ ...s, scheduledTutor: scheduledTutor }));
      }
    }
    fetchData();
  }, []);

  const handleDayCheck = (event) => {
    let checkedDay = [...state.checkedDay];
    if (event.target.checked) {
      if (!checkedDay.includes(parseInt(event.target.name))) {
        checkedDay.push(parseInt(event.target.name));
      }
    } else {
      checkedDay = checkedDay.filter(
        (item) => item !== parseInt(event.target.name)
      );
    }
    setState({ ...state, checkedDay: checkedDay });
  };

  const handleArrowBack = (status) => {
    setState({ ...state, bookStatus: status });
  };

  const initTutorSchedulerData = () => {
    //Tutor Scheduler Data
    let schedulerData = new SchedulerData(
      new moment().format(DATE_FORMAT),
      ViewTypes.Week,
      false,
      false,
      {
        // startResizable: false,
        // endResizable: false,
        movable: false,
        creatable: false,
        schedulerWidth: "95%",
        resourceName: "Tutor",
        nonAgendaSlotMinHeight: 80,
        // eventItemLineHeight: 40,
        // eventItemHeight: 40,
        dayCellWidth: 64,
        views: [
          {viewName: 'Day', viewType: ViewTypes.Day},
          {viewName: 'Week', viewType: ViewTypes.Week}
        ]
      }
    );

    //set locale moment to the schedulerData, if your locale isn't English. By default, Scheduler comes with English(en, United States).
    moment.locale("vi-vn");
    schedulerData.setLocaleMoment(moment);

    //End Tutor Scheduler Data
    return schedulerData;
  };

  const changeSchedulerAvailableTime = async (
    schedulerData,
    temporaryScheduledTutor = null
  ) => {
    const availableTime = {
      from_date: schedulerData.startDate,
      to_date: schedulerData.endDate,
      week_days: state.checkedDay,
    };

    const { data } = await postAvailableTutor(availableTime);
    if (data.status_code === 200) {
      let resources = [],
        events = [];
      const colors = [
        palette.primary.main,
        palette.text.secondary,
        palette.info.main,
        palette.warning.main,
        palette.secondary.main,
      ];
      if (data.data.length > 0) {
        //set resources
        data.data.forEach((el) => {
          if (
            resources.filter((item) => item.id === el.tutor_id).length === 0
          ) {
            resources.push({
              id: el.tutor_id,
              name: `${el.tutor_name} - ${el.tutor_email}`,
            });
          }
        });
        resources = resources.map((item, index) => {
          return {
            id: item.id,
            name: item.name,
            color:
              typeof colors[index] !== "undefined" ? colors[index] : colors[0],
          };
        });
        schedulerData.setResources(resources);

        // set events
        data.data.forEach((el, index) => {
          const date = moment(el.date, "DD-MM-YYYY").format("YYYY-MM-DD");
          let bgColor = resources.filter((item) => item.id === el.tutor_id)[0]
            .color;
          // Check if this item in the temporary schedule tutor
          if (
            temporaryScheduledTutor !== null &&
            temporaryScheduledTutor.filter((item) => {
              return (
                item.scheduleId === el.id &&
                item.date === el.date &&
                item.startTime === el.start_time &&
                item.endTime === el.end_time
              );
            }).length > 0
          ) {
            bgColor = palette.success.main;
          }
          events.push({
            id: index,
            scheduleId: el.id,
            date: el.date,
            startTime: el.start_time,
            endTime: el.end_time,
            start: `${date} ${el.start_time}:00`,
            end: `${date} ${el.end_time}:00`,
            resourceId: el.tutor_id,
            title: `${el.start_time} - ${el.end_time}`,
            bgColor: bgColor,
          });
        });
      }
      schedulerData.setEvents(events);
    } else {
      toast.error(data.message);
    }
    return schedulerData;
  };

  const selectTutorSchedule = async () => {
    let schedulerData = initTutorSchedulerData();
    const tutorSchedulerData = await changeSchedulerAvailableTime(
      schedulerData,
      [...state.temporaryScheduledTutor]
    );
    setState({
      ...state,
      tutorSchedulerData: tutorSchedulerData,
      bookStatus: 2,
    });
  };

  // For Tutor Scheduler
  const onViewChange = async (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    const tutorSchedulerData = await changeSchedulerAvailableTime(
      schedulerData,
      [...state.temporaryScheduledTutor]
    );

    setState({
      ...state,
      tutorSchedulerData: tutorSchedulerData,
    });
  };

  const prevClick = async (schedulerData) => {
    schedulerData.prev();
    const tutorSchedulerData = await changeSchedulerAvailableTime(
      schedulerData,
      [...state.temporaryScheduledTutor]
    );
    setState({
      ...state,
      tutorSchedulerData: tutorSchedulerData,
    });
  };

  const nextClick = async (schedulerData) => {
    schedulerData.next();
    const tutorSchedulerData = await changeSchedulerAvailableTime(
      schedulerData,
      [...state.temporaryScheduledTutor]
    );
    setState({
      ...state,
      tutorSchedulerData: tutorSchedulerData,
    });
  };

  const onSelectDate = async (schedulerData, date) => {
    schedulerData.setDate(date);
    const tutorSchedulerData = await changeSchedulerAvailableTime(
      schedulerData,
      [...state.temporaryScheduledTutor]
    );
    setState({
      ...state,
      tutorSchedulerData: tutorSchedulerData,
    });
  };

  const eventClicked = (schedulerData, event) => {
    // alert(
    //   `You just clicked an event: {id: ${event.id}, title: ${event.title}}`
    // );
  };

  const ops1 = async (schedulerData, event) => {
    // Add to temporary scheduled tutor
    let temporaryScheduledTutor = [...state.temporaryScheduledTutor];
    if (
      temporaryScheduledTutor.filter((item) => {
        return (
          item.date === event.date &&
          item.startTime === event.startTime &&
          item.endTime === event.endTime
        );
      }).length === 0
    ) {
      temporaryScheduledTutor.push(event);
    } else {
      toast.info(`This time has already been in your booking!`);
    }

    const tutorSchedulerData = await changeSchedulerAvailableTime(
      schedulerData,
      temporaryScheduledTutor
    );

    setState((s) => ({
      ...s,
      tutorSchedulerData: tutorSchedulerData,
      temporaryScheduledTutor: temporaryScheduledTutor,
    }));
  };

  const ops2 = async (schedulerData, event) => {
    // Remove from temporary scheduled tutor
    let temporaryScheduledTutor = [...state.temporaryScheduledTutor];
    temporaryScheduledTutor = temporaryScheduledTutor.filter((item) => {
      return !(
        item.scheduleId === event.scheduleId &&
        item.date === event.date &&
        item.startTime === event.startTime &&
        item.endTime === event.endTime
      );
    });
    const tutorSchedulerData = await changeSchedulerAvailableTime(
      schedulerData,
      temporaryScheduledTutor
    );

    setState((s) => ({
      ...s,
      tutorSchedulerData: tutorSchedulerData,
      temporaryScheduledTutor: temporaryScheduledTutor,
    }));
  };

  const saveBooking = async () => {
    // Book tutor
    if(state.temporaryScheduledTutor.length > 0) {
      //disable booking button
      setState({...state, disableBooking: true});

      for (const item of state.temporaryScheduledTutor) {
        const selectedScheduledata = {
          tutor_id: item.scheduleId,
          date: item.date,
          start_time: item.startTime,
          end_time: item.endTime,
        };
        const { data } = await postBookTutor(selectedScheduledata);
        if (data.status_code === 200) {
          toast.success(`Booked tutor on ${item.date} ${item.startTime} - ${item.endTime} successfully!`);
        } else {
          toast.error(`Booked tutor on ${item.date} ${item.startTime} - ${item.endTime} failed! ${data.message}`);
        }
      }

      setTimeout(function(){
        window.location.href = "/key-me/book-tutor";
      }, 3000);
    } else {
      toast.info("Please select at least 1 booking.");
    }
  };

  const toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    setState({
      ...state,
      tutorSchedulerData: schedulerData,
    });
  };
  //End For Tutor Scheduler

  const removeSchedule = async (booking_tutor_id) => {
    const c = window.confirm("Are you sure you want to remove this schedule?");
    if (c === true) {
      const { data } = await deleteBookTutor({
        booking_tutor_id: booking_tutor_id,
      });
      if (data.status_code === 200) {
        toast.success("Your tutor schedule was removed successfully.");
        window.location.href = "/key-me/book-tutor";
      }
    }
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <HashLoader
          css={overrideSpinner}
          // size={150}
          color={blue[300]}
          loading={loadingSpinner}
        />
        {state.bookStatus === 1 && (
          <SelectAvailableTime
            stateObj={state}
            handleDayCheck={handleDayCheck}
            selectTutorSchedule={selectTutorSchedule}
            handleArrowBack={handleArrowBack}
          />
        )}
        {/* {state.bookStatus === 2 && (
          <SelectTutorSchedule
            handleArrowBack={handleArrowBack}
            availableTutor={state.availableTutor}
            bookSchedule={bookSchedule}
          />
        )} */}
        {state.bookStatus === 3 && (
          <ListScheduledTutor
            handleArrowBack={handleArrowBack}
            scheduledTutor={state.scheduledTutor}
            removeSchedule={removeSchedule}
          />
        )}
        {state.bookStatus === 2 && (
          <TutorScheduler
            handleArrowBack={handleArrowBack}
            scheduledTutor={state.scheduledTutor}
            temporaryScheduledTutor={state.temporaryScheduledTutor}
            removeSchedule={removeSchedule}
            tutorSchedulerData={state.tutorSchedulerData}
            onViewChange={onViewChange}
            prevClick={prevClick}
            nextClick={nextClick}
            onSelectDate={onSelectDate}
            eventClicked={eventClicked}
            ops1={ops1}
            ops2={ops2}
            toggleExpandFunc={toggleExpandFunc}
            saveBooking={saveBooking}
            disableBooking={state.disableBooking}
          />
        )}
      </div>
    </React.Fragment>
  );
};

BookTutor.propTypes = {};

export default BookTutor;
