import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DoneOutline from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Block from '@material-ui/icons/Block';
import Forward from '@material-ui/icons/Forward';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import TextareaAutoSize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import { green, red, blue, grey, purple } from '@material-ui/core/colors';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import RecordingVoice from './RecordingVoice';


const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: 20,
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
    nested2: {
        paddingLeft: theme.spacing(4)
    },
    iconDone: {
        color: green[500],
    },
    iconDoneDragDrop: {
        color: green[500],
        marginLeft: 10
    },
    iconFalse: {
        color: red[500],
    },
    iconFalseDragDrop: {
        color: red[500],
        marginLeft: 10
    },
    iconBlock: {
        color: grey[500],
        marginLeft: 10
    },
    iconForward: {
        color: blue[500]
    },
    questionDiv: {
        backgroundColor: grey[100]
    },
    answerTitle: {
        color: blue[700]
    },
    questionTitle: {
        color: blue[700]
    },
    questionScore: {
        color: purple[700]
    },
    questionOrder: {
        color: grey[700]
    },
    showPointCriteria: {
        "& span": {
            fontSize: "0.8rem",
        },
        color: purple[700]
    },
    showCommentCriteria: {
        "& span": {
            fontSize: "1rem",
        },
        color: purple[700]
    },
    card: {
        width: "100%",
        maxWidth: "100%",
        padding: 0,
    },
    cardDragDrop: {
        width: "100%",
        maxWidth: "100%",
        padding: 0,
        height: "100%",
        // minHeight: "180px",
        overflow: "hidden"
    },
    media: {
        height: "auto",
        "& img": {
            maxWidth: "100%",
            height: "auto"
        }
    },
    mediaDragDrop: {
        height: "auto",
        "& img": {
            width: "100%",
            height: "auto"
        }
    },
    question: {
        paddingTop: "10px",
        fontSize: "1rem"
    },
    teacherComment: {
        color: blue[700],
    },
    teacherVoiceComment: {
        color: purple[700]
    },
    gradingStatus: {
        "& span": {
            color: red[700],
            fontSize: "14px"
        }
    },
    dragdropAnswerText: {
        fontSize: "16px"
    },
    dragdropNoAnswer: {
        color: grey[500]
    },
    textAnswerDragdrop: {
        fontSize: "14px",
    },
    fillplaceAnswer: {
        color: blue[700],
    },
    questionAudio: {
        "& div": {
            alignItems: "center",
            display: "flex",
            padding: theme.spacing(1)
        }
    },
    questionVideo: {
        "& div": {
            padding: theme.spacing(1)
        }
    },
    textarea: {
        width: "100%"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
    },
    editPoint: {
        color: purple[700],
        width: "30%",
        minWidth: "75px"
    },
    editCommentTitle: {
        color: purple[700],
        width: "30%",
        minWidth: "75px"
    },
    maxPoint: {
        width: "60%",
        "& span": {
            fontSize: "0.7rem",
            color: grey[700]
        }
    },
    gradingCriteria: {
        width: "30%",
        "& span": {
            fontWeight: "bold",
            fontSize: "0.9rem"
        }
    },
    gradingCriteriaAsset: {
        width: "70%"
    },
    multichoiceImageCheck: {
        color: blue[500]
    },
    multichoiceImageCheckDone: {
        color: green[500] + " !important"
    },
    multichoiceImageCheckFailed: {
        color: red[500],
    },
    multichoiceImageImg: {
        width: "100%",
        maxWidth: "200px",
        height: "auto",
    },
    multichoiceImageLabel: {
        fontSize: "1.5rem",
        marginLeft: "-200px",
        width: "200px",
        position: "absolute",
        color: "#fff"
    },
    multichoiceImageWrapper: {
        textAlign: "center"
    },
    explanation: {
        margin: 0,
        whiteSpace: "pre-line",
        paddingTop: theme.spacing(2),
        color: theme.palette.text.primary
    },
    multiQuestionsSplit: {
        height: "85vh",
        overflowY: "auto",
        overflowX: "hidden",
        padding: theme.spacing(1),
        alignSelf: "flex-start"
    },
    questionName: {
        marginTop: 0,
        marginBottom: 4
    },
    childQuestionWrapper: {
        paddingBottom: theme.spacing(2)
    },
    teacherCommentParagraph: {
        paddingLeft: theme.spacing(8),
        whiteSpace: "pre-line"
    },
    studentAnswerEssay: {
        whiteSpace: "pre-line"
    },
    fillplaceWrapper: {
        paddingLeft: theme.spacing(8),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        whiteSpace: "pre-line"
    }
}));

function ResultDetailAnswer(props) {
    const classes = useStyles();

    const { open, handleClick, questionKey, pathGrading } = props;
    const [audioAnswerLinkError, setAudioAnswerLinkError] = useState([]);

    const _setAnswerIconStatus = (questionObj, answer) => {
        let checkAnswerIcon = '';
        for(var item of questionObj.userAnswer) {
            if(item.content === answer.content) {
                if(item.correct === true) {
                    checkAnswerIcon = <DoneOutline className={classes.iconDone} />;
                    break;
                } else if(item.correct === false) {
                    checkAnswerIcon = <HighlightOff className={classes.iconFalse} />;
                    break;
                }
            }
        }
        if(checkAnswerIcon === '') {
            if(answer.correct === true) {
                checkAnswerIcon = <Forward className={classes.iconForward} />;
            } else {
                checkAnswerIcon = <CheckBoxOutlineBlank />;
            }
        }
        return checkAnswerIcon;
    }

    const _setDragdropOrderAnswerIconStatus = (questionObj, answer) => {
        let checkAnswerIcon = <CheckBoxOutlineBlank />;
        for(var item of questionObj.userAnswer) {
            if(item.id === answer.id) {
                if(parseInt(answer.position) === parseInt(item.answer)) {
                    checkAnswerIcon = <DoneOutline className={classes.iconDone} />;
                    break;
                } else {
                    checkAnswerIcon = <HighlightOff className={classes.iconFalse} />;
                    break;
                }
            }
        }
        return checkAnswerIcon;
    }

    const _mediaRender = (questionObj, kind=null) => {
        let figure = null, audio = null, video = null;
        if(questionObj.figure) {
            figure = (<GridListTile cols={1}>
            <CardMedia className={classes.media} title={questionObj.question}>
                <img src={questionObj.figure} alt={questionObj.question} />
            </CardMedia>
            </GridListTile>);
        }
        if(questionObj.audio) {
            audio = (<GridListTile cols={1} className={classes.questionAudio}>
                <ReactPlayer url={questionObj.audio} controls={true} width="100%" height="50px" 
                    config={{ 
                        file: { 
                          attributes: {
                            onContextMenu: e => e.preventDefault(),
                            controlsList: 'nodownload'
                          } 
                        } 
                    }} />
            </GridListTile>);
        }
        if(questionObj.video) {
            video = (<GridListTile cols={1} className={classes.questionVideo}>
            <ReactPlayer url={questionObj.video} controls={true} width="100%" height="100%" 
                config={{ 
                    file: { 
                      attributes: {
                        onContextMenu: e => e.preventDefault(),
                        controlsList: 'nodownload'
                      } 
                    } 
                }} />
            </GridListTile>);
        }
        return (
            <GridList spacing={15} cellHeight={"auto"} cols={kind === 'is-child-question' ? 1 : props.getGridListCols2()}>
            {audio && audio}
            {video && video}
            {figure && figure}
            </GridList>
        );
    }

    const _showQuestionInformation = (questionObj, kind=null) => {
        const mr = _mediaRender(questionObj, kind);
        return (
            <Card className={classes.card}>
                {mr}
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.explanation}>
                        {questionObj.explanation}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2" className={classes.question}>
                        {questionObj.question}
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    const _showDragDropAnswers = (questionObj, item) => {
        const mediaRender = item.figure && (
            <CardMedia className={classes.mediaDragDrop} title={questionObj.question}>
                <img src={item.figure} alt={questionObj.question} />
            </CardMedia>
        );
        const textRender = item.text && (
            <Typography component="p" className={classes.textAnswerDragdrop}>
                {item.text}
            </Typography>
        );
        const arrStudentAnswers = questionObj.userAnswer.filter(el => el.id === item.id);
        const answerObj = arrStudentAnswers.length > 0 ? arrStudentAnswers[0] : null;
        let studentAnswer = <Typography component="p" className={classes.dragdropNoAnswer}>No answer</Typography>;
        
        if(questionObj.question_type === 'dragdropsingle') {
            let iconAnswerStatus = null;
            if(answerObj) {
                iconAnswerStatus = <HighlightOff className={classes.iconFalseDragDrop} />;
                if(item.is_correct === true) {
                    iconAnswerStatus = <DoneOutline className={classes.iconDoneDragDrop} />;
                }
            }
            studentAnswer = <Typography component="p" className={classes.dragdropAnswerText}>{iconAnswerStatus}</Typography>;
        } else {
            if(answerObj) {
                let iconAnswerStatus = <HighlightOff className={classes.iconFalseDragDrop} />;
                if(answerObj.answer === item.answer) {
                    iconAnswerStatus = <DoneOutline className={classes.iconDoneDragDrop} />;
                }
                studentAnswer = <Typography component="p" className={classes.dragdropAnswerText}>{answerObj.answer} {iconAnswerStatus}</Typography>;
            }
        }
        
        return (
            <Card className={classes.cardDragDrop}>
                {mediaRender}
                <CardContent>
                    {textRender}
                    {studentAnswer}
                </CardContent>
            </Card>
        );
    }

    const _showGrading = (questionObj, kind=null, qParentId=null) => {
        if(pathGrading === 'grading') {
            let audioObj = {};
            if(kind !== 'is-child-question') {
                if(questionObj.teacherVoiceComment) {
                    if(questionObj.teacherVoiceComment['blobURL']) {
                        audioObj = questionObj.teacherVoiceComment;
                    } else {
                        audioObj = {blobURL: questionObj.teacherVoiceComment};
                    }
                }
            }
            let renderGradingDefault = (
                <React.Fragment>
                    <ListItem>
                        <ListItemText primary="Grade:" className={classes.editPoint} />
                        <TextField value={questionObj.userScore} className={classes.textField} label="Grade here" margin="normal" 
                            id={`${qParentId}-${kind}-grade_${questionObj.question_id}`}
                            onChange={props.editGrade} type="number" />
                        <ListItemText primary={`Max point: ${questionObj.questionScore}`} className={classes.maxPoint} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Edit comment:" className={classes.editCommentTitle} />
                        <TextareaAutoSize rows={5} placeholder="Edit comment here..." 
                            className={classes.textarea} id={`${qParentId}-${kind}-comment_${questionObj.question_id}`} 
                            defaultValue={questionObj.teacherComment}
                            onBlur={props.editComment} />
                    </ListItem>
                </React.Fragment>
            );
            if(questionObj.criteria.length > 0) {
                renderGradingDefault = (
                    <React.Fragment>
                        {questionObj.criteria.map((c, index) => (
                            <ListItem key={index} className={classes.nested}>
                                <ListItemText primary={c.criteria_name} className={classes.gradingCriteria} />
                                <ul className={classes.gradingCriteriaAsset}>
                                    <ListItem>
                                        <ListItemText primary="Grade:" className={classes.editPoint} />
                                        <TextField value={c.criteria_point} className={classes.textField} 
                                            label="Grade here" margin="normal" id={`${qParentId}-${kind}-grade_${questionObj.question_id}_${c.criteria_id}`}
                                            onChange={props.editGrade} type="number" />
                                        <ListItemText primary={`Max point: ${c.criteria_max_point}`} className={classes.maxPoint} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Edit comment:" className={classes.editCommentTitle} />
                                        <TextareaAutoSize rows={5} placeholder="Edit comment here..." 
                                            className={classes.textarea} id={`${qParentId}-${kind}-comment_${questionObj.question_id}_${c.criteria_id}`} 
                                            defaultValue={c.criteria_comment}
                                            onBlur={props.editComment} />
                                    </ListItem>
                                </ul>  
                            </ListItem>
                        ))}
                    </React.Fragment>
                );
            }
            return (
                <React.Fragment>
                    {renderGradingDefault}
                    
                    {kind !== 'is-child-question' && 
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Voice comment:" className={classes.voiceCommentTitle} />
                            <RecordingVoice 
                                onSave={props.editVoiceComment} 
                                audioObj={audioObj || {}} 
                                quesId={questionObj.question_id} 
                                setVoiceQuestionCurr={props.setVoiceQuestionCurr} />
                        </ListItem>
                    }
                </React.Fragment>
            );
        }
    }

    const _showMultichoiceImageAnswers = (questionObj, item, key) => {
        let checked = false;
        const userAnswer = questionObj.userAnswer.filter(x => x.content.toString() === item.answer_id.toString());
        let checkboxAnswer = <Checkbox className={classes.multichoiceImageCheck} />;
        if(userAnswer.length > 0) {
            checked = true;
            checkboxAnswer = <Checkbox className={classes.multichoiceImageCheckFailed} />;
            if(userAnswer[0].correct === true) {
                checkboxAnswer = <Checkbox className={classes.multichoiceImageCheckDone} />
            }
        }
        return (
            <GridListTile key={key} cols={1} className={classes.multichoiceImageWrapper}>
                <FormControlLabel
                    control={
                        checkboxAnswer
                    }
                    label={
                        <React.Fragment>
                            <img src={item.figure} key={item.answer_id} className={classes.multichoiceImageImg} alt={item.content} />
                            <span className={classes.multichoiceImageLabel}>{item.content}</span>
                        </React.Fragment>
                    }
                    checked={checked}
                />
            </GridListTile>
        );
    }

    const showAudioAnswerLink = (questionId) => {
        setAudioAnswerLinkError([
            ...audioAnswerLinkError,
            questionId
        ]);
    }

    const showQuestion = (questionObj, kind=null, qParentId=null) => {
        switch(questionObj.question_type) {
            case 'truefalse':
            case 'multichoice':
            case 'singlechoice':
                return (
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Question" className={classes.questionTitle} />
                            {_showPoint(questionObj)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            {_showQuestionInformation(questionObj, kind)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Answers" className={classes.answerTitle} />
                        </ListItem>

                        {questionObj.answers.map((answer, key) => (
                            <ListItem key={key} className={classes.nested2}>
                                <ListItemIcon>{_setAnswerIconStatus(questionObj, answer)}</ListItemIcon>
                                <ListItemText primary={answer.content} />
                            </ListItem>
                        ))}
                    </List>
                );
            case 'essay':
                const essayAnswerContent = (questionObj.userAnswer && questionObj.userAnswer[0]) 
                        ? questionObj.userAnswer[0].content : "";
                return (
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Question" className={classes.questionTitle} />
                            {_showPoint(questionObj)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            {_showQuestionInformation(questionObj, kind)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <ListItemText primary={`Answers (${essayAnswerContent ? essayAnswerContent.split(' ').filter(w => w !== '').length : 0} word(s))`} className={classes.answerTitle} />
                        </ListItem>
                        {essayAnswerContent && 
                            <ListItem className={classes.nested2}>
                                <ListItemText primary={essayAnswerContent} className={classes.studentAnswerEssay} />
                            </ListItem>
                        }
                        {_showTeacherComments(questionObj)}
                        {((questionObj.teacherVoiceComment && questionObj.teacherVoiceComment['blobURL']) 
                            || questionObj.teacherVoiceComment) && (
                            <React.Fragment>
                                <ListItem className={classes.nested}>
                                    <ListItemText primary="Teacher's voice comment" className={classes.teacherVoiceComment} />
                                </ListItem>
                                <ListItem className={classes.nested2}>
                                    <ReactPlayer url={questionObj.teacherVoiceComment['blobURL'] || questionObj.teacherVoiceComment} controls={true} width="100%" height="50px" />
                                </ListItem>
                            </React.Fragment>
                        )}
                        {_showGrading(questionObj, kind, qParentId)}
                    </List>
                );
            case 'dragdrop':
            case 'dragdropsingle':
                return (
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Question" className={classes.questionTitle} />
                            {_showPoint(questionObj)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            {_showQuestionInformation(questionObj, kind)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Answers" className={classes.answerTitle} />
                        </ListItem>

                        <ListItem className={classes.nested2}>
                            <GridList spacing={15} cellHeight={"auto"} cols={props.getGridListCols()}>
                                {questionObj.answers.map((item, key) => (
                                    <GridListTile key={key} cols={1}>
                                        {_showDragDropAnswers(questionObj, item)}
                                    </GridListTile>
                                ))}
                            </GridList>
                        </ListItem>
                    </List>
                );
            case 'dragdroporder':
                return (
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Question" className={classes.questionTitle} />
                            {_showPoint(questionObj)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            {_showQuestionInformation(questionObj, kind)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Answers" className={classes.answerTitle} />
                        </ListItem>

                        {questionObj.answers.map((answer, key) => (
                            <ListItem key={key} className={classes.nested2}>
                                <ListItemIcon>{_setDragdropOrderAnswerIconStatus(questionObj, answer)}</ListItemIcon>
                                <ListItemText primary={answer.text} />
                            </ListItem>
                        ))}
                    </List>
                );
            case 'fillplace':
                const _showFillPlaceAnswer = (item) => {
                    const arrContent = item.content.split('[...]');
                    const objUserAnser = questionObj.userAnswer.filter(o => o.id === item.id);
                    const answer = (objUserAnser.length > 0) ? objUserAnser[0]['answer'] : '';
                    
                    let iconAnswerStatus = <Block className={classes.iconBlock} title="No answer" />;
                    if(answer !== '') {
                        let correctedAnswer = answer.trim().toUpperCase();
                        let acceptableAnswers = item.answer.split(/\[|\]+/).filter(e => e.trim() !== '/');
                        if(acceptableAnswers.length === 1) {
                            acceptableAnswers = acceptableAnswers[0].split('/');
                        } else {
                            // math case
                            correctedAnswer = correctedAnswer.replace(/\s/g, '');
                            acceptableAnswers = acceptableAnswers.map(e => {
                                return e.replace(/\s/g, '');
                            });
                        }
                        iconAnswerStatus = <HighlightOff className={classes.iconFalse} title="Not correct!" />;
                        acceptableAnswers.forEach(element => {
                            if(element.trim() !== '' && correctedAnswer === element.trim().toUpperCase()) {
                                iconAnswerStatus = <DoneOutline className={classes.iconDone} title="Correct!" />;
                            }
                        });
                    }
                    const answerStatus = (
                        <React.Fragment>
                            {answer}
                            {iconAnswerStatus}
                        </React.Fragment>
                    );
                    
                    return (
                        <div key={item.id} className={classes.fillplaceWrapper}>
                            <span>{arrContent[0] && arrContent[0]}</span> 
                            <span className={classes.fillplaceAnswer}>{answerStatus}</span>
                            <span>{arrContent[1] && arrContent[1]}</span>
                        </div>
                    );
                }
                return (
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Question" className={classes.questionTitle} />
                            {_showPoint(questionObj)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            {_showQuestionInformation(questionObj, kind)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Answers" className={classes.answerTitle} />
                        </ListItem>

                        {questionObj.answers.map((item) => _showFillPlaceAnswer(item))}
                    </List>
                );
            case 'fillplaceparagraph':
                const _showFillPlaceParagraphAnswer = (item, index) => {
                    const arrContent = questionObj.paragraph.split('[...]');
                    const objUserAnser = questionObj.userAnswer.filter(o => o.id === item.id);
                    const answer = (objUserAnser.length > 0) ? objUserAnser[0]['answer'] : '';
                    
                    let iconAnswerStatus = <Block className={classes.iconBlock} title="No answer" />;
                    if(answer !== '') {
                        let correctedAnswer = answer.trim().toUpperCase();
                        let acceptableAnswers = item.text.split(/\[|\]+/).filter(e => e.trim() !== '/');
                        if(acceptableAnswers.length === 1) {
                            acceptableAnswers = acceptableAnswers[0].split('/');
                        } else {
                            // math case
                            correctedAnswer = correctedAnswer.replace(/\s/g, '');
                            acceptableAnswers = acceptableAnswers.map(e => {
                                return e.replace(/\s/g, '');
                            });
                        }

                        iconAnswerStatus = <HighlightOff className={classes.iconFalse} title="Not correct!" />;
                        acceptableAnswers.forEach(element => {
                            if(element.trim() !== '' && correctedAnswer === element.trim().toUpperCase()) {
                                iconAnswerStatus = <DoneOutline className={classes.iconDone} title="Correct!" />;
                            }
                        });
                    }
                    const answerStatus = (
                        <React.Fragment>
                            {answer}
                            {iconAnswerStatus}
                        </React.Fragment>
                    );
                    
                    return (
                        <React.Fragment key={item.id}>
                            <span>{arrContent[index] || ""}</span> 
                            <span className={classes.fillplaceAnswer}>{answerStatus}</span>
                            {index === (arrContent.length - 2) && 
                                <span>{arrContent[index + 1] || ""}</span> 
                            }
                        </React.Fragment>
                    );
                }
                return (
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Question" className={classes.questionTitle} />
                            {_showPoint(questionObj)}
                        </ListItem> 
                        <ListItem className={classes.nested}>
                            {_showQuestionInformation(questionObj, kind)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Answers" className={classes.answerTitle} />
                        </ListItem>
                        <div className={classes.fillplaceWrapper}>
                            {questionObj.answers.map((item, index) => _showFillPlaceParagraphAnswer(item, index))}
                        </div>
                    </List>
                );
            case 'recording':
                return (
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Question" className={classes.questionTitle} />
                            {_showPoint(questionObj)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            {_showQuestionInformation(questionObj, kind)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Answers" className={classes.answerTitle} />
                        </ListItem>
                        {questionObj.userAnswer[0] && 
                            <React.Fragment>
                                <ListItem className={classes.nested2}>
                                <ReactPlayer url={questionObj.userAnswer[0].content} controls={true} width="100%" height="50px"
                                    onError={() => showAudioAnswerLink(questionObj["question_id"])} />
                                </ListItem>
                                {audioAnswerLinkError.includes(questionObj["question_id"]) && 
                                    <ListItem>
                                            <a href={questionObj.userAnswer[0].content} target="_blank" rel="noopener noreferrer">
                                                {questionObj.userAnswer[0].content}
                                            </a>   
                                    </ListItem>
                                }
                            </React.Fragment>
                        }
                        {_showTeacherComments(questionObj)}
                        {questionObj.teacherComment && (
                            <React.Fragment>
                                <ListItem className={classes.nested}>
                                    <ListItemText primary="Teacher's comment" className={classes.teacherComment} />
                                </ListItem>
                                <ListItemText primary={questionObj.teacherComment} className={classes.teacherCommentParagraph} />
                            </React.Fragment>
                        )}
                        {((questionObj.teacherVoiceComment && questionObj.teacherVoiceComment['blobURL']) 
                            || questionObj.teacherVoiceComment) && (
                            <React.Fragment>
                                <ListItem className={classes.nested}>
                                    <ListItemText primary="Teacher's voice comment" className={classes.teacherVoiceComment} />
                                </ListItem>
                                <ListItem className={classes.nested2}>
                                    <ReactPlayer url={questionObj.teacherVoiceComment['blobURL'] || questionObj.teacherVoiceComment} controls={true} width="100%" height="50px" />
                                </ListItem>
                            </React.Fragment>
                        )}
                        {_showGrading(questionObj, kind, qParentId)}
                    </List>
                );
            case 'multichoice_image':
                return (
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Question" className={classes.questionTitle} />
                            {_showPoint(questionObj)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            {_showQuestionInformation(questionObj, kind)}
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Answers" className={classes.answerTitle} />
                        </ListItem>

                        <ListItem className={classes.nested2}>
                            <GridList spacing={15} cellHeight={"auto"} cols={props.getGridListCols2()}>
                                {questionObj.answers.map((item, key) => _showMultichoiceImageAnswers(questionObj, item, key))}
                            </GridList>
                        </ListItem>
                    </List>
                );
            case 'guide':
                return (
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <ListItemText primary="Guide" className={classes.questionTitle} />
                        </ListItem>
                        <ListItem className={classes.nested}>
                            {_showQuestionInformation(questionObj, kind)}
                        </ListItem>
                    </List>
                );
            case 'multiquestion':
                let multiQuestions = [];
                questionObj.sub_questions.forEach((childQuestion) => {
                    multiQuestions.push(showQuestion(childQuestion, 'is-child-question', questionObj.question_id));
                });
                return (
                    <Grid container alignItems="center">
                        <Grid item xs={6} className={classes.multiQuestionsSplit}>
                            <h3 className={classes.questionName}>{questionObj.question}</h3>
                            {_mediaRender(questionObj, 'is-child-question')}
                            <p className={classes.explanation}>{questionObj.explanation}</p>
                        </Grid>
                        <Grid item xs={6} className={classes.multiQuestionsSplit}>
                            {multiQuestions.map((item, index) => (
                                <div key={index} className={classes.childQuestionWrapper} >{item}</div>
                            ))}
                        </Grid>
                    </Grid>
                );
            default:
                return '';
        }
    }

    let gradingStatus = '';
    if((props.propQuestion.question_type === 'essay' || props.propQuestion.question_type === 'recording') 
        && props.propQuestion.userAnswer[0] && props.propQuestion.userScore === 0) {
        gradingStatus = '(Waiting if not graded)';
    }

    const _showPoint = (questionObj) => {
        let showPoint = null;
        if(questionObj['question_type'] !== "multiquestion") {
            showPoint = <ListItemText primary={`${questionObj.userScore} point(s)`} className={classes.questionScore} />;
            if(questionObj['criteria'] != null && questionObj['criteria'].length > 0) {
                showPoint = questionObj['criteria'].map((c, index) => {
                    const sp = <ListItemText key={index} primary={`${c['criteria_name']}: ${c['criteria_point']}`} className={classes.showPointCriteria} />;
                    return sp;
                });
            }
        }
        return showPoint;
    }

    const _showTeacherComments = (questionObj) => {
        let showComments = null;
        if(questionObj.teacherComment) {
            showComments = (
                <React.Fragment>
                    <ListItem className={classes.nested}>
                        <ListItemText primary="Teacher's comment" className={classes.teacherComment} />
                    </ListItem>
                    <ListItemText primary={questionObj.teacherComment} className={classes.teacherCommentParagraph} />
                </React.Fragment>
            );
        } else {
            if(questionObj['criteria'] != null && questionObj['criteria'].length > 0) {
                showComments = questionObj['criteria'].map((c, index) => {
                    if(c['criteria_comment']) {
                        return (
                            <ListItem key={index} className={classes.nested}>
                                <ListItemText key={index} primary={`${c['criteria_name']}: ${c['criteria_comment']}`} className={classes.showCommentCriteria} />
                            </ListItem>
                        );
                    } else {
                        return null;
                    }
                });
            }
        }
        
        return showComments;
    }

    return (
        <div className={classes.root}>
            <ListItem button onClick={() => handleClick(questionKey)} className={classes.questionDiv}>
                <ListItemText primary={`Question ${questionKey+1}`} className={classes.questionOrder} />
                <ListItemText primary={gradingStatus} className={classes.gradingStatus} />
                {open[questionKey] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open[questionKey]} timeout="auto" unmountOnExit>
                {showQuestion(props.propQuestion)}
            </Collapse>
        </div>
    );
}

ResultDetailAnswer.propTypes = {
    propQuestion: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
    open: PropTypes.object.isRequired,
    questionKey: PropTypes.number.isRequired,
    getGridListCols: PropTypes.func.isRequired,
    getGridListCols2: PropTypes.func.isRequired,
    pathGrading: PropTypes.string.isRequired,
    editGrade: PropTypes.func.isRequired,
    editComment: PropTypes.func.isRequired,
    editVoiceComment: PropTypes.func.isRequired,
    setVoiceQuestionCurr: PropTypes.func.isRequired,
}

export default ResultDetailAnswer;