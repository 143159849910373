import React from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
  },
  bookTutorWraper: {
    display: "flex",
    justifyContent: "center",
    margin: "2rem auto",
  },
  listWraper: {
    margin: "1rem auto",
  },
  alarmIcon: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid",
  },
  alarmIconOverTime: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid",
  },
  link: {
    cursor: "pointer",
  },
}));

const ListScheduledTutor = (props) => {
  const classes = useStyles();

  const columns = [
    {
      name: "Status",
      cell: (row) => (
        <Avatar
          className={
            row.overtime ? classes.alarmIconOverTime : classes.alarmIcon
          }
        >
          <AlarmOnIcon />
        </Avatar>
      ),
    },
    {
      name: "Tutor",
      selector: "tutor_name",
      sortable: true,
      cell: (row) => `${row.tutor_name} - ${row.tutor_email}`,
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
    },
    {
      name: "Start time",
      selector: "start_time",
    },
    {
      name: "End time",
      selector: "end_time",
    },
    {
      name: "Link meeting",
      cell: (row) => {
        return row.tutor_url && (<Link href={row.tutor_url} target="_blank" className={classes.link}>
          Go to meeting
        </Link>)
      }
    },
    {
      name: "Cancel scheduler",
      cell: (row) => {
        return !row.overtime ? (
          <IconButton
            edge="end"
            aria-label="remove"
            onClick={() => props.removeSchedule(row.booking_tutor_id)}
          >
            <DeleteIcon />
          </IconButton>
        ) : null;
      },
    },
  ];

  return (
    <div>
      <div className={classes.bookTutorWraper}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => props.handleArrowBack(1)}
        >
          Book tutor
        </Button>
      </div>
      <h2 className={classes.title}>SELECTED TUTOR SCHEDULE</h2>
      <Grid container justify="center" className={classes.listWraper}>
        <Grid item xs={12} md={9}>
          <DataTable
            title="List of scheduled tutor booking"
            columns={columns}
            data={props.scheduledTutor}
            pagination={true}
            fixedHeader={true}
            striped={true}
          />
        </Grid>
      </Grid>
    </div>
  );
};

ListScheduledTutor.propTypes = {
  handleArrowBack: PropTypes.func.isRequired,
  scheduledTutor: PropTypes.array,
  removeSchedule: PropTypes.func.isRequired,
};

export default ListScheduledTutor;
