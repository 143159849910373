import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import Home from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ContactSupport from '@material-ui/icons/ContactSupport';
import { UseStateValue } from '../state';

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function DrawerMenu() {
  const classes = useStyles();
  const history = useHistory();

  const [, dispatch] = UseStateValue();

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const resetTestCode = () => {
    dispatch({
      type: 'loadData',
      testCode: null,
      quizQuestions: []
    });
  }

  const backHome = () => {
    resetTestCode();
    history.push('/');
  }

  const backToTestHome = () => {
    resetTestCode();
    history.push('/test/');
  }

  const goToProfile = () => {
    resetTestCode();
    window.location.href = '/profile/dashboard';
  }

  // const goToChat = () => {
  //   resetTestCode();
  //   history.push(`/chat`);
  // }

  const goToYolaKeyMeBookTutor = () => {
    resetTestCode();
    window.location.href = '/key-me/book-tutor';
  }

  const fullList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListItem button onClick={backHome}>
            <ListItemIcon><Home /></ListItemIcon>
            <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={backToTestHome}>
            <ListItemIcon><PlayCircleOutline /></ListItemIcon>
            <ListItemText primary="Your test" />
        </ListItem>
        <ListItem button onClick={goToProfile}>
            <ListItemIcon><AccountCircle /></ListItemIcon>
            <ListItemText primary="Profile" />
        </ListItem>
        {false && <ListItem button onClick={goToYolaKeyMeBookTutor}>
            <ListItemIcon><ContactSupport /></ListItemIcon>
            <ListItemText primary="Book tutor" />
        </ListItem>}
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <IconButton edge="start" className={classes.menuButton} color="inherit" 
        aria-label="menu" onClick={toggleDrawer('left', true)}>
        <MenuIcon />
      </IconButton>
      <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
        {fullList('left')}
      </Drawer>
    </React.Fragment>
  );
}
