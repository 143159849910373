import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    color: theme.palette.info.light,
  },
  img: {
    maxWidth: "200px",
    width: "100%",
    height: "auto",
  },
}));

function SpeechRecognitionQuestion(props) {
  const classes = useStyles();

  const { transcript, resetTranscript } = useSpeechRecognition();

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    console.log("SpeechRecognition not supported");
    return null;
  }

  const _showAnswerOptions = (item, index) => {
    return (
      <Grid item lg={3} sm={6} xl={4} xs={12} key={index}>
        <Card>
          <CardContent>
            <img src={item.figure} className={classes.img} alt={item.content} />
            <Typography
              className={classes.content}
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {item.content}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <div>
      <Grid container spacing={4}>
        {props.answerOptions.map((item, index) =>
          _showAnswerOptions(item, index)
        )}
      </Grid>

      <button onClick={SpeechRecognition.startListening}>Start</button>
      <button onClick={SpeechRecognition.stopListening}>Stop</button>
      <button onClick={resetTranscript}>Reset</button>
      <p>{transcript}</p>
    </div>
  );
}

SpeechRecognitionQuestion.propTypes = {
  answerOptions: PropTypes.array.isRequired,
};

export default SpeechRecognitionQuestion;
