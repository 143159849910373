import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import b2cauth from 'react-azure-adb2c';
import authentication from '@kdpw/msal-b2c-react';
import { ThemeProvider } from '@material-ui/styles';
import 'react-toastify/dist/ReactToastify.css';
import theme from './components/theme';
import './App.css';
import Header from './components/Header';
import StickyFooter from './components/StickyFooter';
import HomePage from './components/HomePage';
import Quiz from './components/quiz/Quiz';
import ResultDetail from './components/quiz/ResultDetail';
import NotFound from './components/notFound';
import ErrorPage from './components/errorPage';
import Main, { InitStudent, PlayWithoutLogin } from './components/quiz/Main';
import RecordingAudio from './components/recording/RecordingAudio';
import Dashboard from './components/profile/Dashboard';
// import Chatbox from './components/chatbox/Chatbox';
import BookTutor from './components/key-me/BookTutor';
import Register from './components/mkt/Register';


// b2cauth.initialize({
//   instance: 'https://yolaedx.b2clogin.com/', 
//   tenant: 'yolaedx.onmicrosoft.com',
//   signInPolicy: 'B2C_1_YolaLearning',
//   applicationId: process.env.REACT_APP_AZURE_APP_ID,
//   cacheLocation: 'sessionStorage', //localStorage
//   scopes: ['https://yolaedx.onmicrosoft.com/api/app'],
//   redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URL,
//   postLogoutRedirectUri: window.location.origin,
// });

authentication.initialize({
  instance: 'https://yolaedx.b2clogin.com/', 
  tenant: 'yolaedx.onmicrosoft.com',
  signInPolicy: 'B2C_1_YolaLearning',
  applicationId: process.env.REACT_APP_AZURE_APP_ID,
  cacheLocation: 'sessionStorage', //localStorage
  scopes: ['https://yolaedx.onmicrosoft.com/api/app', 'https://yolaedx.onmicrosoft.com/api/user_impersonation'],
  redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URL,
  postLogoutRedirectUri: window.location.origin,
  validateAuthority: false,
});

const App = () => {
  return (
    <div className="App">
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <Header />
        <Switch>
          <Route path="/not-found" component={NotFound} />
          <Route path="/error" component={ErrorPage} />
          <Route path="/test/:testCode/play/" exact component={authentication.required(Quiz)} />
          <Route path="/test/:testCode/play-without-login/:studentName/start/" exact component={Quiz} />
          <Route path="/test/:testCode/play-without-login/:studentName/" exact component={PlayWithoutLogin} />
          <Route path="/test/:testCode/:userName/grading/" exact component={authentication.required(ResultDetail)} />
          <Route path="/test/:testCode/:userName/" exact component={ResultDetail} />
          <Route path="/test/:testCode/:userName/detail/" exact component={authentication.required(ResultDetail)} />
          <Route path="/test/:testCode" exact component={InitStudent} />
          <Route path="/test/" exact component={Main} />
          <Route path="/recording/edx/:fname" exact component={RecordingAudio} />
          <Route path="/profile/dashboard/:uid/comment" exact component={authentication.required(Dashboard)} />
          <Route path="/profile/dashboard/:uid/announcement" exact component={authentication.required(Dashboard)} />
          <Route path="/profile/dashboard/:uid/note" exact component={authentication.required(Dashboard)} />
          <Route path="/profile/dashboard/:uid/" exact component={authentication.required(Dashboard)} />
          <Route path="/profile/dashboard/" exact component={authentication.required(Dashboard)} />
          <Route path="/profile/:edxid/" exact component={Dashboard} />
          {/* <Route path="/chat" exact component={b2cauth.required(Chatbox)} />
          <Route path="/chat/:uid/" exact component={b2cauth.required(Chatbox)} /> */}
          <Route path="/key-me/book-tutor" exact component={authentication.required(BookTutor)} />
          <Route path="/register" exact component={Register} />
          <Route path="/" component={HomePage} />
          <Redirect to="/not-found" />
        </Switch>
        <StickyFooter />
      </ThemeProvider>
    </div>
  );
}

export default App;
