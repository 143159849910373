import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    img: {
        maxWidth: "200px",
        width: "100%",
        height: "auto",
    },
    label: {
        fontSize: "1.5rem",
        marginLeft: "-200px",
        width: "200px",
        position: "absolute",
        color: "#fff"
    }
}));

function MultichoiceImage(props) {
    const classes = useStyles();
    
    const _showImage = (item, index) => {
        const checked = item.answer_id in props.studentAnswers ? true : false;
        return (
            <FormControlLabel key={index}
                control={
                    <Radio color="primary"/>
                }
                label={
                    <React.Fragment>
                        <img src={item.figure} key={item.answer_id} className={classes.img} alt={item.content} />
                        <span className={classes.label}>{item.content}</span>
                    </React.Fragment>
                }
                checked={checked}
                value={item.answer_id}
            />
        );
    }

    return (
        <RadioGroup onChange={props.onChange} row>
            {props.answerOptions.map((item, index) => _showImage(item, index))}
        </RadioGroup>
    );
}

MultichoiceImage.propsTypes = {
    onChange: PropTypes.func.isRequired,
    studentAnswers: PropTypes.object.isRequired,
    answerOptions: PropTypes.array.isRequired
}

export default MultichoiceImage;