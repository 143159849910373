import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { ReactMic } from "react-mic";
import is from "is_js";
import QRCode from "qrcode.react";
import ReactPlayer from "react-player";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import { red } from "@material-ui/core/colors";
import { saveRecording } from "../../services/recordingService";
import Info from "../quiz/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 560,
    borderRadius: 3,
    margin: "0 auto",
    paddingTop: "4.5rem",
    paddingBottom: "4rem",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  },
  status: {
    margin: theme.spacing(0),
    "& canvas": {
      width: "100%",
      height: "60px",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  recordingWrapper: {
    padding: theme.spacing(2),
  },
  mb5: {
    marginBottom: "5px",
  },
  fileName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    margin: 0,
  },
  uploadMessage: {
    fontWeight: "bold",
    color: "#63A84E",
  },
  btnChoose: {
    marginRight: "10px !important",
    padding: "5px 2px",
  },
  qrcode: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "2rem",
  },
  uploadTitle: {
    padding: "0.5rem 1rem",
    display: "flex",
    "& button": {
      padding: 0,
    },
  },
  uploadWrapper: {
    padding: "0 1rem",
  },
  moreInfo: {
    textAlign: "center"
  }
}));

const info = `RECORDING AUDIO
    1. Click START RECORDING to record your voice
    2. Click SAVE then click Play button to hear the recording
    3. Adjust the volume if necessary
    4. Turn on or allow your micro if your voice is not recorded
    5. Click START RECORDING to record again.`;

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

function RecordingAudio(props) {
  const classes = useStyles();
  const history = useHistory();
  const socketRef = useRef();

  const { fname } = props.match.params;
  const matchUrl = props.match.url;
  const directory = matchUrl.split("/")[2];

  // recording hook
  const [recordStatus, setRecordStatus] = useState(false);
  const [audioObj, setAudioObj] = useState({});

  const lsAudioLink = localStorage.getItem(
    `yolarecording_${directory}_${fname}`
  );
  const [audioLink, setAudioLink] = useState(lsAudioLink ? lsAudioLink : "");

  // upload file hook
  const [recordingType, setRecordingType] = useState(is.ios() || is.safari() ? "upload" : "");
  const [progressUpload, setProgressUpload] = useState(0);
  const audioUploadObj = {
    open: false,
    selectedFile: null,
    currentFile: null,
    message: "",
    progress: 0,
  };
  const [audioUpload, setAudioUpload] = useState(audioUploadObj);

  useEffect(() => {
    // process qr code audio upload
    if (recordingType === "upload") {
      const socketUrlKey = `${directory}audio_${fname}`;

      if (
        !socketRef ||
        !socketRef.current ||
        socketRef.current.readyState === WebSocket.CLOSED
      ) {
        socketRef.current = new WebSocket(
          `${process.env.REACT_APP_WEBSOCKET_API_URL}/${socketUrlKey}/`
        );
      }

      socketRef.current.onopen = (e) => {
        console.log("open", e);
      };
      socketRef.current.onmessage = (e) => {
        const data = JSON.parse(e.data);
        console.log("data upload socket", data);
        if (data && data["link"]) {
          const linkSplit = data["link"].split("/");
          if (linkSplit.length > 0) {
            if (
              linkSplit[linkSplit.length - 1] === `${directory}audio_${fname}`
            ) {
              toast.success("Saved successfully!");
              setAudioLink(data["link"]);

              // Save to localStorage
              if (data["link"]) {
                localStorage.setItem(
                  `yolarecording_${directory}_${fname}`,
                  data["link"]
                );
              }

              setAudioUpload((a) => ({
                ...a,
                progress: 1,
              }));
            }
          }
        }
      };
      socketRef.current.onerror = (e) => {
        console.log("error", e);
      };
    }
  });

  const handleChangeRecordingType = () => {
    setRecordingType("upload");
    const socketUrlKey = `${directory}audio_${fname}`;
    if (
      !socketRef ||
      !socketRef.current ||
      socketRef.current.readyState === WebSocket.CLOSED
    ) {
      socketRef.current = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_API_URL}/${socketUrlKey}/`
      );
    }
  };

  const uploadAudioFile = () => {
    if (audioUpload.selectedFile && audioUpload.selectedFile.length > 0) {
      const file = audioUpload.selectedFile[0];
      const fsize = Math.round(file.size / 1024);
      if (fsize === 0 || fsize > 20480) {
        setAudioUpload({
          ...audioUpload,
          message: "Audio file is too big!",
        });
        return;
      }
      const allowAudioType = [
        "audio/wav",
        "audio/mpeg",
        "audio/ogg",
        "audio/x-m4a",
        "audio/mp4",
        "audio/x-aiff",
        "audio/aac",
        "video/webm",
        "application/octet-stream",
      ];
      if (!allowAudioType.includes(file.type)) {
        if(!(file instanceof Blob)) {
          setAudioUpload({
            ...audioUpload,
            message: "Please upload an audio file!",
          });
          return;
        }
      }
      setAudioUpload({
        ...audioUpload,
        currentFile: file,
        progress: 1,
      });
      onStop(file);
    }
  };

  const selectFile = (e) => {
    setAudioUpload({
      ...audioUpload,
      selectedFile: e.target.files,
      currentFile: null,
      message: "",
      progress: 0,
    });
  };

  if (!directory || !fname) {
    toast.error("wrong url");
    return history.push("/");
  }

  const startRecording = (e) => {
    setRecordStatus(true);
  };

  const stopRecording = () => {
    setRecordStatus(false);
  };

  const onStop = async (obj) => {
    if (directory && fname) {
      let response = null;
      if (obj.blob) {
        // save and upload recording
        response = await saveRecording(directory, obj.blob, fname);
      } else if (obj.name) {
        // save and upload recording
        response = await saveRecording(directory, obj, fname, (event) => {
          setProgressUpload(Math.round((100 * event.loaded) / event.total));
        });
      }
      if (response) {
        const data = response.data;
        if (data && data.status_code === 200) {
          toast.success("Saved successfully!");
          setAudioObj(obj);
          setAudioLink(data.audio_link);

          // Save to localStorage
          if (data.audio_link) {
            localStorage.setItem(
              `yolarecording_${directory}_${fname}`,
              data.audio_link
            );
          }
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("Something went wrong, please try again later.");
      }
    }
  };

  return (
    <Grid container justify="center" className={classes.root}>
      {/* Recording */}
      {recordingType !== "upload" && (
        <React.Fragment>
          <Grid item xs={12} className={classes.status}>
            <ReactMic
              record={recordStatus}
              className="sound-wave"
              onStop={onStop}
              strokeColor="#fff"
              backgroundColor={red[100]}
            />
          </Grid>
          <Grid item xs={12} className={classes.recordingWrapper}>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Button
                  disabled={recordStatus}
                  type="button"
                  size="large"
                  color="secondary"
                  variant="outlined"
                  className={classes.button}
                  onClick={startRecording}
                >
                  START RECORDING
                </Button>
                <Button
                  disabled={!recordStatus}
                  type="button"
                  size="large"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={stopRecording}
                >
                  SAVE
                </Button>
              </Grid>
              <Grid item xs={3} className={classes.moreInfo}>
                <Info info={info} />
                <Button color="primary" onClick={handleChangeRecordingType}>
                  Cannot recording?
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {audioObj["blobURL"] && (
            <Grid item xs={12}>
              <ReactPlayer
                url={audioObj["blobURL"]}
                controls={true}
                width="100%"
                height="50px"
              />
            </Grid>
          )}
        </React.Fragment>
      )}

      {/* Upload audio */}
      {recordingType === "upload" && (
        <div className={classes.uploadWrapper}>
          <Typography variant="subtitle1" className={classes.uploadTitle}>
            Scan this QR Code to upload audio from another device.
          </Typography>
          <div className={classes.qrcode}>
            <QRCode
              value={`${process.env.REACT_APP_API_URL}/q/upload_audio/${directory}audio_${fname}/`}
            />
          </div>

          <div>
            {audioUpload.currentFile &&
              progressUpload > 0 &&
              audioUpload.progress > 0 && (
                <Box className={classes.mb5} display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={progressUpload ? progressUpload : 0}
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">{`${
                      progressUpload ? progressUpload : 0
                    }%`}</Typography>
                  </Box>
                </Box>
              )}

            <label htmlFor={`btn-upload`}>
              <input
                id={`btn-upload`}
                name={`btn-upload`}
                style={{ display: "none" }}
                type="file"
                onChange={selectFile}
              />

              <Button
                className={classes.btnChoose}
                variant="outlined"
                component="span"
              >
                Choose audio file
              </Button>
            </label>

            <Button
              color="primary"
              variant="contained"
              component="span"
              disabled={!audioUpload.selectedFile || audioUpload.progress > 0}
              onClick={uploadAudioFile}
            >
              Upload
            </Button>

            <h6 className={classes.fileName}>
              {audioUpload.selectedFile && audioUpload.selectedFile.length > 0
                ? audioUpload.selectedFile[0].name
                : null}
            </h6>

            <Typography variant="subtitle2" className={classes.uploadMessage}>
              {audioUpload.message}
              {audioUpload.progress > 0 &&
                progressUpload === 100 &&
                "Your audio file uploaded successfully!"}
            </Typography>
          </div>

          {audioLink && (
            <React.Fragment>
              <Typography variant="h6" className="list-header">
                Your audio file
              </Typography>
              <ReactPlayer
                url={audioLink}
                controls={true}
                width="100%"
                height="50px"
              />
            </React.Fragment>
          )}
        </div>
      )}

      <input
        type="hidden"
        name="hdd_audio_link"
        id="hdd_audio_link"
        value={audioLink}
      />
    </Grid>
  );
}

export default RecordingAudio;
