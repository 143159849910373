import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { css } from "@emotion/core";
import HashLoader from "react-spinners/HashLoader";
import { addBackToTop } from "vanilla-back-to-top";
import moment from "moment";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Avatar, Typography } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import {
  getStudentProfile,
  getStudentProfileFromEdx,
} from "../../services/profileService";
import usePushNotifications from "../../utils/usePushNotifications";

import Overall from "./Overall";
import Info1 from "./Info1";
import Info2 from "./Info2";
import Info3 from "./Info3";
import MainInfo from "./MainInfo";
import Chart1 from "./Chart1";
import Chart2 from "./Chart2";
import List1 from "./List1";
import List2 from "./List2";
import List3 from "./List3";
import List4 from "./List4";
import List5 from "./List5";
import ParentDashboard from "./ParentDashboard";
import NotificationDetail from "./NotificationDetail";
import Gamification from "./Gamification";
// import ChatIconBottom from '../chatbox/ChatIconBottom';
import DashboardBreadcrumbs from "./DashboardBreadcrumbs";
import DashboardCharts from "./DashboardCharts";

const overrideSpinner = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 3,
    margin: "3rem auto",
    flexGrow: 1,
  },
  root: {
    padding: theme.spacing(2),
    margin: "3rem auto",
    width: "100%",
    flexGrow: 1,
  },
  moveCourseWraper: {
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    margin: "35vh auto",
    zIndex: 1,
    width: "100%",
  },
  moveCourse: {
    border: "3px solid",
    width: "60px",
    borderRadius: "30px",
    "& svg": {
      width: "100%",
      fontSize: "3rem",
    },
    color: blue[300],
  },
  avatar: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.white,
    height: 52,
    width: 52,
    margin: "auto",
    fontWeight: "bold",
  },
  studentName: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: theme.palette.warning.dark,
  },
  studentNameWrapper: {
    marginBottom: "auto",
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();

  const history = useHistory();

  usePushNotifications();

  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const objState = {
    courses: [
      {
        courseProgram: null,
        courseName: "",
        courseStartDate: null,
        courseEndDate: null,
        hwResult: {},
        hwTimespent: {},
        hwSkill: {},
        hwSkillGeneral: {},
        hwLevel: {},
        testResult: [],
        listTestResult: {},
        comments: [],
        announcements: [],
        notes: [],
        studentName: "",
        effort: {},
      },
    ],
    currentIndex: null,
    coursesByProgram: {},
  };
  const [state, setState] = useState(objState);

  const [studentList, setStudentList] = useState([]);

  const [notificationType, setNotificationType] = useState({});

  useEffect(() => {
    const { uid, edxid } = props.match.params;
    const { path } = props.match;
    async function fetchData() {
      // turn on spinner
      setLoadingSpinner(true);
      let result = {};
      if (edxid) {
        const { data } = await getStudentProfileFromEdx(history, edxid);
        result = data;
      } else {
        const { data } = await getStudentProfile(history, uid);
        result = data;
      }
      const data = "data" in result ? result.data : [];

      if (result.status_code !== 200) {
        if (result.message === "Data not found") {
          toast.info(
            "If you are student or parent, please sync your social account(Google, Facebook) in learn.yola.vn"
          );
          return history.push("/");
        } else {
          toast.error(result.message);
          return history.push("/");
        }
      }

      // turn off spinner
      setLoadingSpinner(false);

      // check if it's parent
      if (data[0] && !data[0].courseId) {
        setStudentList(data);
        return;
      }

      let courses = [];

      const list_courses = data;
      if (list_courses.length === 0) {
        toast.error("haven't found any course");
        return history.push("/");
      }

      const getNotiArray = (element) => {
        let comments = [];
        for (const c of element.comments) {
          if (
            Object.entries(c)[0][1]["comment"] ||
            Object.entries(c)[0][1]["star"]
          ) {
            comments.push(c);
          }
        }

        const announcements = element.announcements.sort(
          (a, b) =>
            moment(b.date, "YYYY-MM-DD HH:mm:ss") -
            moment(a.date, "YYYY-MM-DD HH:mm:ss")
        );

        const notes = element.notes.sort(
          (a, b) =>
            moment(b.date, "YYYY-MM-DD HH:mm:ss") -
            moment(a.date, "YYYY-MM-DD HH:mm:ss")
        );
        return {
          comments: comments,
          announcements: announcements,
          notes: notes,
        };
      };

      list_courses.forEach((element) => {
        const courseProgram = element.courseProgram;
        const courseName = element.courseName;
        const courseStartDate = element.courseStartDate;
        const courseEndDate = element.courseEndDate;
        let hwResult = element.homeWork.homeworkCompletedStatistics.finalTerm;
        if (Object.keys(hwResult).length === 0) {
          hwResult = element.homeWork.homeworkCompletedStatistics.midTerm;
        }

        let hwTimespent = element.homeWork.userStatistics.finalTerm;
        if (Object.keys(hwTimespent).length === 0) {
          hwTimespent = element.homeWork.userStatistics.midTerm;
        }

        let hwSkill = element.homeWork.lessonTypeGradeStatistics.finalTerm;
        if (Object.keys(hwSkill).length === 0) {
          hwSkill = element.homeWork.lessonTypeGradeStatistics.midTerm;
        }

        let hwSkillGeneral =
          element.homeWork.lessonTypeGeneralGradeStatistics.finalTerm;
        if (Object.keys(hwSkillGeneral).length === 0) {
          hwSkillGeneral =
            element.homeWork.lessonTypeGeneralGradeStatistics.midTerm;
        }

        let hwLevel = element.homeWork.levelTypeGradeStatistics.finalTerm;
        if (Object.keys(hwLevel).length === 0) {
          hwLevel = element.homeWork.levelTypeGradeStatistics.midTerm;
        }

        let testResult = "FT" in element.appify ? element.appify["FT"] : [];
        if (
          !testResult ||
          testResult.filter((item) => item["Total Score"] === 0).length > 0
        ) {
          testResult = "MT" in element.appify ? element.appify["MT"] : [];
        }

        let listTestResult = {};
        if ("MT" in element.appify && element.appify["MT"]) {
          listTestResult["Midterm"] = element.appify["MT"];
        }
        if ("FT" in element.appify && element.appify["FT"]) {
          listTestResult["Final"] = element.appify["FT"];
        }

        const { comments, announcements, notes } = getNotiArray(element);

        courses.push({
          courseProgram: courseProgram,
          courseName: courseName,
          courseStartDate: courseStartDate,
          courseEndDate: courseEndDate,
          hwResult: hwResult,
          hwTimespent: hwTimespent,
          hwSkill: hwSkill,
          hwSkillGeneral: hwSkillGeneral,
          hwLevel: hwLevel,
          testResult: testResult,
          listTestResult: listTestResult,
          comments: comments,
          announcements: announcements,
          notes: notes,
          studentName: element.studentName,
          effort: element.studentCourseScore,
        });
      });

      courses = courses.sort(
        (a, b) =>
          moment(b.courseStartDate, "YYYY-MM-DD") -
          moment(a.courseStartDate, "YYYY-MM-DD")
      );

      let coursesByProgram = {};
      for (const p of ["KID", "JUNIOR", "IELTS"]) {
        const cbp = courses.filter((c) => c.courseProgram === p);
        if (cbp.length > 0) {
          coursesByProgram[p] = cbp.sort(
            (a, b) =>
              moment(a.courseStartDate, "YYYY-MM-DD") -
              moment(b.courseStartDate, "YYYY-MM-DD")
          );
        }
      }

      setState((state) => ({
        ...state,
        courses: courses,
        coursesByProgram: coursesByProgram,
      }));

      const { comments, announcements, notes } = getNotiArray(courses[0]);
      const pathMatchEnd = path.split("/")[4];
      let notiObj = {};
      if (pathMatchEnd === "comment" && comments.length > 0) {
        notiObj[pathMatchEnd] = comments;
      } else if (pathMatchEnd === "announcement" && announcements.length > 0) {
        notiObj[pathMatchEnd] = announcements;
      } else if (pathMatchEnd === "note" && notes.length > 0) {
        notiObj[pathMatchEnd] = notes;
      }
      setNotificationType(notiObj);
    }
    fetchData();
  }, [props, history]);

  // check if it's parent
  if (studentList.length > 0) {
    return <ParentDashboard studentList={studentList} />;
  }

  const previousCourse = () => {
    setLoadingSpinner(true);
    setTimeout(function () {
      setState((state) => ({
        ...state,
        currentIndex: state.currentIndex > 0 ? state.currentIndex - 1 : 0,
      }));
      setLoadingSpinner(false);
    }, 1000);
  };

  const nextCourse = () => {
    setLoadingSpinner(true);
    setTimeout(function () {
      setState((state) => ({
        ...state,
        currentIndex:
          state.currentIndex < state.courses.length - 1
            ? state.currentIndex + 1
            : state.currentIndex,
      }));
      setLoadingSpinner(false);
    }, 1000);
  };

  addBackToTop({ backgroundColor: blue[400] });

  if (state.courses.length === 0) {
    return "";
  }

  const setCourse = (index) => {
    setState((state) => ({
      ...state,
      currentIndex: index,
    }));
  };

  const loadProfile = () => {
    setState((state) => ({
      ...state,
      currentIndex: null,
    }));
  };

  return (
    <React.Fragment>
      {state.currentIndex !== null && (
        <div className={classes.moveCourseWraper}>
          {state.currentIndex > 0 && (
            <Button
              variant="outlined"
              className={classes.moveCourse}
              title="Go to previous course"
              onClick={previousCourse}
            >
              <ArrowBackIosRoundedIcon />
            </Button>
          )}
          {state.currentIndex < state.courses.length - 1 && (
            <Button
              variant="outlined"
              className={classes.moveCourse}
              title="Go to next course"
              onClick={nextCourse}
            >
              <KeyboardArrowRightIcon />
            </Button>
          )}
        </div>
      )}

      <div className={classes.root}>
        <HashLoader
          css={overrideSpinner}
          // size={150}
          color={blue[300]}
          loading={loadingSpinner}
        />

        {Object.keys(notificationType).length > 0 && (
          <Grid item xs={12}>
            <NotificationDetail notificationType={notificationType} />
          </Grid>
        )}

        {state.currentIndex === null && (
          <Grid container spacing={4} alignItems="flex-start">
            {state.courses[0].courseName !== "" && (
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Grid
                  container
                  spacing={4}
                  alignItems="center"
                  justify="center"
                  className={classes.studentNameWrapper}
                >
                  <Grid item>
                    <Avatar className={classes.avatar}></Avatar>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.studentName}>
                      {state.courses[0].studentName}
                    </Typography>
                  </Grid>
                </Grid>
                {Object.keys(state.courses[0].effort).length > 0 && (
                  <Gamification
                    currentEffort={state.courses[0].effort}
                    previousEffort={
                      state.courses.length > 1 ? state.courses[1].effort : {}
                    }
                    courseProgram={state.courses[0].courseProgram}
                  />
                )}
              </Grid>
            )}

            {Object.entries(state.coursesByProgram).map((cbp, key) => {
              return (
                <Grid key={key} item lg={3} sm={6} xl={3} xs={12}>
                  <DashboardCharts courses={cbp[1]} program={cbp[0]} />
                </Grid>
              );
            })}

            {state.courses.map((course, index) => (
              <Grid key={index} item lg={3} sm={6} xl={3} xs={12}>
                <Overall course={course} index={index} setCourse={setCourse} />
              </Grid>
            ))}
          </Grid>
        )}

        {state.currentIndex !== null && (
          <div>
            <DashboardBreadcrumbs
              courseName={state.courses[state.currentIndex].courseName}
              loadProfile={loadProfile}
              studentName={state.courses[state.currentIndex].studentName}
            />
            <Grid container spacing={4}>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <MainInfo
                  testResult={state.courses[state.currentIndex].testResult}
                  courseName={state.courses[state.currentIndex].courseName}
                  courseStartDate={
                    state.courses[state.currentIndex].courseStartDate
                  }
                  courseEndDate={
                    state.courses[state.currentIndex].courseEndDate
                  }
                  studentName={state.courses[state.currentIndex].studentName}
                />
              </Grid>
              {false &&
                Object.keys(state.courses[state.currentIndex].effort).length >
                  0 && (
                  <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Gamification
                      currentEffort={state.courses[state.currentIndex].effort}
                      previousEffort={
                        state.currentIndex > 0
                          ? state.courses[state.currentIndex - 1].effort
                          : {}
                      }
                      courseProgram={
                        state.courses[state.currentIndex].courseProgram
                      }
                    />
                  </Grid>
                )}
              {Object.keys(state.courses[state.currentIndex].hwResult).length >
                0 && (
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                  <Info1
                    currentHWResult={state.courses[state.currentIndex].hwResult}
                    previousHWResult={
                      state.currentIndex > 0
                        ? state.courses[state.currentIndex - 1].hwResult
                        : {}
                    }
                  />
                </Grid>
              )}
              {Object.keys(state.courses[state.currentIndex].hwResult).length >
                0 && (
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                  <Info2
                    currentHWResult={state.courses[state.currentIndex].hwResult}
                    previousHWResult={
                      state.currentIndex > 0
                        ? state.courses[state.currentIndex - 1].hwResult
                        : {}
                    }
                  />
                </Grid>
              )}
              {Object.keys(state.courses[state.currentIndex].hwTimespent)
                .length > 0 &&
                state.courses[state.currentIndex].hwTimespent.total_time_spend >
                  0 && (
                  <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Info3
                      hwTimespent={
                        state.courses[state.currentIndex].hwTimespent
                      }
                    />
                  </Grid>
                )}
              {Object.keys(state.courses[state.currentIndex].hwSkill).length >
                0 && (
                <Grid item lg={8} md={12} xl={9} xs={12}>
                  <Chart1 hwSkill={state.courses[state.currentIndex].hwSkill} />
                </Grid>
              )}
              {(Object.keys(state.courses[state.currentIndex].hwLevel).length >
                0 ||
                Object.keys(state.courses[state.currentIndex].hwSkillGeneral)
                  .length > 0) && (
                <Grid item lg={4} md={6} xl={3} xs={12}>
                  <Chart2
                    hwLevel={state.courses[state.currentIndex].hwLevel}
                    hwSkillGeneral={
                      state.courses[state.currentIndex].hwSkillGeneral
                    }
                  />
                </Grid>
              )}
              {Object.keys(state.courses[state.currentIndex].hwTimespent)
                .length > 0 &&
                state.courses[state.currentIndex].hwTimespent.total_time_spend >
                  0 && (
                  <Grid item lg={4} md={4} xl={3} xs={12}>
                    <List1
                      hwTimespent={
                        state.courses[state.currentIndex].hwTimespent
                      }
                    />
                  </Grid>
                )}
              <Grid item lg={8} md={8} xl={9} xs={12}>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <List2
                      listTestResult={
                        state.courses[state.currentIndex].listTestResult
                      }
                    />
                  </Grid>
                  {state.courses[state.currentIndex].comments.length > 0 && (
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <List3
                        comments={state.courses[state.currentIndex].comments}
                      />
                    </Grid>
                  )}
                  {state.courses[state.currentIndex].announcements.length >
                    0 && (
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <List4
                        announcements={
                          state.courses[state.currentIndex].announcements
                        }
                      />
                    </Grid>
                  )}
                  {state.courses[state.currentIndex].notes.length > 0 && (
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <List5 notes={state.courses[state.currentIndex].notes} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
