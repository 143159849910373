import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar, Link, Button } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';


const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 3,
    margin: "3rem auto"
  },
  root: {
    padding: theme.spacing(4),
    margin: "3rem auto",
    width: "100%",
    display: "flex",
    flexGrow: 1,
  },
  title: {
    fontWeight: 700,
    color: theme.palette.info.light
  },
  avatar: {
    backgroundColor: theme.palette.info.light,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textDecoration: 'none !important'
  }
}));

const ParentDashboard = (props) => {
  const classes = useStyles();

  const { studentList } = props;

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          {studentList.map((s, key) => (
            <Grid item lg={3} sm={6} xl={3} xs={12} key={key} >
              <Card>
                <CardContent>
                  <Grid
                    container
                    justify="space-between"
                  >
                    <Grid item>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        variant="h4"
                      >
                        {s.studentName}
                      </Typography>
                      <Typography variant="h3">
                        <Link href={`/profile/dashboard/${s.studentUid}`} variant="body1"
                          className={classes.link}>
                          Go to profile
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button onClick={() => window.location.href=`/profile/dashboard/${s.studentUid}`}>
                        <Avatar className={classes.avatar}>
                          <AccountCircle className={classes.icon} />
                        </Avatar>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </React.Fragment>
  );
};

ParentDashboard.propTypes = {
  studentList: PropTypes.array
}

export default ParentDashboard;
