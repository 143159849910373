import React, {useEffect, useState, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import Moment from 'react-moment';
import { CSSTransitionGroup } from 'react-transition-group';
import { toast } from 'react-toastify';
import { addBackToTop } from 'vanilla-back-to-top';
import { css } from '@emotion/core';
import RingLoader from 'react-spinners/RingLoader';
import { UseStateValue } from '../../state';
import { getStudentResultDetail, 
    getStudentResultDetailGrading,
    postDetailGrading,
    checkAudioFileExists,
    uploadAudioFile
} from '../../services/quizService';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import PrintIcon from '@material-ui/icons/Print';
import { Link, ListItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { blue, grey, purple, green, red } from '@material-ui/core/colors';
import ResultDetailAnswer from './ResultDetailAnswer';
import QuizInfo from './QuizInfo';

const overrideSpinner = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1170,
        borderRadius: 3,
        margin: "2.5rem auto",
        flexGrow: 1
    },
    pageHeader: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: 16,
        top: 0,
        zIndex: 2,
        // position: "sticky",
    },
    pageHeader2: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: 16,
    },
    list: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    testInfo: {
        color: grey[500],
        fontSize: 14,
        paddingRight: 10
    },
    testInfoName: {
        color: grey[900],
        fontSize: 16
    },
    testInfoStudent: {
        color: blue[900],
        fontSize: 16
    },
    testInfoStudentScore: {
        color: purple[900],
        fontSize: 16
    },
    time: {
        color: grey[900]
    },
    print: {
        padding: "60px 26px",
        textAlign: "right",
        top: 0,
        zIndex: 1,
        position: "sticky"
    },
    printIcon: {
        color: blue[600],
        cursor: "pointer"
    },
    doneAll: {
        color: green[600],
        marginLeft: 20
    },
    failed: {
        color: red[600]
    },
    gradingTitle: {
        color: purple[900]
    },
    saveGrade: {
        padding: theme.spacing(2)
    },
    userSkillScore: {
        textTransform: "uppercase"
    }
}));

function ResultDetail(props) {
    const classes = useStyles();
    const history = useHistory();
    const [, dispatch] = UseStateValue();
    
    const pageResultRef = useRef();

    const [open, setOpen] = useState({});

    const objResultState = {
        testName: '',
        quizDescription: '',
        totalTestPoint: null,
        studentName: null,
        studentScore: null,
        studentStartTime: null,
        studentEndTime: null,
        resultDetail: [],
        scoredPercentage: null,
        passResult: null,
        userPass: null,
        testResult: null,
        userSkillScore: null,
        documentGrade: null
    }
    const [resultState, setResultState] = useState(objResultState);
    const [gradingDisable, setGradingDisable] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    // get params
    const { testCode, userName } = props.match.params;
    const pathGrading = props.match.path.split('/')[4];

    useEffect(() => {
        async function fetchData() {
            if(testCode === "undefined" || testCode === "null" 
                || userName === "undefined" || userName === "null") {
                dispatch({
                    type: "updateStudentFinish",
                    studentFinish: "no"
                })
                return;
            }

            // turn on spinner
            setLoadingSpinner(true);

            let res;
            if(pathGrading === 'grading') {
                const { data } = await getStudentResultDetailGrading(testCode, userName, history);
                res = data;
            } else {
                const { data } = await getStudentResultDetail(testCode, userName, history);
                res = data;
            }

            // turn off spinner
            setLoadingSpinner(false);
            
            if(res.status_code !== 200) {
                if(res.message === 'Data not found') {
                    toast.error("Haven't found any data!");
                    return history.push('/test');
                }

                toast.error(res.message);
                return history.push('/not-found');
            }

            // set test info
            const {quizTitle, 
                    quizDescription,
                    quizMaxScore, 
                    userFirstName, 
                    userLastName, 
                    userMaxScore, 
                    quizPassMark, 
                    userStartTime, 
                    userFinishTime,
                    quizQuestions,
                    userPass,
                    userLevel,
                    userSkillScore,
                    documentGrade
                } = res.data;

            const scoredPercentage = quizMaxScore ? Math.floor(userMaxScore / quizMaxScore * 100) : 0;

            setResultState(resultState => ({
                ...resultState,
                testName: quizTitle,
                quizDescription: quizDescription,
                totalTestPoint: quizMaxScore,
                studentName: `${userFirstName} ${userLastName}`,
                studentScore: userMaxScore > 0 ? userMaxScore : null,
                scoredPercentage: scoredPercentage,
                passResult: (quizPassMark != null && quizPassMark > 0) ? 
                    (scoredPercentage >= quizPassMark ? 'Passed' : 'Failed') : null,
                studentStartTime: userStartTime,
                studentEndTime: userFinishTime,
                resultDetail: quizQuestions,
                userPass: userPass,
                testResult: userLevel,
                userSkillScore: userSkillScore,
                documentGrade: documentGrade
            }));

            let o = {};
            for(var i of quizQuestions.keys()) {
                o[i] = true;
            }
            setOpen(o);
        }
        fetchData();
    }, [history, testCode, userName, pathGrading, dispatch]);
        
    const handleClick = (i) => {
        let o = {...open};
        o[i] = !open[i];
        setOpen(o);
    }

    const _setPassResult = (pR) => {
        if(pR === 'Passed') {
            return (
                <span className={classes.doneAll}>
                    <span>{pR}</span>
                </span>
                
            );
        } else if(pR === 'Failed') {
            return (
                <span className={classes.failed}>
                    <span>{pR}</span>
                </span>
            );
        }
        return '';
    }

    const getGridListCols = () => {
        if (isWidthUp('lg', props.width)) {
          return 4;
        }
    
        if (isWidthUp('md', props.width)) {
          return 3;
        }
    
        if (isWidthUp('sm', props.width)) {
          return 2;
        }
    
        return 1;
    }

    const getGridListCols2 = () => {
        if (isWidthUp('lg', props.width)) {
            return 1;
        }
        return 1;
    }

    const _setVoiceQuestionCurr = (quesId) => {
        localStorage.setItem(`yolatest_recording_voice_question_curr_id_${testCode}_${userName}`, quesId);
    }

    const _setGrading = (item, e, gradingType) => {
        if(typeof e.persist === 'function') {
            e.persist();
        }
        
        if(gradingType === 'grade') {
            let { value } = e.currentTarget;
            if(item['criteria'] == null || item['criteria'].length === 0) {
                const min = 0, max = item['questionScore'];
                value = Math.max(Number(min), Math.min(Number(max), Number(value)));
                item['userScore'] = Number(value);
            } else {
                // this question is graded by criteria
                const criteriaId = parseInt(e.currentTarget.getAttribute('id').split('_')[2]);
                const criteria = item['criteria'].map(c => {
                    if(c.criteria_id === criteriaId) {
                        const min = 0, max = c['criteria_max_point'];
                        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
                        c['criteria_point'] = Number(value);
                    }
                    return c;
                });
                item['criteria'] = criteria;
            }
            
        } else if(gradingType === 'comment') {
            let { value } = e.currentTarget;
            if(item['criteria'] == null || item['criteria'].length === 0) {
                item['teacherComment'] = value;
            } else {
                // this question is graded by criteria
                const criteriaId = parseInt(e.currentTarget.getAttribute('id').split('_')[2]);
                const criteria = item['criteria'].map(c => {
                    if(c.criteria_id === criteriaId) {
                        c['criteria_comment'] = value;
                    }
                    return c;
                });
                item['criteria'] = criteria;
            }
            
        } else if(gradingType === 'voice-comment') {
            item['teacherVoiceComment'] = e || null;
        }

        return item;
    }

    const handleGrading = (e, gradingType) => {
        if(typeof e.persist === 'function') {
            e.persist();
        }

        let resultDetail = [...resultState.resultDetail];
        
        let questionId = null;
        let qParentId = null;
        if(gradingType === 'voice-comment') {
            questionId = parseInt(localStorage.getItem(`yolatest_recording_voice_question_curr_id_${testCode}_${userName}`));

            const localRD = localStorage.getItem(`yolatest_grading_resultDetail_${testCode}_${userName}`);
            if(localRD) {
                resultDetail = JSON.parse(localRD);
            }
        } else {
            questionId = parseInt(e.currentTarget.getAttribute('id').split('_')[1]);
            qParentId = e.currentTarget.getAttribute('id').split('-')[0];
            qParentId = qParentId !== 'null' ? parseInt(qParentId) : null;
        }
        if(questionId === null) return;

        resultDetail = resultDetail.map(q => {
            let item = {...q};
            // if multiquestion
            if(item['question_type'] === 'multiquestion') {
                if(item['question_id'] === qParentId) {
                    item['sub_questions'] = item['sub_questions'].map(item2 => {
                        if(item2['question_id'] === questionId) {
                            item2 = _setGrading(item2, e, gradingType);
                        }
                        return item2;
                    });
                }
            } else {
                if(item['question_id'] === questionId && qParentId === null) {
                    item = _setGrading(item, e, gradingType);
                }
            }
            return item;
        });

        setResultState({
            ...resultState,
            resultDetail: resultDetail
        });

        // update local storage
        localStorage.setItem(`yolatest_grading_resultDetail_${testCode}_${userName}`, JSON.stringify(resultDetail));
    }

    const _preSave = async (q, e, kind=null, qParentId=null) => {
        if(typeof e.persist === 'function') {
            e.persist();
        }
        let audio = null;
        if(q['teacherVoiceComment'] != null) {
            if(q['teacherVoiceComment']['blob']) {
                // upload file to the server
                const blobExists = await checkAudioFileExists(q['teacherVoiceComment'].blobURL);
                if(blobExists) {
                    const blobRes = await fetch(q['teacherVoiceComment'].blobURL);
                    const blob = await blobRes.blob();

                    const { data } = await uploadAudioFile(blob, userName);
                    if(data.status_code === 200) {
                        audio = data.audio_link;
                    }
                }
            } else {
                audio = q['teacherVoiceComment'].blobURL;
            }
        }
        let gradingObj = {};
        if(q['criteria'] == null || q['criteria'].length === 0) {
            gradingObj = {
                point: e.target[`${qParentId}-${kind}-grade_${q['question_id']}`] ? e.target[`${qParentId}-${kind}-grade_${q['question_id']}`].value : 0,
                comment: e.target[`${qParentId}-${kind}-comment_${q['question_id']}`] ? e.target[`${qParentId}-${kind}-comment_${q['question_id']}`].value : null,
                audio: audio
            };
        } else {
            // if this question is graded by criteria
            let criteria = {};
            q['criteria'].forEach(c => {
                criteria[c['criteria_id']] = {
                    point: e.target[`${qParentId}-${kind}-grade_${q['question_id']}_${c['criteria_id']}`] ? e.target[`${qParentId}-${kind}-grade_${q['question_id']}_${c['criteria_id']}`].value : 0,
                    comment: e.target[`${qParentId}-${kind}-comment_${q['question_id']}_${c['criteria_id']}`] ? e.target[`${qParentId}-${kind}-comment_${q['question_id']}_${c['criteria_id']}`].value : null,
                };
            });
            gradingObj = {
                criteria: criteria,
                audio: audio
            }
        }
        return gradingObj;
    }

    const saveGrade = async (e) => {
        if(typeof e.persist === 'function') {
            e.persist();
        }
        e.preventDefault();
        let data = {};

        for(var q of resultState.resultDetail) {
            // if multiquestion
            if(q['question_type'] === 'multiquestion') {
                let childQuestions = {};
                for(var q2 of q['sub_questions']) {
                    childQuestions[q2['question_id']] = await _preSave(q2, e, 'is-child-question', q['question_id']);
                }
                data[q['question_id']] = childQuestions;
            } else {
                data[q['question_id']] = await _preSave(q, e);
            }
        }

        postDetailGrading(testCode, userName, data)
        .then((res) => {
            if(res.data.status_code === 200 || res.data.status_code === 201) {
                toast.success(res.data.message);
                setGradingDisable(true);
            } else {
                toast.error(res.data.message);
            }
        })
        .catch((err) => {
            console.log('error', err);
        });
    }

    addBackToTop({backgroundColor: blue[400]});

    const _setFinalResult = (sk, key) => {
        let fr = null;
        let cr = null;
        if(typeof(resultState.userSkillScore[sk]) === 'object') {
            cr = Object.keys(resultState.userSkillScore[sk]).map((crite, key2) => (
                <span key={key2} className={classes.userSkillScore}> {crite}: {resultState.userSkillScore[sk][crite]},</span>
            ));
            fr = <span key={key} className={classes.userSkillScore}> {sk}: ({cr}),</span>;
        } else {
            fr = <span key={key} className={classes.userSkillScore}> {sk}: {resultState.userSkillScore[sk]},</span>;
        }
        
        return fr;
    }

    const _getFinalResult = () => {
        let result = null;
        if(pathGrading !== 'grading' && (
                resultState.passResult != null 
                || resultState.userPass != null
                || resultState.testResult != null 
                || resultState.userSkillScore != null)
        ) {
            let finalResult = _setPassResult(resultState.passResult);
            if(resultState.userSkillScore != null) {
                finalResult = Object.keys(resultState.userSkillScore).map((sk, key) => _setFinalResult(sk, key));
            } else if(resultState.testResult != null) {
                finalResult = resultState.testResult;
            } else if(resultState.userPass != null) {
                finalResult = resultState.userPass;
            }
            result = (
                <Grid item xs={8} className={classes.testInfo}>Result: 
                    <span className={classes.testInfoStudentScore}>
                        {finalResult}
                    </span>
                </Grid>
            )
        }
        return result;
    }

    // prevent right click
    // document.oncontextmenu = () => {
    //     return false;
    // }

    return (
        <React.Fragment>
            {(pathGrading !== 'grading') && (
                <div className={classes.print}>
                    <ReactToPrint trigger={() => (<Link title="Print this out" onClick={void(0)}>
                                                    <PrintIcon className={classes.printIcon} />
                                                </Link>)}
                        content={() => pageResultRef.current} />
                </div>
            )}
            
            <CSSTransitionGroup
                className={classes.container}
                component="div"
                transitionName="fade"
                transitionEnterTimeout={800}
                transitionLeaveTimeout={500}
                transitionAppear
                transitionAppearTimeout={500}
                ref={pageResultRef}
            >
                <RingLoader
                    css={overrideSpinner}
                    color={blue[400]}
                    loading={loadingSpinner}
                />
                {(pathGrading === 'grading') && (
                    <Grid container alignItems="flex-end" className={classes.pageHeader2}>
                        <Grid item xs={12} className={classes.gradingTitle}>
                            <h2>Grading</h2>
                        </Grid>
                    </Grid>
                )}
                <QuizInfo quizTitle={resultState.testName} quizDescription={resultState.quizDescription} documentGrade={resultState.documentGrade} />
                <Grid container alignItems="flex-end" className={classes.pageHeader2}>
                    <Grid item xs={4} className={classes.testInfo}>Student: 
                        <span className={classes.testInfoStudent}> {resultState.studentName}</span>
                    </Grid>
                    <Grid item xs={4} className={classes.testInfo}>Start time: 
                        <Moment className={classes.time}>{resultState.studentStartTime}</Moment>
                    </Grid>
                    <Grid item xs={4} className={classes.testInfo}>End time: 
                        <Moment className={classes.time}>{resultState.studentEndTime}</Moment>
                    </Grid>
                    
                </Grid>
                <Grid container alignItems="flex-end" className={classes.pageHeader}>
                    {_getFinalResult()}
                    {(pathGrading !== 'grading') && (
                        <Grid item xs={4} className={classes.testInfo}>Scored: 
                            <span className={classes.testInfoStudentScore}> 
                                {(resultState.studentScore > 0 && resultState.totalTestPoint > 0) 
                                && `${resultState.studentScore} / ${resultState.totalTestPoint}`}
                            </span>
                        </Grid>
                    )}
                </Grid>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Student Answers
                        </ListSubheader>
                    }
                    className={classes.list}
                >
                    <form onSubmit={saveGrade}>
                        {resultState.resultDetail.map((questionObj, key) => (
                            <ResultDetailAnswer key={key} propQuestion={questionObj}
                                open={open} handleClick={handleClick} questionKey={key} 
                                getGridListCols={getGridListCols} getGridListCols2={getGridListCols2} pathGrading={pathGrading}
                                editGrade={(e) => handleGrading(e, 'grade')} editComment={(e) => handleGrading(e, 'comment')} 
                                editVoiceComment={(obj) => handleGrading(obj, 'voice-comment')} 
                                setVoiceQuestionCurr={_setVoiceQuestionCurr} />
                        ))}

                        {(pathGrading === 'grading') && (
                            <ListItem className={classes.saveGrade}>
                                <Button type="submit" variant="outlined" color="primary" disabled={gradingDisable} >Save Grade</Button>
                            </ListItem>
                        )}    
                    </form>
                </List>
            </CSSTransitionGroup>
        </React.Fragment>
        
    );
}

export default withWidth()(ResultDetail);