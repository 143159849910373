import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { initialState, reducer } from './components/quiz/Quiz';
import * as serviceWorker from './serviceWorker';
import { StateProvider } from './state';
import { BrowserRouter } from 'react-router-dom';


ReactDOM.render(
    <StateProvider initialState={initialState} reducer={reducer}>
        <BrowserRouter><App /></BrowserRouter>
    </StateProvider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();



