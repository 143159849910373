import React from 'react';
import PropTypes from 'prop-types';
import { UseStateValue } from '../../state';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(0),
      cursor: "pointer"
    },
  },
  questionCount: {
    fontSize: "16px"
  },
  arrowBack: {
    color: blue[500]
  },
  arrowForward: {
    float: "right",
    marginRight: "0 !important",
    color: blue[500]
  }
}));

function QuestionCount(props) {
  const classes = useStyles();
  const [{quizQuestions, questionOrder}] = UseStateValue();
  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs={1}><ArrowBackIcon onClick={props.onSetPreviousQuestion}
        className={classes.arrowBack} /></Grid>
      <Grid item xs={10}>
        <span className={classes.questionCount}>
          Question <span>{questionOrder}</span> of <span>{quizQuestions.length}</span>
        </span>
      </Grid>
      <Grid item xs={1}>
        <ArrowForwardIcon className={classes.arrowForward} onClick={props.onSetNextQuestion} /></Grid>
    </Grid>
  );
}

QuestionCount.propTypes = {
  onSetPreviousQuestion: PropTypes.func.isRequired,
  onSetNextQuestion: PropTypes.func.isRequired
}

export default QuestionCount;

