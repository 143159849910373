import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
  },
  checkGroup: {
    justifyContent: "space-around",
    margin: "2rem auto",
  },
  selectTutorWraper: {
    textAlign: "center",
    margin: "2rem auto"
  },
  arrowBack: {
    color: theme.palette.info.main,
    margin: "1rem",
  },
}));

const GreenCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.success.light,
    "&$checked": {
      color: theme.palette.success.main,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const RedCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.light,
    "&$checked": {
      color: theme.palette.secondary.main,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const SelectAvailableTime = (props) => {
  const classes = useStyles();

  const state = { ...props.stateObj };

  return (
    <React.Fragment>
      <ArrowBackIcon
        className={classes.arrowBack}
        onClick={() => props.handleArrowBack(3)}
      />
      <h2 className={classes.title}>BOOK TUTOR</h2>
      <form>
        <FormGroup row className={classes.checkGroup}>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.checkedDay.includes(1) ? true : false}
                onChange={props.handleDayCheck}
                name="1"
              />
            }
            label="Monday"
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.checkedDay.includes(2) ? true : false}
                onChange={props.handleDayCheck}
                name="2"
              />
            }
            label="Tuesday"
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.checkedDay.includes(3) ? true : false}
                onChange={props.handleDayCheck}
                name="3"
              />
            }
            label="Wednesday"
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.checkedDay.includes(4) ? true : false}
                onChange={props.handleDayCheck}
                name="4"
              />
            }
            label="Thursday"
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.checkedDay.includes(5) ? true : false}
                onChange={props.handleDayCheck}
                name="5"
              />
            }
            label="Friday"
          />
          <FormControlLabel
            control={
              <RedCheckbox
                checked={state.checkedDay.includes(6) ? true : false}
                onChange={props.handleDayCheck}
                name="6"
              />
            }
            label="Saturday"
          />
          <FormControlLabel
            control={
              <RedCheckbox
                checked={state.checkedDay.includes(0) ? true : false}
                onChange={props.handleDayCheck}
                name="0"
              />
            }
            label="Sunday"
          />
        </FormGroup>
        <div className={classes.selectTutorWraper}>
          <Button
            variant="outlined"
            color="primary"
            onClick={props.selectTutorSchedule}
          >
            Select tutoring schedule
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

SelectAvailableTime.propTypes = {
  stateObj: PropTypes.object.isRequired,
  handleDayCheck: PropTypes.func.isRequired,
  selectTutorSchedule: PropTypes.func.isRequired,
  handleArrowBack: PropTypes.func.isRequired
};

export default SelectAvailableTime;
