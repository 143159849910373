import http, { RequestHeader } from './httpService';


const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = '/api';

export function getStudentProfile(history, uid) {
    const header = RequestHeader();
    let result;
    if(uid) {
        result = http.get(`${apiUrl}${apiEndpoint}/tracking/user_statistic/azuread-b2c-oauth2/?uid=${uid}`, header);
    } else {
        result = http.get(`${apiUrl}${apiEndpoint}/tracking/user_statistic/azuread-b2c-oauth2/`, header);
    }   
    result.catch(function(error) {
        console.log(error);
        history.push('/');
    });
    return result;
}

export function getStudentProfileFromEdx(history, edxid) {
    const header = RequestHeader();
    let result = http.get(`${apiUrl}${apiEndpoint}/tracking/user_statistic/edx/${edxid}/`, header);   
    result.catch(function(error) {
        console.log(error);
        history.push('/');
    });
    return result;
}