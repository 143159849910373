import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: 0,
        marginTop: 0,
        width: "auto",
    },
    textAnswer: {
        verticalAlign: "bottom",
        fontSize: "1rem"
    },
    itemWrapper: {
        padding: theme.spacing(1)
    }
}));

function FillPlace(props) {
    const classes = useStyles();

    const _showFillPlace = (item) => {
        const arrContent = item.content.split('[...]');
        const answer = item.id in props.studentAnswers ? props.studentAnswers[item.id] : '';
        return (
            <div key={item.id} className={classes.itemWrapper}>
                <span className={classes.textAnswer}>{arrContent[0] && arrContent[0]}</span> 
                <TextField defaultValue={answer} className={classes.textField} margin="normal" 
                    id={item.id.toString()} onBlur={props.onTextChange} placeholder={answer} />
                <span className={classes.textAnswer}>{arrContent[1] && arrContent[1]}</span>
            </div>
        );
    }

    return (
        <div>
            {props.answerOptions.map(item => _showFillPlace(item))}
        </div>
    );
}

FillPlace.propsTypes = {
    onTextChange: PropTypes.func.isRequired,
    studentAnswers: PropTypes.object.isRequired,
    answerOptions: PropTypes.array.isRequired
}

export default FillPlace;