import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import { blue } from '@material-ui/core/colors';
import { Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    quizIconInfoWraper: {
        textAlign: "right"
    },
    quizIconInfo: {
        color: blue[500],
    },
    root: {
        paddingBottom: "8px"
    },
    typography: {
        padding: theme.spacing(2),
        whiteSpace: "pre-line"
    },
    documentGrade: {
        fontSize: "1rem"
    }
}));


function QuizInfo(props) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Grid container alignContent="center" className={classes.root}>
          <Grid item xs={10}>
            <Typography className={classes.quizTitle} variant="h4" component="h4">
                {props.quizTitle}
                {props.documentGrade && <Link href={props.documentGrade} target="_blank" 
                    className={classes.documentGrade}> (Grading guide)</Link>}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.quizIconInfoWraper}>
            <InfoIcon className={classes.quizIconInfo} onClick={handleClick} />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>{props.quizDescription}</Typography>
            </Popover>
          </Grid>
        </Grid>
    );
}

QuizInfo.propTypes = {
    // quizTitle: PropTypes.string.isRequired,
    // quizDescription: PropTypes.string.isRequired
}

export default QuizInfo;