import b2cauth from 'react-azure-adb2c';
import decodeJWT from 'jwt-decode'; 

class Auth {
  isLoggedIn() {
    if (b2cauth.getAccessToken()) {
      return true;
    }
    return false;
  }

  logout() {
    b2cauth.signOut();
  }

  getToken() {
    return b2cauth.getAccessToken();
  }

  currentUser() {
    if(this.isLoggedIn()) {
      const decoded = decodeJWT(this.getToken());
      return {
        azureId: decoded.sub,
        name: decoded.name,
        firstName: decoded.given_name,
        lastName: decoded.family_name,
        emails: decoded.emails,
      };
    }
    return null;
  }
}

export default Auth;