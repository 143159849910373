import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: 0,
        marginTop: 0,
        width: "auto",
    },
    textAnswer: {
        verticalAlign: "bottom",
        fontSize: "1rem"
    },
    itemWrapper: {
        padding: theme.spacing(1),
        whiteSpace: "pre-line"
    }
}));

function FillPlaceParagraph(props) {
    const classes = useStyles();

    const arrContent = props.paragraph.split('[...]');
    
    const _showFillPlaceParagraph = (item, index) => {
        return (
            <React.Fragment key={item.id}>
                <span className={classes.textAnswer}>{arrContent[index]}</span> 
                <TextField defaultValue={props.studentAnswers[item.id] || ""} className={classes.textField} margin="normal" 
                    id={item.id.toString()} onBlur={props.onTextChange} placeholder={props.studentAnswers[item.id] || ""} />
                {index === (arrContent.length - 2) && 
                    <span className={classes.textAnswer}>{arrContent[index+1] || ""}</span> 
                }
            </React.Fragment>
        );
    }

    return (
        <div className={classes.itemWrapper}>
            {props.answerOptions.map((item, index) => _showFillPlaceParagraph(item, index))}
        </div>
    );
}

FillPlaceParagraph.propsTypes = {
    onTextChange: PropTypes.func.isRequired,
    studentAnswers: PropTypes.object.isRequired,
    paragraph: PropTypes.string.isRequired,
    answerOptions: PropTypes.array.isRequired
}

export default FillPlaceParagraph;