import React from 'react';
import clsx from 'clsx';
import uuid from 'uuid/v1';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    overflowX: "auto"
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const List2 = props => {
  const { className, listTestResult } = props;

  const classes = useStyles();

  let data = [];
  for(const k in listTestResult) {
    let scoreData = {
      id: uuid(),
      period: k
    };
    listTestResult[k].forEach((element) => {
      scoreData[Object.entries(element)[0][0]] = Object.entries(element)[0][1];
    });
    data.push(scoreData);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Test Result detail"
      />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Test Period</TableCell>
                {data.length > 0 && Object.entries(data[0]).map((item, k) => {
                  if(item[0] !== 'period' && item[0] !== 'Total Score' && item[0] !== 'id') {
                    return <TableCell key={k}>{item[0]}</TableCell>
                  }
                  return null;
                })}
                <TableCell>Total Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(d => (
                <TableRow
                  hover
                  key={d.id}
                >
                  <TableCell>{d.period}</TableCell>
                  {Object.entries(d).map((item, k) => {
                    if(item[0] !== 'period' && item[0] !== 'Total Score' && item[0] !== 'id') {
                      return <TableCell key={k}>{item[1] > 0 ? item[1] : 'N/A'}</TableCell>
                    }
                    return null;
                  })}
                  <TableCell>{d['Total Score'] > 0 ? d['Total Score'] : 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

List2.propTypes = {
  className: PropTypes.string,
  listTestResult: PropTypes.object.isRequired
};

export default List2;
