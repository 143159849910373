import React, { useState } from "react";
import validator from "validator";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import { postRegister } from "../../services/registerService";
import InputOTPCode from "./InputOTPCode";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: "4rem auto",
    width: "100%",
    height: "100vh",
    flexGrow: 1,
    minHeight: 650
  },
  registerBtn: {
    margin: "2rem auto !important"
  },
  emailError: {
    color: theme.palette.error.main,
  },
  emailSuccess: {
    color: theme.palette.success.main,
  },
  formItem: {
    margin: "1rem auto !important",
  },
  doneBox: {
    margin: "3rem auto",
  },
  linkTest: {
    margin: "2rem auto !important"
  }
}));

export default function Register() {
  const classes = useStyles();
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [yourNameError, setYourNameError] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const stateData = {
    email: "",
    mobile: "",
    yourname: "",
    program: "",
    otpCode: "",
    registerState: "register",
    countdown: 0,
    linkTest: ""
  };
  const [state, setState] = useState(stateData);

  const validateEmail = (e) => {
    let email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    setState({
      ...state,
      email: email,
    });
  };

  const validateMobile = (e) => {
    let mobile = e.target.value;
    const vnf_regex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
    if (vnf_regex.test(mobile)) {
      setMobileError(false);
    } else {
      setMobileError(true);
    }
    setState({
      ...state,
      mobile: mobile,
    });
  };

  const validateYourName = (e) => {
    let yourname = e.target.value;
    if (!yourname || yourname.length < 3) {
      setYourNameError(true);
    } else {
      setYourNameError(false);
    }
    setState({
      ...state,
      yourname: yourname,
    });
  };

  const handleChangeProgram = (event) => {
    setState({
      ...state,
      program: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    // console.log({
    //   email: data.get("email"),
    // });
    if (
      emailError === false &&
      mobileError === false &&
      yourNameError === false &&
      state.program !== ""
    ) {
      console.log("OK to submit");
      setOpenBackdrop(true);
      const registerData = {
        email: state.email,
        phone: state.mobile,
        program: state.program,
        name: state.yourname
      }
      const {data} = await postRegister(registerData);
      if(data.status_code === 200) {
        if(data.message === 'OTP Code send success') {
          setState({
            ...state,
            registerState: "inputOTP",
            countdown: Date.now() + 60 * 1000,
          });
        } else {
          toast.error("Cannot send OTP Code. Please check your mobile is correct!");
        }
      } else {
        toast.error("Some thing went wrong!");
      }
      setOpenBackdrop(false);
    } else {
      console.log("validate failed!");
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}></Avatar>
          <Typography component="h1" variant="h5">
            Register Placement Test Online
          </Typography>
          {state.registerState === "register" && (
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                defaultValue={state.email}
                onBlur={(e) => validateEmail(e)}
                placeholder={state.email}
              />
              {emailError === true && (
                <Typography
                  component="span"
                  variant="span"
                  className={classes.emailError}
                >
                  Invalid email!
                </Typography>
              )}
              {emailError === false && (
                <Typography
                  component="span"
                  variant="span"
                  className={classes.emailSuccess}
                >
                  Valid email
                </Typography>
              )}

              <TextField
                margin="normal"
                className={classes.formItem}
                required
                fullWidth
                id="mobile"
                label="Mobile"
                name="mobile"
                autoComplete="mobile"
                defaultValue={state.mobile}
                onBlur={(e) => validateMobile(e)}
                placeholder={state.mobile}
              />
              {mobileError === true && (
                <Typography
                  component="span"
                  variant="span"
                  className={classes.emailError}
                >
                  Invalid mobile!
                </Typography>
              )}
              {mobileError === false && (
                <Typography
                  component="span"
                  variant="span"
                  className={classes.emailSuccess}
                >
                  Valid mobile
                </Typography>
              )}

              <TextField
                margin="normal"
                className={classes.formItem}
                required
                fullWidth
                id="yourname"
                label="Your Name"
                name="yourname"
                autoComplete="yourname"
                defaultValue={state.yourname}
                onBlur={(e) => validateYourName(e)}
                placeholder={state.yourname}
              />
              {yourNameError === true && (
                <Typography
                  component="span"
                  variant="span"
                  className={classes.emailError}
                >
                  Invalid name!
                </Typography>
              )}
              {yourNameError === false && (
                <Typography
                  component="span"
                  variant="span"
                  className={classes.emailSuccess}
                >
                  OK
                </Typography>
              )}

              <FormControl fullWidth className={classes.formItem}>
                <InputLabel id="label-select-program">
                  Choose Program
                </InputLabel>
                <Select
                  labelId="label-select-program"
                  id="select-program"
                  value={state.program}
                  label="Program"
                  onChange={handleChangeProgram}
                >
                  <MenuItem value="IELTS">IELTS</MenuItem>
                  <MenuItem value="JUNIOR">JUNIOR</MenuItem>
                  <MenuItem value="EFL">EFL</MenuItem>
                  <MenuItem value="SAT">SAT</MenuItem>
                  <MenuItem value="KID">JUNIOR PRIMARY</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth className={classes.formControlBtn}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.registerBtn}
                >
                  Register
                </Button>
              </FormControl>
            </Box>
          )}
          {state.registerState === "inputOTP" && (
            <InputOTPCode
              state={state}
              setState={setState}
              setOpenBackdrop={setOpenBackdrop}
            />
          )}
          {state.registerState === "done" && (
            <Box component={"div"} className={classes.doneBox}>
              <Typography component="h3" variant="span">
                Your test link has just been sent. Please check your Email/SMS.
              </Typography>
              {state.linkTest && (
                <Typography component="h4" variant="span" className={classes.linkTest}>
                  Or click this <Link href={state.linkTest} target="_blank">link</Link> to do the test right now.
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </Grid>
  );
}
