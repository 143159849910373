import React from "react";
import { useHistory } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
// import ChatIconBottom from './chatbox/ChatIconBottom';


const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 1170,
    borderRadius: 3,
    margin: "2.5rem auto",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-around",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: "2rem",
  },
  item: {
    width: "200px",
  },
  submit: {
    marginTop: 20
  },
  card: {
    minWidth: 235,
  },
  media: {
    height: 240,
  },
  gridListTile: {
    '& div': {
      position: "unset"
    },
    height: "auto !important"
  }
}));

const HomePage = (props) => {
  const classes = useStyles();

  const history = useHistory();

  const getGridListCols = () => {
      if (isWidthUp('lg', props.width)) {
        return 3;
      }

      if (isWidthUp('md', props.width)) {
        return 2;
      }

      if (isWidthUp('sm', props.width)) {
        return 1;
      }
      return 1;
  }

  return (
    <React.Fragment>
        <CSSTransitionGroup
          className={classes.container}
          component="div"
          transitionName="fade"
          transitionEnterTimeout={800}
          transitionLeaveTimeout={500}
          transitionAppear
          transitionAppearTimeout={500}
        >
        <GridList spacing={15} cellHeight={500} cols={getGridListCols()}
          className={classes.root}>
          <GridListTile cols={1} className={classes.gridListTile}>
            <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image="/img/test-online.png"
                  title="Test Online"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Test Online
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Try a test with your created test code. We recommend you sign-in 
                      with your social account to have a better experience.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" onClick={() => history.push(`/test/`)}>
                    Play a test
                  </Button>
                </CardActions>
              </Card>
          </GridListTile>

          <GridListTile cols={1} className={classes.gridListTile}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image="/img/learn-online.png"
                  title="Learn Online"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Learn Online
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Your learning exercise with lessons released by day. 
                      You will be able to doing exercise with many and many types of questions.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" onClick={() => {
                    window.location.href = "https://learn.yola.vn";
                  }}>
                    Go to learn
                  </Button>
                </CardActions>
              </Card>
          </GridListTile>

          <GridListTile cols={1} className={classes.gridListTile}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image="/img/yola-website.png"
                  title="Yola Website"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Yola Website
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    About Yola Education and more...
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" onClick={() => {
                    window.location.href = "https://yola.vn";
                  }}>
                    Go to Yola Website
                  </Button>
                </CardActions>
              </Card>
          </GridListTile>

          {false && <GridListTile cols={1} className={classes.gridListTile}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image="/img/yola-tutor-online.jpg.webp"
                  title="Yola Tutor Online"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Yola Tutor Online
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    This new feature enables students to booking tutor online at Yola. Students only need to select 
                      a tutor from the scheduled list and then have a meeting link with that tutor for help. 
                      Please click the button below for online tutor booking.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" onClick={() => {
                    window.location.href = `${process.env.REACT_APP_BASE_URL}/key-me/book-tutor`;
                  }}>
                    Book tutor
                  </Button>
                </CardActions>
              </Card>
          </GridListTile>}

        </GridList>
      </CSSTransitionGroup>
    </React.Fragment>
  );
};

export default withWidth()(HomePage);
