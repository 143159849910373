import React from "react";
import { CSSTransitionGroup } from 'react-transition-group';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 800,
    borderRadius: 3,
    margin: "5rem auto 2rem auto",
    flexGrow: 1,
    width: "100%"
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    color: red[600],
    padding: 40
  }
}))

const ErrorPage = () => {
  const classes = useStyles();
  return (
    <CSSTransitionGroup
        className={classes.container}
        component="div"
        transitionName="fade"
        transitionEnterTimeout={800}
        transitionLeaveTimeout={500}
        transitionAppear
        transitionAppearTimeout={500}
    >
      <Grid container alignItems="center" className={classes.root}>
        <h3>Something went wrong.</h3>
      </Grid>
    </CSSTransitionGroup>
  );
};

export default ErrorPage;
