import React from 'react';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';
import Update from '@material-ui/icons/Update';
import Schedule from '@material-ui/icons/Schedule';

import palette from '../theme/palette';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end',
    color: palette.primary.main
  }
}));

function pad(num) {
  return ("0"+num).slice(-2);
}

function hhmmss(secs) {
  var minutes = Math.floor(secs / 60);
  secs = secs%60;
  var hours = Math.floor(minutes/60)
  minutes = minutes%60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

const List1 = props => {
  const { className, hwTimespent } = props;

  const classes = useStyles();

  let timespentData = Object.entries(hwTimespent).filter(obj => obj[0] !== 'total_time_spend');
  timespentData = timespentData.sort((a, b) => moment(a[0], 'YYYY-MM-DD') - moment(b[0], 'YYYY-MM-DD'))
    .map(obj => {
      return {
        id: uuid(),
        date: obj[0],
        timeSpent: hhmmss(obj[1])
      };
  });

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        subtitle={`${timespentData.length} in total`}
        title="Time spent detail"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {timespentData.map((tp, i) => (
            <ListItem
              divider={i < timespentData.length - 1}
              key={tp.id}
            >
              <ListItemAvatar>
                <Schedule />
              </ListItemAvatar>
              <ListItemText
                secondary={tp.date}
                primary={`Spent ${tp.timeSpent}`}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Typography>
          {Object.keys(hwTimespent).length > 0 && moment.utc(hwTimespent.total_time_spend*1000).format('HH:mm:ss')}
        </Typography>
        <Update />
      </CardActions>
    </Card>
  );
};

List1.propTypes = {
  className: PropTypes.string,
  hwTimespent: PropTypes.object.isRequired
};

export default List1;
