import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography
} from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';

import palette from '../theme/palette';
import { options } from './chart';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end',
    color: palette.primary.main
  },
  chart1Help: {
    fontSize: '0.8rem',
    color: palette.primary.main
  }
}));

const Chart1 = props => {
  const { className, hwSkill } = props;

  const classes = useStyles();

  const data = {
    labels: [],
    datasets: [
      {
        label: 'Complettion percentage',
        backgroundColor: palette.warning.main,
        data: []
      },
      {
        label: 'Avg. Grade',
        backgroundColor: palette.success.main,
        data: []
      }
    ]
  };

  const [dataState, setDataState] = useState(data);

  useEffect(() => {
    if(Object.keys(hwSkill).length > 0) {
      const newData = {
        labels: Object.entries(hwSkill).map(item => {
          return item[0];
        }),
        datasets: [
          {
            label: 'Complettion percentage',
            backgroundColor: palette.warning.main,
            data: Object.entries(hwSkill).map(item => {
              return item[1].percentage_completion;
            })
          },
          {
            label: 'Avg. Grade',
            backgroundColor: palette.success.main,
            data: Object.entries(hwSkill).map(item => {
              return item[1].average_grad;
            })
          }
        ]
      };
      setDataState(newData);
  }}, [hwSkill]);

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={
        //   <Button
        //     size="small"
        //     variant="text"
        //   >
        //     Last 7 days <ArrowDropDownIcon />
        //   </Button>
        // }
        title="Skills"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={dataState}
            options={options}
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Typography className={classes.chart1Help}>
          Number in percentage
        </Typography>
        <HelpOutline />
      </CardActions>
    </Card>
  );
};

Chart1.propTypes = {
  className: PropTypes.string,
  hwSkill: PropTypes.object.isRequired
};

export default Chart1;
