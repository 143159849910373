import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextareaAutoSize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles(theme => ({
    textarea: {
        width: "100%"
    }
}));

function Essay(props) {
    const [localValue, setLocalValue] = useState();
    
    const classes = useStyles();

    useEffect(() => {
        const value = ('value' in props.studentAnswers) ? props.studentAnswers['value'] : '';
        setLocalValue(value);
    }, [props.studentAnswers]);

    return (
        <TextareaAutoSize rows={15} placeholder="Answer here..." 
                className={classes.textarea} defaultValue={localValue}
                onBlur={props.onEssayChange} />
    );
}

Essay.propTypes = {
    onEssayChange: PropTypes.func.isRequired,
    studentAnswers: PropTypes.object.isRequired
}

export default Essay;