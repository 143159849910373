import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  CardActions,
  Divider,
  Button,
  Collapse,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List3 from "./List3";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    color: theme.palette.primary.contrastText,
  },
  content: {
    backgroundColor: theme.palette.info.light,
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56,
    margin: "auto",
    fontWeight: "bold",
  },
  icon: {
    height: 32,
    width: 32,
  },
  courseName: {
    fontWeight: 500,
    fontSize: "1.2rem",
    marginBottom: theme.spacing(3),
  },
  mainInfo: {
    justifyContent: "space-around",
  },
  title: {
    margin: 8,
    color: theme.palette.yellow,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapse: {
    backgroundColor: theme.palette.white,
  },
  btnDetail: {
      margin: theme.spacing(1),
      fontWeight: "bold",
      fontSize: "1rem"
  }
}));

const Overall = (props) => {
  const { className, course, index, setCourse } = props;

  const classes = useStyles();

  let expandedObj = {};
  expandedObj[index] = false;

  const [expanded, setExpanded] = React.useState(expandedObj);

  const handleExpandClick = () => {
    let expandedObj = {};
    expandedObj[index] = !expanded[index];
    setExpanded(expandedObj);
  };

  let score = null;

  if (course.testResult.length > 0) {
    const totalScore = course.testResult.filter((e) =>
      e.hasOwnProperty("Total Score")
    );
    score = totalScore.length > 0 ? totalScore[0]["Total Score"] : null;
  }
  
  return (course.courseName !== "") && (
    <Card className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.courseName}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              {course.courseName}
            </Typography>
          </Grid>
        </Grid>

        <Grid container justify="space-around">
          {score !== null && (
            <Grid item>
              <Avatar className={classes.avatar}>{score > 0 ? score : 'N/A'}</Avatar>
              <Typography variant="h6" className={classes.title}>
                Score
              </Typography>
            </Grid>
          )}
          {Object.keys(course.hwResult).length > 0 && (
            <Grid item>
              <Avatar className={classes.avatar}>
                {course.hwResult.percentage_completion > 0 ? course.hwResult.percentage_completion : 'N/A'}%
              </Avatar>
              <Typography variant="h6" className={classes.title}>
                HW Complete
              </Typography>
            </Grid>
          )}
          {Object.keys(course.hwResult).length > 0 && (
            <Grid item>
              <Avatar className={classes.avatar}>
                {course.hwResult.average_grad > 0 ? course.hwResult.average_grad : 'N/A'}%
              </Avatar>
              <Typography variant="h6" className={classes.title}>
                HW Avg.
              </Typography>
            </Grid>
          )}
          {Object.keys(course.hwTimespent).length > 0 && course.hwTimespent.total_time_spend > 0 && (
            <Grid item>
              <Avatar className={classes.avatar}>
                {Object.keys(course.hwTimespent).length > 0
                  ? moment
                      .utc(course.hwTimespent.total_time_spend * 1000)
                      .format("HH:mm")
                  : null}
              </Avatar>
              <Typography variant="h6" className={classes.title}>
                Hours Spent
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Divider />
      <CardActions disableSpacing>
        <Button size="large" color="secondary" className={classes.btnDetail} onClick={() => setCourse(index)}>
          Detail
        </Button>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded[index],
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded[index]}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse
        in={expanded[index]}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        <CardContent>
          <List3 comments={course.comments} />
        </CardContent>
      </Collapse>
    </Card>
  );
};

Overall.propTypes = {
  course: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setCourse: PropTypes.func.isRequired,
};

export default Overall;
