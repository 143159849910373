import React from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import palette from "../theme/palette";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.info.main,
  },
  clickToView: {
      fontSize: "0.8rem",
      color: theme.palette.text.secondary
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DashboardCharts = (props) => {
  const classes = useStyles();
  const { courses, program } = props;

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  let labels = [];
  let dataTestScores = [];
  let dataHWCompletion = [];
  let dataHWAvgScore = [];

  courses.forEach((c) => {
    labels.push(c.courseName);
    // Test score
    dataTestScores.push(
      c.testResult.filter((t) => "Total Score" in t).length > 0
        ? c.testResult.filter((t) => "Total Score" in t)[0]["Total Score"]
        : null
    );
    // HW completion
    if (Object.keys(c.hwResult).length > 0) {
      dataHWCompletion.push(c.hwResult.percentage_completion);
    }
    // HW Avg. score
    if (Object.keys(c.hwResult).length > 0) {
      dataHWAvgScore.push(c.hwResult.average_grad);
    }
  });

  let data = {
    labels: labels,
    datasets: [
      {
        label: "Exam Score",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 50,
        data: dataTestScores,
      },
    ],
  };

  if (dataHWCompletion.length > 0) {
    data["datasets"].push({
      label: "Homework Completion percentage",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: palette.info.main,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: palette.info.main,
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: palette.info.main,
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 5,
      pointHitRadius: 50,
      data: dataHWCompletion,
    });
  }

  if (dataHWAvgScore.length > 0) {
    data["datasets"].push({
      label: "Homework Avg. Score",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: palette.success.main,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: palette.success.main,
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: palette.success.main,
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 5,
      pointHitRadius: 50,
      data: dataHWAvgScore,
    });
  }

  return (
    <div>
      <h4 className={classes.title} onClick={handleOpenDialog}>
        {program} Student Progress{" "}
        <span className={classes.clickToView}>Click to view</span>
      </h4>
      <div onClick={handleOpenDialog}>
        <Line data={data} />
      </div>

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-slide-title">
          {`${program} Student Progress`}
        </DialogTitle>
        <DialogContent>
          <Line data={data} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DashboardCharts.propTypes = {
  courses: PropTypes.array,
  program: PropTypes.string,
};

export default DashboardCharts;
