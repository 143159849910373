import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = "/api";

export function saveRecording(
  directory,
  file,
  fileName = null,
  onUploadProgress = null
) {
  let fd = new FormData();
  let fn = fileName === null ? file.size : fileName;
  fd.append(
    "file",
    file,
    onUploadProgress !== null ? `audio_${fn}` : `blob_${fn}`
  );
  const header = {
    onUploadProgress: onUploadProgress,
    "Content-Type": "multipart/form-data"
  };
  return http.post(
    `${apiUrl}${apiEndpoint}/upload-record-source/azuread-b2c-oauth2/${directory}/`,
    fd,
    header
  );
}
