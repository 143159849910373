import React, { useState, forwardRef, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ReactMic } from "react-mic";
import QRCode from "qrcode.react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import ReactPlayer from "react-player";
import is from "is_js";
import { red } from "@material-ui/core/colors";
import { UseStateValue } from "../../state";
import { checkAudioFileExists } from "../../services/quizService";
import { ConfirmListeningAfterSpeakingDialogSlide } from "./Dialog";

const useStyles = makeStyles((theme) => ({
  status: {
    "& canvas": {
      width: "100%",
      height: "50px",
    },
  },
  button: {
    margin: theme.spacing(2),
  },
  mb5: {
    marginBottom: "5px",
  },
  fileName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    margin: 0,
  },
  uploadMessage: {
    fontWeight: "bold",
    color: "#63A84E",
  },
  btnChoose: {
    marginRight: "10px !important",
    padding: "5px 2px",
  },
  qrcode: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "1rem",
  },
  uploadTitle: {
    padding: "0.5rem 1rem",
    display: "flex",
    "& button": {
      padding: 0,
    },
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function RecordingVoice(props) {
  const classes = useStyles();

  const [
    { testCode, userName, recordingObj, recordingType },
    dispatch,
  ] = UseStateValue();

  // recording hook
  const [open, setOpen] = useState(false);
  const [recordStatus, setRecordStatus] = useState(false);
  const [openConfirmListening, setOpenConfirmListening] = useState(false);

  // upload file hook
  const socketRef = useRef();
  const audioUploadObj = {
    open: false,
    selectedFile: null,
    currentFile: null,
    message: "",
    progress: 0,
  };
  const [audioUpload, setAudioUpload] = useState(audioUploadObj);

  const handleChangeRecordingType = () => {
    dispatch({
      type: "setRecordingType",
      recordingType: "upload",
    });
    setOpen(false);
    setOpenUpload(true);
    const socketUrlKey = `audio_${testCode}_${userName}_${props.quesId}`;
    if (
      !socketRef ||
      !socketRef.current ||
      socketRef.current.readyState === WebSocket.CLOSED
    ) {
      socketRef.current = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_API_URL}/${socketUrlKey}/`
      );
    }
  };

  const updateUploadedAudio = async (data) => {
    if (data && data["link"]) {
      const linkSplit = data["link"].split("/");
      if (linkSplit.length > 0) {
        if (
          linkSplit[linkSplit.length - 1] ===
          `audio_${testCode}_${userName}_${props.quesId}`
        ) {
          await props.onSave({ value: data["link"] });
          setAudioUpload({
            ...audioUpload,
            progress: 1,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (recordingType === "upload") {
      const socketUrlKey = `audio_${testCode}_${userName}_${props.quesId}`;
      if (
        !socketRef ||
        !socketRef.current ||
        socketRef.current.readyState === WebSocket.CLOSED
      ) {
        socketRef.current = new WebSocket(
          `${process.env.REACT_APP_WEBSOCKET_API_URL}/${socketUrlKey}/`
        );
      } else {
        const linkSplit = socketRef.current.url.split("/");
        if (linkSplit.length > 0) {
          if (linkSplit[linkSplit.length - 2] !== socketUrlKey) {
            socketRef.current = new WebSocket(
              `${process.env.REACT_APP_WEBSOCKET_API_URL}/${socketUrlKey}/`
            );
          }
        }
        socketRef.current.onopen = (e) => {
          console.log("open", e);
        };
        socketRef.current.onmessage = (e) => {
          console.log("data upload socket", JSON.parse(e.data));
          updateUploadedAudio(JSON.parse(e.data));
        };
        socketRef.current.onerror = (e) => {
          console.log("error", e);
        };
      }
    }
  });

  const selectFile = (e) => {
    setAudioUpload({
      ...audioUpload,
      selectedFile: e.target.files,
      currentFile: null,
      message: "",
      progress: 0,
    });
  };

  const setOpenUpload = (openUpload) => {
    setAudioUpload({
      ...audioUploadObj,
      open: openUpload,
    });
  };

  const uploadAudioFile = async () => {
    if (audioUpload.selectedFile && audioUpload.selectedFile.length > 0) {
      const file = audioUpload.selectedFile[0];
      const fsize = Math.round(file.size / 1024);
      if (fsize === 0 || fsize > 4096) {
        setAudioUpload({
          ...audioUpload,
          message: "Audio file is too big!",
        });
        return;
      }
      const allowAudioType = [
        "audio/wav",
        "audio/mpeg",
        "audio/ogg",
        "audio/x-m4a",
        "audio/mp4",
        "audio/x-aiff",
        "video/webm",
        "application/octet-stream",
      ];
      if (!allowAudioType.includes(file.type)) {
        if(!(file instanceof Blob)) {
          setAudioUpload({
            ...audioUpload,
            message: "Please upload an audio file!",
          });
          return;
        }
      }
      setAudioUpload({
        ...audioUpload,
        currentFile: file,
        progress: 1,
      });
      await props.onSave(file);
    }
  };

  const startRecording = (e) => {
    if (typeof props.setVoiceQuestionCurr === "function") {
      const propId = e.currentTarget.getAttribute("id").split("-");
      props.setVoiceQuestionCurr(parseInt(propId[0]), parseInt(propId[1]));
    }
    setRecordStatus(true);
  };

  const stopRecording = () => {
    setRecordStatus(false);
  };

  const onStop = async (recordedBlob) => {
    const lsRecQuesId = localStorage.getItem(
      `yolatest_recording_voice_question_curr_id_${testCode}_${userName}`
    );

    const lsRecordingObj = localStorage.getItem(
      `yolatest_recording_object_${testCode}_${userName}`
    );

    if (lsRecQuesId) {
      const recQuesId = parseInt(lsRecQuesId);
      let tmpRecordingObj = { ...recordingObj };
      if (Object.keys(tmpRecordingObj).length === 0) {
        if (lsRecordingObj) {
          tmpRecordingObj = JSON.parse(lsRecordingObj);
          for (const ro in tmpRecordingObj) {
            const blobExists = await checkAudioFileExists(tmpRecordingObj[ro]);
            if (!blobExists) {
              delete tmpRecordingObj[ro];
            }
          }
        }
      }
      tmpRecordingObj[recQuesId] = recordedBlob["blobURL"];
      await dispatch({
        type: "updateRecordingObj",
        recordingObj: tmpRecordingObj,
      });

      // update local storage recordingObj
      localStorage.setItem(
        `yolatest_recording_object_${testCode}_${userName}`,
        JSON.stringify(tmpRecordingObj)
      );
    }

    await props.onSave(recordedBlob);
  };

  const showPlayer = () => {
    if (props.quesId in recordingObj) {
      return (
        <Grid item xs={12}>
          <ReactPlayer
            url={recordingObj[props.quesId]}
            controls={true}
            width="100%"
            height="50px"
          />
        </Grid>
      );
    } else if (props.audioObj["value"]) {
      return (
        <Grid item xs={12}>
          <ReactPlayer
            url={props.audioObj["value"]}
            controls={true}
            width="100%"
            height="50px"
          />
        </Grid>
      );
    }
    return null;
  };

  const handleCloseDialog = () => {
    stopRecording();

    setOpenConfirmListening(false);

    setOpen(false);
  };

  const handleCloseUploadDialog = () => {
    setAudioUpload({
      ...audioUpload,
      open: false,
    });
  };

  return (
    <React.Fragment>
      {recordingType !== "upload" && (
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => setOpen(true)}
        >
          Record your voice
        </Button>
      )}

      {recordingType === "upload" && (
        <Button
          type="button"
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={() => setOpenUpload(true)}
        >
          Upload your audio file
        </Button>
      )}

      {/* Recording dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenConfirmListening(true)}
        aria-labelledby="customized-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setOpenConfirmListening(true)}
        >
          Start Recording your voice
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Click the START RECORDING button to record your voice then click the
            SAVE button to finish. You can also check your voice by clicking the
            Play button.
          </DialogContentText>
          <div className={classes.status}>
            <ReactMic
              record={recordStatus}
              className="sound-wave"
              onStop={onStop}
              strokeColor="#fff"
              backgroundColor={red[100]}
            />
          </div>

          {showPlayer()}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" onClick={handleChangeRecordingType}>
            Cannot recording?
          </Button>
          <div>
            <Button
              disabled={recordStatus}
              type="button"
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={startRecording}
              id={`${props.parentQuesId}-${props.quesId}`}
            >
              Start recording
            </Button>
            <Button
              disabled={!recordStatus}
              type="button"
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={stopRecording}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <ConfirmListeningAfterSpeakingDialogSlide
        handleYes={handleCloseDialog}
        handleClose={() => setOpenConfirmListening(false)}
        open={openConfirmListening}
      />

      {/* Upload file dialog */}
      <Dialog
        open={audioUpload.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseUploadDialog}
        aria-labelledby="upload-file-dialog-title"
        aria-describedby="upload-file-dialog"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle
          id="upload-file-dialog-title"
          onClose={handleCloseUploadDialog}
          className={classes.uploadTitle}
        >
          Upload your audio file
        </DialogTitle>
        <DialogContent>
          {!is.ios() && !is.android() && 
            <React.Fragment>
              <DialogContentText id="upload-file-dialog">
                Scan this QR Code to upload audio from another device.
              </DialogContentText>
              <div className={classes.qrcode}>
                <QRCode
                  value={`${process.env.REACT_APP_API_URL}/q/upload_audio/audio_${testCode}_${userName}_${props.quesId}/`}
                />
              </div>
            </React.Fragment>
          }

          <div>
            {(is.ios() || is.android()) && 
              <React.Fragment>
                  {audioUpload.currentFile &&
                    props.progressUpload > 0 &&
                    audioUpload.progress > 0 && (
                      <Box className={classes.mb5} display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={props.progressUpload ? props.progressUpload : 0}
                          />
                        </Box>
                        <Box minWidth={35}>
                          <Typography variant="body2" color="textSecondary">{`${
                            props.progressUpload ? props.progressUpload : 0
                          }%`}</Typography>
                        </Box>
                      </Box>
                    )}

                  <label htmlFor={`btn-upload-${props.parentQuesId}-${props.quesId}`}>
                    <input
                      id={`btn-upload-${props.parentQuesId}-${props.quesId}`}
                      name={`btn-upload-${props.parentQuesId}-${props.quesId}`}
                      style={{ display: "none" }}
                      type="file"
                      onChange={selectFile}
                    />

                    <Button
                      className={classes.btnChoose}
                      variant="outlined"
                      component="span"
                    >
                      Choose audio file
                    </Button>
                  </label>

                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    disabled={!audioUpload.selectedFile || audioUpload.progress > 0}
                    onClick={uploadAudioFile}
                  >
                    Upload
                  </Button>

                  <h6 className={classes.fileName}>
                    {audioUpload.selectedFile && audioUpload.selectedFile.length > 0
                      ? audioUpload.selectedFile[0].name
                      : null}
                  </h6>
              </React.Fragment>
            }

            <Typography variant="subtitle2" className={classes.uploadMessage}>
              {audioUpload.message}
              {audioUpload.progress > 0 &&
                props.progressUpload === 100 &&
                "Your audio file uploaded successfully!"}
            </Typography>
          </div>

          <Typography variant="h6" className="list-header">
            Your audio file
          </Typography>
          {showPlayer()}
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={handleCloseUploadDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

RecordingVoice.propTypes = {
  onSave: PropTypes.func.isRequired,
  audioObj: PropTypes.object.isRequired,
  progressUpload: PropTypes.number,
};

export default RecordingVoice;
